import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as types from '../../constants/prop-types';
import { prop, find } from 'ramda';
import cn from 'classnames';
import Loader from '../../components/loader';
import BasicLayout from '../basic-layout';
import Button from '../../components/button';
import PaymentSource from '../../components/send-money/wizard/payment-source';
import CreditCardBackup from '../../components/credit-cards/credit-card-backup';
import CreditCardAddModal from '../../components/credit-cards/credit-card-add-modal';
import CreditCardErrorModal from '../../components/credit-cards/credit-card-error-modal';
import {
  hasPermission,
} from '../../utils/permissions';



const AgreementConfiguration = ({
  destWalletHandle,
  setField,
  selectedAccount,
  selectedBankAccount,
  confirmPayment,
  cancelPayment,
  paymentInProcess,
  description,
  isBankAccountsDisabled,
  permissions,
  makePreflight,
  preflightIsLoading,
  preflightAccounts,
  cardAccounts,
  isCreditCardsDisabled,
  hasPreflightAccounts,
  hasMorePaymentMethods,
}) => {
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [isCardErrorModalOpen, setIsCardErrorModalOpen] = useState(false);

  useEffect(() => {
    makePreflight();
  }, []);

  const [isMorePaymentMethodsOpen, setIsMorePaymentMethodsOpen] = useState(false);

  const toggleMorePaymentMethods = () => {
    setIsMorePaymentMethodsOpen(!isMorePaymentMethodsOpen);
  };

  const renderPaymentSources = (paymentSources) => paymentSources.map((paymentSource) => {

    const isDisabledByAccount = paymentSource.sourceType === 'bank account' ? paymentSource.mode === 'invalid' : false;

    const isDisabledByPermission = !hasPermission(permissions, 'resides_in_allowed_state');

    if(isBankAccountsDisabled && paymentSource.sourceType === 'bank account') {
      return null;
    }

    const accountName =  paymentSource.name ? paymentSource.name : paymentSource.balance ? paymentSource.balance.currency : null;

    return (
      <div className="description-list_item -condensed" key={paymentSource.id}>
        <PaymentSource
          key={paymentSource.id}
          className="-height-small"
          sourceType={paymentSource.sourceType}
          onClick={() => {
            if(paymentSource.mode !== 'invalid') {
              setField('selectedAccount', paymentSource);
              setField('selectedAccountId', paymentSource.bank_account_id || paymentSource.card_account_id || paymentSource.account_id);

              if(paymentSource.sourceType === 'bank' && !isCreditCardsDisabled) {
                setIsCardModalOpen(true);
              } else {
                confirmPayment();
              }

            }
          }}
          disabled={isDisabledByAccount || isDisabledByPermission || paymentSource.mode === 'invalid' || paymentInProcess}
          accountName={accountName}
          {...paymentSource}
        />
      </div>
    );
  }); 

  return (
    <BasicLayout
      hideNav
      headerClassName="-payment"
      isBranding={true}
      poweredByType="full"
      wrapperClassName={
        cn('-no-page-header')
      }
    >
      <div className="page_header">
        <h1 className="page_title">Allow recurring withdrawal</h1>
      </div>

      <div className="page_body">
        <div className="page_content">
          <div className="layer -space-down-sm">
            <div className="badge -as-form-message -alert full-width">
            The recipient will be able to charge the account selected at regular intervals
            </div>
          </div>

          <div className="description-list full-width -fixed-title">
            <div className="description-list_body">
              <div className="description-list_group">
                <div className="description-list_item">
                  <div className="description-list_title">
                    Description
                  </div>
                  <div className="description-list_value">
                    {description}
                  </div>
                </div>
              </div>
            </div>
          </div>

          { preflightIsLoading && 
                <div className="transactions-log_loader-wrapper -lower">
                  <Loader size={'sm'} color={'blue'} />
                  <p className="color-gray">Loading payment sources</p>
                </div>
          }

          { !preflightIsLoading && (
            <Fragment>
              <h2 className="new-transaction__header -agreements">Payment methods</h2>
              <div className="new-transaction_description source-list description-list -fixed-title full-width">
                <div className="description-list_body -transparent">
                  { hasPreflightAccounts && renderPaymentSources(preflightAccounts[0]) }
                </div>
              </div>
            </Fragment>
          ) 
          }
          {
            !preflightIsLoading && hasMorePaymentMethods && (
              <h2 
                className={cn('new-transaction__header', '-clickable', { '-open': isMorePaymentMethodsOpen })} 
                onClick={() => toggleMorePaymentMethods()}
              >
                More payment methods
              </h2>
            )
          }

          { !preflightIsLoading && isMorePaymentMethodsOpen && hasMorePaymentMethods && (
            <div className="new-transaction_description source-list description-list -fixed-title full-width">
              <div className="description-list_body -transparent">
                { renderPaymentSources(preflightAccounts[1]) }
              </div>
            </div>
          )}
        </div>
      </div>

      {!preflightIsLoading && (
        <div className="layer -space-up-md -space-down-md text-align-center -column -gap-5 ">
          <span className="font-size-secondary-responsive">
            Funds held in accounts are represented in USD equivalent
          </span>
          <span className="font-size-secondary-responsive">
            By continuing this operation you agree with the <a
              rel="noopener noreferrer"
              target="_blank"
              href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
            >
            User Agreement
            </a>
          </span>
        </div>
      )}
      
      <div className="page_controls -align-top-desktop">
        <Button
          xSize="full"
          transparency="full"
          className="page_control -cancel"
          onClick={() => cancelPayment()}
          disabled={paymentInProcess}
        >
        Cancel
        </Button>
      </div>
      
      <CreditCardBackup 
        show={isCardModalOpen}
        closeButton={false}
        onClose={() => setIsCardModalOpen(false)}
        setAsBackup={(card) => {
          setIsCardModalOpen(false);  
          setTimeout(() => {
            const paymentMode = card && card.card_account_id
              ? prop('mode')(find((item) => card.card_account_id === item.card_account_id)(cardAccounts)) 
              : selectedBankAccount.mode;
            setField('backup_card_id', card.card_account_id); 
            setField('backup_card', card);
            setField('paymentMode', paymentMode);
            makePreflight();
            confirmPayment();  
          }, 0);
        }}
        addCard={() => {
          setIsCardModalOpen(false);
          setIsAddCardModalOpen(true);
        }}
        cardAccounts={cardAccounts}
      />

      <CreditCardAddModal 
        show={isAddCardModalOpen}
        closeButton={true}
        onClose={() => setIsAddCardModalOpen(false)}
        addAndUse={(backup_card_id) => {
          setField('backup_card_id', backup_card_id);
          setField('paymentMode', selectedBankAccount.mode);
          setIsAddCardModalOpen(false);
          confirmPayment();
        }}
      />
      <CreditCardErrorModal 
        show={isCardErrorModalOpen}
        closeButton={false}
        onClose={() => setIsCardErrorModalOpen(false)}
        handleChooseAnother={() => {
          setIsCardErrorModalOpen(false);
          setIsCardModalOpen(true);
        }}
        handleContinue={() => {
          setIsCardErrorModalOpen(false);
          setField('backup_card_id', null); 
          setField('paymentMode', selectedBankAccount.mode);
          confirmPayment();
        }}
        bankClears={selectedBankAccount ? selectedBankAccount.clears : ''}
      />

    </BasicLayout>);
};

AgreementConfiguration.propTypes = {
  cardAccounts: PropTypes.arrayOf(types.walletReducerTypes.cardAccounts),
  cancelPayment: PropTypes.func,
  description: types.recurrentPaymentReducerTypes.description,
  destWalletHandle: types.recurrentPaymentReducerTypes.destWalletHandle,
  isBankAccountsDisabled: PropTypes.bool,
  makePreflight: PropTypes.func,
  paymentInProcess: types.recurrentPaymentReducerTypes.paymentInProcess,
  permissions: types.EnabledStatesShape,
  preflightAccounts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  preflightIsLoading: PropTypes.bool,
  confirmPayment: PropTypes.func,
  selectedAccount: types.AccountShape,
  selectedBankAccount: types.BankAccountShape,
  setField: PropTypes.func,
  isCreditCardsDisabled: PropTypes.bool,
  hasPreflightAccounts: PropTypes.bool,
  hasMorePaymentMethods: PropTypes.bool,
};

export default AgreementConfiguration;