import { CONSTANTS } from '../constants/invoice-constants';

export const setField = (path, value) => ({
  type: CONSTANTS.SET_FIELD,
  payload: { path, value },
});

export const getInvoiceDetails = (invoiceId) => ({
  type: CONSTANTS.GET_INVOICE_DETAILS,
  payload: { invoiceId },
});

export const createInvoice = () => ({
  type: CONSTANTS.CREATE_INVOICE,
});

export const setValidationError = (field, error) => ({
  type: CONSTANTS.SET_VALIDATION_ERROR,
  payload: { field, error },
});

export const clearFilters = () => ({
  type: CONSTANTS.CLEAR_FILTERS,
});

export const applyFilters = (filters) => ({
  type: CONSTANTS.APPLY_FILTERS,
  payload: { filters },
});

export const cancelInvoice = (invoiceId) => ({
  type: CONSTANTS.CANCEL_INVOICE,
  payload: { invoiceId },
});

export const createNewInvoice = (invoice) => ({
  type: CONSTANTS.CREATE_NEW_INVOICE,
  payload: { invoice },
});

export const deleteInvoice = (invoiceId) => ({
  type: CONSTANTS.DELETE_INVOICE,
  payload: { invoiceId },
});

export const getCompletedInvoices = () => ({
  type: CONSTANTS.GET_COMPLETED_INVOICES,
});

export const getPendingInvoices = () => ({
  type: CONSTANTS.GET_PENDING_INVOICES,
});

export const closeInvoiceForm = () => ({
  type: CONSTANTS.CLOSE_INVOICE_FORM,
});

export const clearInvoiceForm = () => ({
  type: CONSTANTS.CLEAR_INVOICE_FORM,
});

export const openInvoiceForm = () => ({
  type: CONSTANTS.OPEN_INVOICE_FORM,
});
