export const TIMEFRAME_INTERVALS = {
  ANY: 'ANY',
  LAST_90: 'LAST_90',
  CUSTOM: 'CUSTOM',
  LAST_30: 'LAST_30',
  LAST_YEAR: 'LAST_YEAR',
  THIS_MONTH: 'THIS_MONTH',
  LAST_WEEK: 'LAST_WEEK',
  TODAY: 'TODAY',
};

export const TRANSACTION_DIRECTION = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
};

export const TRANSACTION_STATUSES = {
  COMPLETE: 'complete',
  PENDING: 'pending',
  SUSPENDED: 'suspended',
  FAILED: 'failed',
  PARTIALLY_REFUNDED: 'partially_refunded',
  FULLY_REFUNDED: 'fully_refunded'
};

export const CONSTANTS = {
  SET_FIELD: '@transactions/SET_FIELD',
  APPLY_FILTERS: '@transactions/APPLY_FILTERS',
  CLEAR_FILTERS: '@transactions/CLEAR_FILTERS',
  GET_TRANSACTION_DETAILS: '@transactions/GET_TRANSACTION_DETAILS',
  GET_COMPLETED_TRANSACTIONS: '@transactions/GET_COMPLETED_TRANSACTIONS',
  GET_PENDING_TRANSACTIONS: '@transactions/GET_PENDING_TRANSACTIONS',
  SET_PENDING_TRANSACTIONS: '@transactions/SET_PENDING_TRANSACTIONS',
  SET_COMPLETED_TRANSACTIONS: '@transactions/SET_COMPLETED_TRANSACTIONS',
  APPEND_COMPLETED_TRANSACTIONS: '@transactions/APPEND_COMPLETED_TRANSACTIONS',
  GET_NEXT_COMPLETED_TRANSACTIONS: '@transactions/GET_NEXT_COMPLETED_TRANSACTIONS',
  GET_NEXT_90: '@transactions/GET_NEXT_90',
  REPORT: '@transactions/REPORT',
  SHOW_PREDEFINED_ORDER_RESULT: '@transactions/SHOW_PREDEFINED_ORDER_RESULT',

  STATUS_PENDING_TRANSACTION: 'pending',
  STATUS_COMPLETED_TRANSACTION: 'complete',

  MINUS: '-',
  PLUS: '+',
  MDASH: '—',
  TRANSFER: 'transfer',
  PROMOTION: 'promotion',
  ORDER: 'order',
  PAYMENT: 'payment',
  RECONCILIATION: 'reconciliation',
  EXCHANGE: 'exchange',
  CREDIT: 'credit',
  FEE: 'fee',
  REFUND: 'refund',
  INVOICE: 'invoice',

  BANK_TRANSFER_IN_PROGRESS: 'Bank transfer in progress',
  BANK_TRANSFER_FAILED: 'Bank transfer failed',
  TRANSACTION_SUSPENDED: 'Contact Customer Support',

  COMPLETED_PAGE_LIMIT: 30,

  TRANSACTION_STATUSES,

  TRANSACTION_DIRECTION,

  TIMEFRAME_INTERVALS,

  TRANSACTION_STATUS_COLOR: {
    [TRANSACTION_STATUSES.COMPLETE]: 'green',
    [TRANSACTION_STATUSES.SUSPENDED]: 'orange',
    [TRANSACTION_STATUSES.PENDING]: 'blue',
    [TRANSACTION_STATUSES.FAILED]: 'red',
    [TRANSACTION_STATUSES.PARTIALLY_REFUNDED]: 'green',
    [TRANSACTION_STATUSES.FULLY_REFUNDED]: 'green'
  },

  TRANSACTION_MAPPER: {
    '+': {
      VERB: 'Received',
      INF: 'Incoming',
      PATH: 'source',
      PREFIX: 'Sold',
      DESCRIPTION_PREFIX: 'Sold',
    },
    '-': {
      VERB: 'Sent to',
      INF: 'Sending',
      PATH: 'destination',
      PREFIX: 'Purchased',
      DESCRIPTION_PREFIX: 'Purchased for',
    },
  },

  HUMAN_READABLE_STATUSES: {
    [TRANSACTION_STATUSES.COMPLETE]: 'Complete',
    [TRANSACTION_STATUSES.PENDING]: 'Pending',
    [TRANSACTION_STATUSES.SUSPENDED]: 'Suspended',
    [TRANSACTION_STATUSES.FAILED]: 'Failed',
    [TRANSACTION_STATUSES.PARTIALLY_REFUNDED]: 'Partially refunded',
    [TRANSACTION_STATUSES.FULLY_REFUNDED]: 'Fully refunded'
  },

  // https://bitrail.slack.com/archives/CC1K2GHFA/p1628255906029700?thread_ts=1628181492.027900&cid=CC1K2GHFA
  TRANSACTION_RELATIVES_TYPES: {
    RECONCILE: 'reconcile',
    DISPUTE: 'dispute',
    ABANDONED: 'abandoned',
    REFUNDED: 'refunded',
    DUPLICATE_TRANSFER: 'duplicate_transfer'
  }
};

export default CONSTANTS;
