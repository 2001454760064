import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import cn from 'classnames';

import { isEmpty, find } from 'ramda';
import { trackEvent } from '../utils/ganalytics';

import {
  getBankAccounts,
  updateBankAccountLabel,
  removeBankAccount,
  clearForm,
  addBankAccountInstant,
  setField,
  addBankAccountMassive,
} from '../actions/bank-account-actions';

import BasicLayout from './basic-layout';
import Button from '../components/button';
import InfoText from '../components/info-text';
import ModalAccountDetails from '../components/bank-accounts/modal-account-details';
import ModalEditAccountLabel from '../components/bank-accounts/modal-edit-account-label';
import ModalAccountOwnershipValidation from '../components/bank-accounts/modal-account-ownership-validation';
import EmptyPlate from '../components/emptyPlate';
import YodleeModal from '../components/yodlee-modal';
import ModalDeletionConfirmation from '../components/modal-deletion-confirmation';
import BankAccountsInfoModal from '../components/bank-accounts-info-modal';
import BankAccountsProceedToAddModal from '../components/bank-accounts-proceed-to-add-modal';
import { permissionAllowedLimit, permissionErrorMessage } from '../utils/permissions';
import NotificationBadge from '../components/notification-badge';
import ModalACH from '../components/modal-ach';
import Access from '../components/hoc/access';
import { ACCESS_NAMES } from '../constants/wallet-constants';

const BankAccounts = (props) => {

  useEffect(() => {
    props.getBankAccounts();
  }, []);

  const [bankId, setBankId] = useState(null);
  const [accountDetailsModalOpened, setAccountDetailsModalOpened] = useState(false);
  const [editAccountLabelModalOpened, setEditAccountLabelModalOpened] = useState(false);
  const [accountOwnershipValidationModalOpened, setAccountOwnershipValidationModalOpened] = useState(false);
  const [accountDeletionConfirmationModalOpened, setAccountDeletionConfirmationModalOpened] = useState(false);
  const [bankAccountInfoModalShown, setBankAccountInfoModalShown] = useState(false);
  const [bankAccountsProceedToAddModalShown, setBankAccountsProceedToAddModalShown] = useState(false);
  const [achModalShown, setAchModalShown] = useState(false);

  const initState = () => {
    setBankId(null);
    setAccountDetailsModalOpened(false);
    setEditAccountLabelModalOpened(false);
    setAccountOwnershipValidationModalOpened(false);
    setAccountDeletionConfirmationModalOpened(false);
    setBankAccountInfoModalShown(false);
    setBankAccountsProceedToAddModalShown(false);
  };

  // This effect needed for closing modals after save changes
  useEffect(() => {
    initState();
  }, [props.bankAccounts]);

  const toggleProceedModal = () => {
    setBankAccountsProceedToAddModalShown(!bankAccountsProceedToAddModalShown);
  };

  const toggleAccountDetailsModal = () => {
    setAccountDetailsModalOpened(!accountDetailsModalOpened);
  };

  const toggleEditAccountLabelModal = () => {
    setEditAccountLabelModalOpened(!editAccountLabelModalOpened);
  };

  const toggleAccountOwnershipValidationModal = () => {
    setAccountOwnershipValidationModalOpened(!accountOwnershipValidationModalOpened);
  };

  const toggleAccountDeletionConfirmationModal = () => {
    setAccountDeletionConfirmationModalOpened(!accountDeletionConfirmationModalOpened);
  };

  const toggleAchModal = () => {
    setAchModalShown(!achModalShown);
  };  

  const toggleInfoModal = () => {
    setBankAccountInfoModalShown(!bankAccountInfoModalShown);
  };  

  const showAccountDetails = (bankId) => {
    const { bankAccountFlow: { isLoading } } = props;
    if (!isLoading) {
      setBankId(bankId);
      toggleAccountDetailsModal();
    }
  };

  const changeBankAccountLabel = (label) => {
    if (label) {
      props.updateBankAccountLabel(bankId, label);
    }
  };

  const removeBankAccount = () => {
    if (bankId) {
      props.removeBankAccount(bankId);
    }
  };

  const addBankAccountInstant = () => {
    trackEvent('started_yodlee_flow', 'Button "Add Bank Account Instantly" clicked');
    setBankAccountsProceedToAddModalShown(false);
    props.addBankAccountInstant();
  };

  const {
    bankAccounts,
    bankAccountFlow: {
      isLoading,
      errorMessage,
      yodleeModalShown,
      yodleeUrl,
      yodleeJWT,
      yodleeServiceId,
      isYodleeDisabled,
      configName,
    },
    permissions,
    user: { email_verified: emailVerified },
    setField,
    addBankAccountMassive,
  } = props;

  const bankAccount = find(bank => bank.bank_account_id === bankId, bankAccounts) || {};
  const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');

  return (
    <BasicLayout>
      <div className="page_header -relative">
        <h1 className="page_title">Bank Accounts</h1>
        <Access requiredAccess={ACCESS_NAMES.OWNER}>
          <Button
            xSize="xs"
            ySize="min"
            color="blue"
            className="-right-button"
            transparency="full"
            onClick={() => toggleInfoModal()}
          >
            How to add
          </Button>
        </Access>
      </div>
      <div className="page_body">
        <div className="page_content">
          {maxAchAllowed <= 0 && (
            <NotificationBadge 
              type="permission"
              message={permissionErrorMessage(permissions, 'max_ach_allowed')}
              isSlim={true}
            />
          )}
          {isEmpty(bankAccounts) && (
            <EmptyPlate
              className="-no-bank-accounts"
              plateColor="white"
              iconName="bank-building"
              iconClassName="splash_icon"
              placeholderText="Your Bank Accounts will appear here"
            />
          )}
          {bankAccounts.length > 0 && (
            <div className="button-group full-width">
              <div className="button-group_body">
                {bankAccounts.map(bank => (
                  <Button
                    key={bank.bank_account_id}
                    xSize="full"
                    ySize="xl"
                    className="button-group_item"
                    color="white"
                    onClick={() => showAccountDetails(bank.bank_account_id)}
                    disabled={isLoading}
                  >
                    <div className="button_head">
                      <div className="button_content-primary js-nickname">{bank.user_description}</div>
                      <div className="button_content-secondary">
                        {bank.bank_name || 'Bank Account'}&nbsp;
                        {bank.withdraw_status !== 'allowed' && (
                          <div className="label">No withdrawal</div>
                        )}
                        {bank.account_type === 'savings' && (
                          <div className="label">Savings</div>
                        )}
                      </div>
                    </div>
                    <div className="button_tail">
                      <div className="button_content-primary font-weight-regular">
                        <span className="account-number-bullets">&bull;&bull;</span>{bank.account_number}
                      </div>
                      {bank.amounts_verified ? (
                        <div className="button_content-secondary js-verified-status color-green">Verified</div>
                      ) : (
                        <div className="button_content-secondary js-not-verified-status color-red">Not Verified</div>
                      )}
                    </div>
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <div className="page_controls -align-top-desktop">
          <InfoText>
            <span>By adding a bank account, you agree with the <a onClick={() => toggleAchModal()}>ACH&nbsp;Authorization</a></span>
          </InfoText>
          <Button
            color="blue"
            xSize="full"
            className="page_control"
            disabled={isLoading}
            loading={isLoading}
            onClick={() => addBankAccountInstant()}
          >
              Add Bank Account Instantly
          </Button>
        </div>
      </Access>

      <ModalACH
        shown={achModalShown}
        onClose={() => toggleAchModal()}
      />

      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <YodleeModal
          shown={yodleeModalShown}
          bankAccountFlow={{
            yodleeUrl,
            yodleeJWT,
            yodleeServiceId,
            configName,
          }}
          isYodleeDisabled={isYodleeDisabled}
          setField={setField}
          addBankAccountMassive={ bankAccounts => addBankAccountMassive(bankAccounts) }
          onClose={() => setField('yodleeModalShown', false)}
          isLoading={isLoading}
          closeButton
        />
      </Access>

      <ModalAccountDetails
        bankDetails={bankAccount}
        show={accountDetailsModalOpened}
        onClose={() => { props.clearForm(); toggleAccountDetailsModal(); }}
        toggleEditAccountLabelModal={toggleEditAccountLabelModal}
        toggleVerifyBankAccount={toggleAccountOwnershipValidationModal}
        removeBankAccount={toggleAccountDeletionConfirmationModal}
        containerClassName={
          cn({
            '-bottom': editAccountLabelModalOpened || accountOwnershipValidationModalOpened,
          })
        }
        isLoading={isLoading}
        closeButton
      />

      <ModalAccountOwnershipValidation
        bankAccount={bankAccount}
        errorMessage={errorMessage}
        show={accountOwnershipValidationModalOpened}
        containerClassName={cn({ '-top': accountOwnershipValidationModalOpened })}
        onClose={() => { props.clearForm(); toggleAccountOwnershipValidationModal(); }}
        isLoading={isLoading}
      />

      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <ModalEditAccountLabel
          bankAccount={bankAccount}
          show={editAccountLabelModalOpened}
          containerClassName={cn({ '-top': accountDetailsModalOpened })}
          changeBankAccountLabel={changeBankAccountLabel}
          onClose={() => toggleEditAccountLabelModal()}
          isLoading={isLoading}
        />
      </Access>

      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <ModalDeletionConfirmation
          show={accountDeletionConfirmationModalOpened}
          confirmationText="Do&nbsp;you really want to&nbsp;remove this account?"
          onConfirm={removeBankAccount}
          onClose={() => { props.clearForm(); toggleAccountDeletionConfirmationModal(); }}
          isLoading={isLoading}
        />
      </Access>

      <BankAccountsInfoModal
        show={bankAccountInfoModalShown}
        onClose={() => toggleInfoModal()}
        emailVerified={emailVerified}
        addBankAccountInstant={() => addBankAccountInstant()}
        isLoading={isLoading}
      />

      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <BankAccountsProceedToAddModal
          show={bankAccountsProceedToAddModalShown}
          onClose={() => toggleProceedModal()}
          onManualLink={'/bank-accounts/new'}
          onInstant={() => addBankAccountInstant()}
        />
      </Access>

    </BasicLayout>
  );
};

BankAccounts.propTypes = {
  user: types.userReducerTypes.user,
  bankAccountFlow: PropTypes.shape({
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    yodleeModalShown: PropTypes.bool,
    yodleeUrl: PropTypes.string,
    yodleeJWT: PropTypes.string,
    yodleeServiceId: PropTypes.string,
    isYodleeDisabled: PropTypes.bool,
    configName: PropTypes.string,
  }),
  bankAccounts: PropTypes.arrayOf(types.BankAccountShape),
  permissions: types.EnabledStatesShape,
  getBankAccounts: PropTypes.func,
  updateBankAccountLabel: PropTypes.func,
  removeBankAccount: PropTypes.func,
  clearForm: PropTypes.func,
  addBankAccountInstant: PropTypes.func,
  setField: PropTypes.func,
  addBankAccountMassive: PropTypes.func
};

function mapStateToProps(state) {
  return {
    bankAccountFlow: state.bankAccount,
    bankAccounts: state.wallet.bankAccounts,
    permissions: state.user.user.enabled_states,
    user: state.user.user,
  };
}

export default connect(mapStateToProps, {
  getBankAccounts,
  updateBankAccountLabel,
  removeBankAccount,
  clearForm,
  addBankAccountInstant,
  setField,
  addBankAccountMassive,
})(BankAccounts);
