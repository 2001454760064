import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import stickybits from 'stickybits';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Portal } from './portal';
import { BREAKPOINTS } from './breakpoints';

const Modal = (props) => {
  const MODALS_OPEN = useRef(0);
  const headerStickyBitsInstance = useRef(null);
  const closeButtonStickyBitsInstance = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if(props.show) {
      onHide();
    }
  }, []);

  const onShow = () => {
    if (MODALS_OPEN.current === 0) {
      if (!props.confirmation) {
        document.body.classList.add('modal-open');
      }
    }

    if (props.confirmation) {
      document.body.classList.add('confirmation-open');
    }

    MODALS_OPEN.current += 1;

    if (props.onClose) {
      document.addEventListener('keyup', onKeyUp, true);
    }

    initStickyBits();
    disableBodyScroll(modalRef.current);
  };

  const onHide = () => {
    MODALS_OPEN.current = Math.max(0, MODALS_OPEN.current - 1);

    if (MODALS_OPEN.current === 0) {
      if (!props.confirmation) {
        document.body.classList.remove('modal-open');
      }
    }

    if (props.confirmation) {
      document.body.classList.remove('confirmation-open');
    }

    if (props.onClose) {
      document.removeEventListener('keyup', onKeyUp, true);
    }

    destroyStickyBits();

    const isMobile = window.innerWidth < BREAKPOINTS.desktop;
    const mobileMenuOpen = document.querySelector('.js-header').classList.contains('-menu-opened');

    // body scroll lock should be released only if we are on desktop
    // or we are on mobile and menu is closed
    if (!isMobile || (isMobile && !mobileMenuOpen)) {
      enableBodyScroll(modalRef.current);
      document.body.style.overflow = '';
    }
  };

  const onKeyUp = (e) => {
    const { onClose } = props;
    const { keyCode } = e;
    // esc
    if (onClose && keyCode === 27) {
      onClose();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const updateStickyBitsInstances = () => {
    headerStickyBitsInstance.current.update();
    closeButtonStickyBitsInstance.current.update();
  };

  const initStickyBits = () => {
    const modalHeaders = modalRef.current.querySelectorAll('.modal_header');
    const modalClose = modalRef.current.querySelectorAll('.modal_close');

    headerStickyBitsInstance.current = stickybits(modalHeaders, {
      useStickyClasses: true,
      stickyClass: '-stuck-visible',
      stuckClass: '-stuck-scrolled',
      scrollEl: modalRef.current,
    });

    closeButtonStickyBitsInstance.current = stickybits(modalClose, {
      useStickyClasses: true,
      stickyClass: '-stuck-visible',
      stuckClass: '-stuck-scrolled',
      useFixed: true,
      noStyles: true,
      scrollEl: modalRef.current,
      stickyBitStickyOffset: -30,
    });

    window.addEventListener('resize', updateStickyBitsInstances);
  };

  const destroyStickyBits = () => {
    window.removeEventListener('resize', updateStickyBitsInstances);

    try {
      headerStickyBitsInstance && headerStickyBitsInstance.current.cleanup();
      closeButtonStickyBitsInstance && closeButtonStickyBitsInstance.current.cleanup();
    } catch (e) {

    }
  };

  const {
    className, children, show, onClose, closeButton, isHalfling,
    closeButtonClassName, containerClassName, confirmation, higher,
  } = props;

  const classNames = cn('modal', {
    '-open': show,
    '-has-close-button': closeButton,
    '-confirmation': confirmation,
    '-higher': higher,
    '-halfling': isHalfling
  }, containerClassName);

  return (
    <Portal>
      <CSSTransition
        in={show}
        mountOnEnter
        unmountOnExit
        appear
        timeout={500}
        onEnter={onShow}
        onExit={onHide}
        classNames=""
      >
        <div
          className={classNames}
          ref={modalRef}
        >
          <div className="modal_backdrop" onClick={onClose} tabIndex={-1} role="button" />
          <div className={cn('modal_dialog', className)} role="dialog">
            {closeButton && (
              <button onClick={onClose} className={cn('js-cancel-modal-button modal_close', closeButtonClassName)} />
            )}
            <div className="modal_content">
              {children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  confirmation: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  closeButton: PropTypes.bool,
  closeButtonClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  higher: PropTypes.bool,
  isHalfling: PropTypes.bool
};

Modal.defaultProps = {
  closeButton: false,
  containerClassName: '',
  confirmation: false,
};

export default Modal;
