import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TransactionShape, WalletShape, CurrencyOptionShape, AccountShape } from '../constants/prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { prop, pathEq, filter, path, isEmpty } from 'ramda';
import Avatar from '../components/avatar';
import Svg from '../components/svg';
import {
  getTransactionType,
  getWalletToShow,
  findAccountBySign,
  findRejectedBA,
  findInProgress,
  getDescription,
  getIsIncoming,
  getAccountName
} from '../utils/transaction-utils';
import { withCurrency } from '../utils/send-money-utils';

import CONST from '../constants/transactions-constants';
import { currencyOptionsSelector } from '../reducers/app-reducer';

const {
  PAYMENT,
  ORDER,
  TRANSFER,
  PROMOTION,
  EXCHANGE,
  CREDIT,
  INVOICE,
  PLUS,
  HUMAN_READABLE_STATUSES,
  TRANSACTION_STATUSES,
} = CONST;

const NotSupported = ({ transaction }) => (
  <TransactionBody
    avatar={
      <Avatar className="-status">
        <Svg name="usd" className="avatar_status-icon" />
      </Avatar>
    }
    name={transaction.description}
    transaction={transaction}
    description={getDescription(transaction)}
  />
);

const TransactionBody = ({ avatar, receipt, destination, name, transaction, description, additionalInfo, filterByAccount, withoutSign }) => {
  const bankTransaction = filter(i => prop('account_type', i) === 'bankaccount', transaction.transaction_details.all)[0];
  const isIncomingTransfer = path(['transaction_amount', 'sign'], bankTransaction) !== CONST.PLUS;
  const isIncoming = getIsIncoming(transaction) || (transaction.type === CONST.TRANSFER && isIncomingTransfer) || transaction.type === PROMOTION; 
  const isFail = !!findRejectedBA(transaction);
  const isSuspended = transaction.status === TRANSACTION_STATUSES.SUSPENDED;
  const isFailed = transaction.status === TRANSACTION_STATUSES.FAILED;
  const isSuccess = !isSuspended && !isFail && !isFailed && isIncoming && transaction.type !== INVOICE;
  const grossAmount = prop('gross_amount', transaction);
  const amount = !isEmpty(grossAmount) ? `${Math.abs(grossAmount.usd_equivalent) / 100}` : 0;
  const conversion = withoutSign ? `${withCurrency(amount, 'USD')}` : `${isIncoming ? PLUS : '-'}${withCurrency(amount, 'USD')}`;

  return (
    <Fragment>
      {avatar || (
        <Avatar
          imageSrc={prop('avatar_image_url', destination)}
          label={receipt && <Svg name="receipt" className="avatar_label -purple" />}
          className={cn('transactions-log-item_avatar', !prop('avatar_image_url', destination) ? '-status' : '-gray')}
          type={destination.type}
        />
      )}
      <div className="transactions-log-item_body">
        <div className="transactions-log-item_body-main">
          <div className={cn('transactions-log-item_title', {
            '-status-fail': isFail || isFailed
          })}>
            {name}
          </div>
          <div className={cn('transactions-log-item_value', {
            '-status-success': isSuccess,
            '-status-fail': isFail || isFailed,
            '-status-alert': isSuspended,
          })}
          >
            {conversion}
          </div>
        </div>
        {additionalInfo || (
          <div className="transactions-log-item_body-secondary">
            <span
              className={cn({
                'transactions-log-item_status': isFailed || isSuspended,
                '-status-fail': isFailed,
                '-status-alert': isSuspended,
              })}
            >
              {HUMAN_READABLE_STATUSES[transaction.status]}
              {description && (
                <Fragment>
                  &nbsp;&mdash;&nbsp;
                  <span className={cn('transactions-log-item_status', {
                    '-status-fail': isFail,
                    '-status-alert': isSuspended,
                  })}
                  >
                    {description}
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        )}
      </div>
    </Fragment>
  );
};

TransactionBody.propTypes = {
  additionalInfo: PropTypes.node,
  avatar: PropTypes.node,
  description: PropTypes.string,
  destination: WalletShape,
  name: PropTypes.string.isRequired,
  receipt: PropTypes.bool,
  transaction: TransactionShape.isRequired,
  filterByAccount: PropTypes.string,
  withoutSign: PropTypes.bool,
};

const TransactionTransfer = ({ transaction, filterByAccount }) => (
  <TransactionBody
    avatar={
      <Avatar className="-status">
        <Svg name="usd" className="avatar_status-icon" />
      </Avatar>
    }
    name="Bank account"
    transaction={transaction}
    description={getDescription(transaction)}
    filterByAccount={filterByAccount}
  />
);

const TransactionCredit = ({ transaction, filterByAccount }) => (
  <TransactionBody
    avatar={
      <Avatar className="-status">
        <Svg name="usd" className="avatar_status-icon" />
      </Avatar>
    }
    name={transaction.description || 'Promotional credit'}
    transaction={transaction}
    description={getDescription(transaction)}
    filterByAccount={filterByAccount}
  />
);

const TransactionOrder = ({ transaction, filterByAccount }) => {
  const isIncoming = getIsIncoming(transaction);
  const sign = isIncoming ? '+' : '-';
  const destination = getWalletToShow(transaction, sign);

  return (
    <TransactionBody
      receipt
      name={destination.name}
      destination={destination}
      transaction={transaction}
      description={getDescription(transaction)}
      filterByAccount={filterByAccount}
    />
  );
};

const TransactionPayment = ({ transaction, filterByAccount }) => {
  const isIncoming = getIsIncoming(transaction);
  const sign = isIncoming ? '+' : '-';
  const destination = getWalletToShow(transaction, sign);

  return (
    <TransactionBody
      name={destination.name}
      destination={destination}
      transaction={transaction}
      description={getDescription(transaction)}
      filterByAccount={filterByAccount}
    />
  );
};

const TransactionExchange = ({ transaction, currencyOptions, filterByAccount, accounts }) => {
  const sourceAccountName = getAccountName(findAccountBySign(transaction, CONST.MINUS), accounts);
  const targetAccountName = getAccountName(findAccountBySign(transaction, CONST.PLUS), accounts);

  const isFail = !!findRejectedBA(transaction);
  const isSuspended = transaction.status === TRANSACTION_STATUSES.SUSPENDED;
  const inProgress = findInProgress(transaction);
  const isFailed = transaction.status === TRANSACTION_STATUSES.FAILED;
  const isSuccess = !isSuspended && !isFail && !isFailed
    && !inProgress
    && pathEq(['gross_amount', 'sign'], PLUS, transaction);

  return (
    <TransactionBody
      success
      avatar={
        <Avatar className="-status -green">
          <Svg name="arrows-big" className="avatar_status-icon" />
        </Avatar>
      }
      name={`${sourceAccountName} → ${targetAccountName}`}
      transaction={transaction}
      filterByAccount={filterByAccount}
      additionalInfo={
        <Fragment>
          {isSuccess
            ? (
              <div className="transactions-log-item_body-secondary">
                Complete
              </div>
            ) : (
              <span
                className={cn('transactions-log-item_body-secondary', {
                  'transactions-log-item_status': isFailed || isSuspended,
                  '-status-fail': isFailed,
                  '-status-alert': isSuspended,
                })}
              >
                {HUMAN_READABLE_STATUSES[transaction.status]}
                <span className={cn('transactions-log-item_status', {
                  '-status-fail': isFail,
                  '-status-alert': isSuspended,
                })}
                >
                  {!!getDescription(transaction) && (' \u2014 ' + getDescription(transaction))}
                </span>
              </span>
            )
          }
        </Fragment>
      }
    />
  );
};

const TransactionInvoice = ({ transaction, filterByAccount }) => {

  return (
    <TransactionBody
      avatar={
        <Avatar className="-status">
          <Svg name="invoice-icon" className="avatar_status-icon" />
        </Avatar>
      }
      name={transaction.name}
      destination={transaction.destination}
      additionalInfo={(
        <div className="transactions-log-item_body-secondary">
          <span className={cn({
            'color-red': ['Expired', 'Canceled', 'Deleted'].includes(transaction.invoiceStatus),
            'color-green': ['Paid'].includes(transaction.invoiceStatus),
            '-first-letter-capitalize': true,
          })}>
            {transaction.invoiceStatus}
          </span>
        </div>
      )}
      transaction={transaction}
      withoutSign={true}
    />
  );
};

const TRANSACTION_COMPONENTS = {
  [PAYMENT]: TransactionPayment,
  [ORDER]: TransactionOrder,
  [CREDIT]: TransactionCredit,
  [PROMOTION]: TransactionCredit,
  [TRANSFER]: TransactionTransfer,
  [EXCHANGE]: TransactionExchange,
  [INVOICE]: TransactionInvoice,
};

const Transaction = ({ transaction, onClick, currencyOptions, filterByAccount, ...rest }) => {
  const TransactionComponent = TRANSACTION_COMPONENTS[getTransactionType(transaction)];

  return (
    <div
      onClick={() => onClick(transaction)}
      className="js-dashboard-transaction-item transactions-log_item transactions-log-item cursor-pointer"
    >
      { TransactionComponent
        ? <TransactionComponent
          {...rest}
          transaction={transaction}
          currencyOptions={currencyOptions}
          filterByAccount={filterByAccount}
        />
        : <NotSupported transaction={transaction} />
      }
    </div>
  );
};

Transaction.propTypes = {
  transaction: TransactionShape,
  onClick: PropTypes.func,
  currencyOptions: PropTypes.arrayOf(CurrencyOptionShape),
  filterByAccount: PropTypes.string,
  accounts: PropTypes.arrayOf(AccountShape),
};

TransactionPayment.propTypes = {
  transaction: TransactionShape.isRequired,
};

TransactionCredit.propTypes = TransactionPayment.propTypes;
TransactionOrder.propTypes = TransactionPayment.propTypes;
TransactionTransfer.propTypes = TransactionPayment.propTypes;
TransactionExchange.propTypes = TransactionPayment.propTypes;
TransactionInvoice.propTypes = TransactionPayment.propTypes;
NotSupported.propTypes = TransactionPayment.propTypes;

const mapStateToProps = (state) => ({
  accounts: state.wallet.accounts,
  currencyOptions: currencyOptionsSelector(state)
});

export default connect(mapStateToProps, null)(Transaction);
