import CONST from '../constants/sign-up-constants';

export const authLinkFlow = () => ({
  type: CONST.AUTH_LINK_FLOW,
});

export const openPopup = () => ({
  type: CONST.OPEN_POPUP,
});

export const clearState = () => ({
  type: CONST.CLEAR_STATE,
});

export const updateAddress = value => ({
  type: CONST.UPDATE_ADDRESS,
  payload: { value },
});

export const updateName = value => ({
  type: CONST.UPDATE_NAME,
  payload: { value },
});

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const setValidationError = (field, message) => ({
  type: CONST.SET_VALIDATION_ERROR,
  payload: { field, message },
});

export const setPopup = (field, state) => ({
  type: CONST.SET_POPUP,
  payload: { field, state },
});

export const endStep = step => ({
  type: CONST.END_STEP,
  payload: { step },
});

export const consentAgreement = () => ({
  type: CONST.CONSENT_AGREEMENT
});

export const agreementConsented = () => ({
  type: CONST.AGREEMENT_CONSENTED
});

export const submitDocumentation = () => ({
  type: CONST.SUBMIT_DOCUMENTATION
});

export const getSingularKey = () => ({
  type: CONST.GET_SINGULAR_KEY
});

export const startVerification = () => ({
  type: CONST.START_VERIFICATION
});

export const returnToPhoneStep = () => ({
  type: CONST.RETURN_TO_PHONE_STEP
});