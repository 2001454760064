import React, { useEffect, useState, useRef, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import stickybits from 'stickybits';
import { contains, reject, equals, append } from 'ramda';
import 'smoothscroll-polyfill';
import { TransactionShape, userReducerTypes, TransactionFiltersShape, InvoiceShape } from '../../constants/prop-types';
import BasicLayout from '../basic-layout';
import Button from '../../components/button';
import { setField, clearFilters, applyFilters, getCompletedInvoices, getPendingInvoices, openInvoiceForm } from '../../actions/invoice-actions';
import TransactionsList from '../../components/transactions-list';
import { OnDesktop, OnMobile } from '../../components/breakpoints';
import CONST from '../../constants/transactions-constants';
import { predefinedTimeFrame } from '../../utils/transaction-utils';
import Svg from '../../components/svg';
import InvoiceFilterMobile from './invoice-filter-mobile';
import InvoiceFilterDesktop from './invoice-filter-desktop';
import InvoiceFilterDateRange from './invoice-filter-date-range';
import InvoiceDetailsModal from './invoice-details-modal';

export const DEFAULT_FILTERS = {
  search: '',
  status: [],
  direction: [],
  timeFrameValue: CONST.TIMEFRAME_INTERVALS.LAST_90,
  timeFrameDates: predefinedTimeFrame(CONST.TIMEFRAME_INTERVALS.LAST_90),
};


const INIT_STATE = {
  isFirstDate: true,
  daysCount: 90,
  transactionShown: null,
  filterVisible: false,
  filterApplied: false,
  searchInputFocused: false,
  searchInputEmpty: true,
  filters: DEFAULT_FILTERS,
  calendarOpened: false,
  range: null,
  disabledDates: {
    minDate: moment().subtract(5, 'years').startOf('week').toDate(),
    maxDate: moment().add(1, 'years').endOf('week').toDate(),
  },
};


const InvoiceList = ({
  getCompletedInvoices,
  getPendingInvoices,
  isLoading,
  setField,
  user,
  invoiceShown,
  invoiceToShow,
  completedInvoiceList,
  pendingInvoiceList,
  completedInvoiceTransactions,
  pendingInvoiceTransactions,
  openInvoiceForm,
  ...props
}) => {
  const [state, _setState] = useState(INIT_STATE);
  let stickyBitsInstance = useRef(null);
  let oldRange = useRef(null);
  const filterPlateRef = useRef(null);
  const searchInputRef = useRef(null);
  const rangeRef = useRef(null);
  const noInvoices = completedInvoiceList.length === 0 && pendingInvoiceList.length === 0 && !isLoading;
  const changeState = (value) => _setState({...state, ...value});

  useEffect(() => {
    getCompletedInvoices();
    getPendingInvoices();
  }, []);

  useEffect(() => {
    INIT_STATE.disabledDates.minDate = moment(user.created_at).startOf('month').toDate();

    _setState(INIT_STATE);

    if (window.location.search) {
      const params = parse(window.location.search, { ignoreQueryPrefix: true });

      if (params.invoice) {
        setField('invoiceShown', true);
        setField('invoiceToShow', { invoice_id: params.invoice });
      }
    } else {
      setField('invoiceShown', false);
    }

    stickyBitsInstance.current = stickybits('.js-transactions-group-header', {
      useStickyClasses: true,
      stickyBitStickyOffset: 68, // height of both mobile and desktop headers
      stickyClass: '-stuck-visible',
      stuckClass: '-stuck-scrolled',
    });

    window.addEventListener('resize', updateStickyBitsInstance);
    window.addEventListener('mousedown', handleClickOutsideFilterPlate);

    return () => {
      stickyBitsInstance.current.cleanup();
      props.clearFilters();
      window.removeEventListener('resize', updateStickyBitsInstance);
      window.removeEventListener('mousedown', handleClickOutsideFilterPlate);
    };
  }, []);

  const updateStickyBitsInstance = () => {
    stickyBitsInstance.current.update();
  };

  const handleClickOutsideFilterPlate = (event) => {
    const { filterVisible, searchInputFocused, filters: { search } } = state;
    if ((filterPlateRef.current && !filterPlateRef.current.contains(event.target) && filterVisible) ||
      (searchInputRef.current && !searchInputRef.current.contains(event.target) && searchInputFocused)
    ) {
      changeState({
        filterVisible: false,
        searchInputFocused: false,
        filters: {
          ...props.filters,
          search,
        },
      });
    }
  };

  const resetFilters = () => {
    changeState({
      filterVisible: false,
      filterApplied: false,
      searchInputFocused: false,
      searchInputEmpty: state.searchInputEmpty,
      filters: {
        ...DEFAULT_FILTERS,
        search: state.filters.search,
      },
      calendarOpened: false,
      range: null,
      disabledDates: {
        minDate: INIT_STATE.disabledDates.minDate,
        maxDate: INIT_STATE.disabledDates.maxDate,
      }
    });
    props.applyFilters({
      ...DEFAULT_FILTERS,
      search: state.filters.search,
    });
  };

  const applyFilters = () => {
    props.applyFilters(state.filters);

    changeState({
      filterVisible: false,
      filterApplied: true,
      searchInputFocused: false,
      range: state.filters.timeFrameValue !== CONST.TIMEFRAME_INTERVALS.CUSTOM ? null : state.range
    });
  };

  const applySearchString = () => {
    applyFilters(state.filters);
    changeState({
      searchApplied: !state.searchInputEmpty,
      filterVisible: false,
      searchInputFocused: false,
    });
  };

  const clearSearchString = () => {
    const currentSearch = state.filters.search;
    changeState({
      searchApplied: false,
      searchInputEmpty: true,
      searchInputFocused: false,
      filters: {
        ...state.filters,
        search: '',
      },
    });
    if(currentSearch !== '') {
      props.applyFilters({
        ...state.filters,
        search: '',
      });
    }
  };

  const onSearchInputFocus = () => {
    changeState({ searchInputFocused: true });
  };

  const onPressEnter = (e) => {
    e.persist();
    if (e.keyCode === 13) {
      applySearchString();
      e.target.blur();
    }
  };

  const setSearchFilter = (value) => {
    changeState({
      searchInputEmpty: value.length === 0,
      filters: {
        ...state.filters,
        search: value,
      },
    });
  };

  const handleFilterTriggerClick = () => {
    changeState({
      filterVisible: !state.filterVisible,
      filters: props.filters,
    });
  };

  const openCalendar = () => {
    oldRange.current = state.range;

    changeState({
      calendarOpened: true,
      filterVisible: false,
      isFirstDate: true,
    });
  };

  const setFilterValue = (value, field) => {
    changeState({
      filters: {
        ...state.filters,
        [field]: contains(value, state.filters[field])
          ? reject(equals(value), state.filters[field])
          : append(value, state.filters[field]),
      },
    });
  };

  const setTimeFrameFilter = (timeFrame) => {
    changeState({
      filters: {
        ...state.filters,
        timeFrameValue: timeFrame,
        timeFrameDates: predefinedTimeFrame(timeFrame),
      },
    });
  };

  const handleDateRangeSelect = ({ selection }) => {
    if (state.isFirstDate) {
      let minDate = moment(selection.startDate).subtract(89, 'days').toDate();
      let maxDate = moment(selection.startDate).add(89, 'days').toDate();

      if (+minDate < +INIT_STATE.disabledDates.minDate) {
        minDate = INIT_STATE.disabledDates.minDate;
      }

      if (+maxDate > +INIT_STATE.disabledDates.maxDate) {
        maxDate = INIT_STATE.disabledDates.maxDate;
      }

      changeState({
        range: selection,
        isFirstDate: false,
        disabledDates: { minDate, maxDate },
      });
    } else {
      changeState({
        range: selection,
        isFirstDate: true,
        disabledDates: {
          minDate: INIT_STATE.disabledDates.minDate,
          maxDate: INIT_STATE.disabledDates.maxDate,
        }
      });
    }
  };

  const cancelCalendar = () => {
    changeState({
      calendarOpened: false,
      filterVisible: true,
      isFirstDate: true,
      range: oldRange.current,
      disabledDates: {
        minDate: INIT_STATE.disabledDates.minDate,
        maxDate: INIT_STATE.disabledDates.maxDate,
      },
    });
  };

  const applyDates = () => {
    const range = state.range || {
      endDate: moment().toDate(),
      startDate: moment().toDate(),
      key: 'selection',
    };

    if (range) {
      changeState({
        range: range,
        calendarOpened: false,
        filterVisible: true,
        isFirstDate: true,
        disabledDates: {
          minDate: INIT_STATE.disabledDates.minDate,
          maxDate: INIT_STATE.disabledDates.maxDate,
        },
        filters: {
          ...state.filters,
          timeFrameValue: CONST.TIMEFRAME_INTERVALS.CUSTOM,
          timeFrameDates: {
            from_date: moment(range.startDate).startOf('day').toISOString(),
            to_date: moment(range.endDate).endOf('day').toISOString(),
          },
        },
      });
    } else {
      cancelCalendar();
    }
  };

  const setModal = (isShown) => {
    setField('invoiceShown', isShown);
  };

  const setInvoice = (transaction) => {
    if (transaction) {
      let invoice = pendingInvoiceList.find(invoice => invoice.invoice_id === transaction.transaction_id);

      if(!invoice) {
        invoice = completedInvoiceList.find(invoice => invoice.invoice_id === transaction.transaction_id);
      }

      setField('invoiceToShow', invoice);
    } else {
      setField('invoiceToShow', null);
    }
  };

  return (
    <BasicLayout className="invoice-list" wrapperClassName="-x-lg -page-body-max -close-to-header">
      <div className="page_header invoice-list__header -relative">
        <h1 className="js-page-title page_title">Invoices</h1>
        <Button
          onClick={() => openInvoiceForm()}
          color="blue"
          xSize="xs"
          ySize="xs"
          className="-right-button"
        >
          Create Invoice
        </Button>
      </div>
      <div className="page_body">
        <div className="page_content">
          <div className="invoice-list__container">
            <div
              className={
                cn(
                  'transactions_search search-input',
                  {
                    '-is-focused': state.searchInputFocused,
                    '-is-not-empty': !state.searchInputEmpty,
                  },
                )
              }
              ref={searchInputRef}
            >
              <div className="search-input_input-wrapper">
                <input
                  type="text"
                  value={state.filters.search}
                  className="search-input_input"
                  placeholder="Invoice Search"
                  onFocus={onSearchInputFocus}
                  onKeyDown={onPressEnter}
                  onChange={e => setSearchFilter(e.target.value)}
                />
                <Svg name="magnifying-glass" className="search-input_icon" />
                {
                  (!state.searchInputEmpty && !state.searchInputFocused) &&
                <button className="search-input_filter-reset-button" onClick={clearSearchString}>
                  <Svg name="cross" className="search-input_filter-reset-button-icon" />
                </button>
                }

                {
                  !state.searchInputFocused &&
                <button
                  className="transactions_trigger -input-filter-trigger search-input_filter-button"
                  onClick={handleFilterTriggerClick}
                >
                  <Svg name="filter-bars" className="search-input_filter-button-icon" />
                </button>
                }

                {
                  state.searchInputFocused &&
              <Fragment>
                <button className="search-input_input-reset-button" onClick={clearSearchString}>
                  <Svg name="cross" className="search-input_input-reset-button-icon" />
                </button>
                <button
                  className="search-input_submit-button"
                  onClick={applySearchString}
                >
                  Search
                </button>
              </Fragment>
                }
              </div>

              <OnMobile>
                <InvoiceFilterMobile
                  filterVisible={state.filterVisible}
                  handleFilterTriggerClick={handleFilterTriggerClick}
                  clearFilters={resetFilters}
                  applyFilters={applyFilters}
                  filters={state.filters}
                  setFilterValue={setFilterValue}
                  setTimeFrameFilter={setTimeFrameFilter}
                  range={state.range}
                  openCalendar={openCalendar}
                />
              </OnMobile>

              <OnDesktop>
                <InvoiceFilterDesktop
                  filters={state.filters}
                  setFilterValue={setFilterValue}
                  setTimeFrameFilter={setTimeFrameFilter}
                  range={state.range}
                  openCalendar={openCalendar}
                  filterPlateRef={filterPlateRef}
                  filterVisible={state.filterVisible}
                  clearFilters={resetFilters}
                  applyFilters={applyFilters}
                />
              </OnDesktop>

              <OnDesktop>
                {state.calendarOpened && (
                  <InvoiceFilterDateRange
                    calendarOpened={state.calendarOpened}
                    rangeRef={rangeRef}
                    handleSelect={handleDateRangeSelect}
                    cancelCalendar={cancelCalendar}
                    applyDates={applyDates}
                    disabledDates={state.disabledDates}
                    initDisabledDates={INIT_STATE.disabledDates}
                    range={state.range}
                  />
                )}
              </OnDesktop>
            </div>
            {noInvoices && (
              <div className="transactions_body">
                {
                  (state.filterApplied || state.searchApplied)
                    ? (
                      <div className="splash color-light-gray">
                        <Svg name="search" className="splash_icon" />
                        <div className="splash_message font-size-secondary-responsive">
                      No invoices matching criteria found
                        </div>
                      </div>
                    )
                    : (
                      <div className="splash color-light-gray">
                        <Svg name="reload-usd" className="splash_icon" />
                        <div className="splash_message font-size-secondary-responsive">
                      No invoices to show<br/>
                      for last {state.daysCount} days <br/><br/>
                        </div>
                      </div>
                    )
                }
              </div>
            )}
            <TransactionsList
              isInvoiceList={true}
              isSeparate={true}
              pending={pendingInvoiceTransactions}
              completed={completedInvoiceTransactions}
              pendingIsLoading={isLoading}
              setModal={setModal}
              setTransaction={setInvoice}
              transactionShown={invoiceShown}
              transactionToShow={invoiceToShow}
              listType="invoice"
              modalComponent={InvoiceDetailsModal}
            />
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

InvoiceList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  user: userReducerTypes.user,
  setField: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filters: TransactionFiltersShape,
  completedInvoiceList: PropTypes.arrayOf(InvoiceShape).isRequired,
  pendingInvoiceList: PropTypes.arrayOf(InvoiceShape).isRequired,
  invoiceShown: PropTypes.bool.isRequired,
  invoiceToShow: InvoiceShape,
  getCompletedInvoices: PropTypes.func.isRequired,
  getPendingInvoices: PropTypes.func.isRequired,
  completedInvoiceTransactions: PropTypes.arrayOf(TransactionShape).isRequired,
  pendingInvoiceTransactions: PropTypes.arrayOf(TransactionShape).isRequired,
  openInvoiceForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.invoice.isLoading,
    user: state.user.user,
    filters: state.invoice.filters,
    invoiceShown: state.invoice.invoiceShown,
    invoiceToShow: state.invoice.invoiceToShow,
    completedInvoiceList: state.invoice.completedInvoiceList,
    pendingInvoiceList: state.invoice.pendingInvoiceList,
    completedInvoiceTransactions: state.invoice.completedInvoiceTransactions,
    pendingInvoiceTransactions: state.invoice.pendingInvoiceTransactions,
  };
};

export default connect(mapStateToProps, {
  setField,
  clearFilters,
  applyFilters,
  getCompletedInvoices,
  getPendingInvoices,
  openInvoiceForm,
})(InvoiceList);
