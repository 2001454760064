import React, { useEffect, useRef, Fragment } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userReducerTypes, walletReducerTypes } from './constants/prop-types';
import fastclick from 'fastclick';
import qs from 'qs';
import jwtDecode from 'jwt-decode';
import { __, any, startsWith } from 'ramda';
import GA from 'react-ga';
import storage from 'sessionstorage';

import 'react-date-range-creativeco/dist/styles.css';
import 'react-date-range-creativeco/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';

import { redirectToOAuthLogin, redirectToInvoiceComplete } from './utils/redirects';

import { history } from './init-store';
import Loader from './components/loader';
import RewardsModal from './components/rewards-modal';
import Toast from './components/toast';
import Version from './components/version';
import { OnMobile, OnDesktop } from './components/breakpoints';
import TransactionsInfoModal from './components/transactions-info-modal';

import SendMoney from './pages/send-money';
import BankAccounts from './pages/bank-accounts';
import AddBankAccount from './pages/add-bank-account';
import Transfer from './pages/transfer';
import Dashboard from './pages/dashboard/dashboard';
import SignUpPage from './pages/sign-up';
import WalletSettings from './pages/wallet-settings';
import LoginPage from './pages/login-page';
import Payment from './pages/payment';
import RecurrentPayment from './pages/agreements/recurrent-payment';
import Transactions from './pages/transactions';
import RecoverPasswordPage from './pages/recover-password';
import SetNewPasswordPage from './pages/set-new-password';
import PartnerSignup from './pages/partner-signup';
import LoanOffer from './pages/loan-offer';
import AddBankAccountInstant from './pages/add-bank-account-instant';
import InvoiceComplete from './pages/invoice-complete';
import AccountPage from './pages/account';
import WalletSelect from './pages/wallet-select/wallet-select';
// Error views
import Error404 from './pages/errors/error-404';
import ErrorOAuth from './pages/errors/error-oauth';

// OAuth views
import OAuthScopePage from './pages/oauth/scope';
import OAuthRedirectPage from './pages/oauth/redirect';
import OAuthConsentPage from './pages/oauth/consent';
import OAuthLoginPage from './pages/oauth/login';
import OAuthPendingPage from './pages/oauth/pending';
import OAuthSetupPage from './pages/oauth/setup';
import OAuthMobilePage from './pages/oauth/mobile';
import OAuthOTPPage from './pages/oauth/otp';
import OAuthCodePage from './pages/oauth/code';

import MaintenanceScreen from './pages/maintenance';
import CreditCardsPage from './pages/credit-cards';
import CreditCardsAddPage from './pages/credit-cards-add';
import InvoiceForm from './pages/invoice/invoice-form';
import InvoiceList from './pages/invoice/invoice-list';
import WalletPermissions from './pages/wallet-permissions';
import { getUser, setField } from './actions/user-actions';
import { getCurrentWallet } from './actions/wallet-actions';
import { clearAuthToken } from './utils/api';
import { setField as setDashboardField } from './actions/dashboard-actions';
import { getOptions } from './actions/app-actions';
import { 
  isSendMoneyDisabledSelector,
  isTransferDisabledSelector,
  isBankAccountsDisabledSelector,
  isOrderDisabledSelector,
  isCreditCardsDisabledSelector,
} from './reducers/app-reducer'; 
import { isInvoicingEnabledSelector } from './reducers/wallet-reducer';
import ProtectedRoute from './components/hoc/protected-route';


// fixes behaviour of iOS when :active state of buttons is not triggered by touch
document.addEventListener('touchstart', () => {}, true);

export const pathsWithoutUser = [
  '/signup',
  '/login',
  '/recover',
  '/set-new-password',
  '/markup',
  '/partner-signup',
  '/setup/streamlined',
  '/invoice-complete',
  '/auth',
  '/maintenance',
  '/authcode'
];

export const queryRedirects = [
  {
    path: '/order/form',
    params: {
      type: 'invoice',
      status: 'complete'
    },
    redirect: redirectToInvoiceComplete
  }
];

export const shouldGetUser = () => {
  const { pathname } = window.location;

  if (pathsWithoutUser.find(path => pathname.indexOf(path) !== -1)) {
    return false;
  }

  return true;
};

export const shouldBeRedirected = () => {
  const { search, pathname } = window.location;

  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
  const foundRedirect = queryRedirects.find((set) => {
    return pathname.indexOf(set.path) !== -1 &&
      Object.keys(set.params).every(key => queryParams[key] === set.params[key]);
  });

  if (foundRedirect) {
    return foundRedirect;
  }

  return false;
};

const App = (props) => {
  const unlistener = useRef(null);
  const gaListener = useRef(null);

  useEffect(() => {
    GA.initialize(process.env.REACT_APP_GA_KEY);
    GA.pageview(window.location.pathname + window.location.search);
    
    gaListener.current = history.listen(() => {
      GA.pageview(window.location.pathname + window.location.search);
    });

    const { clientui } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if(clientui) {
      localStorage.setItem('clientui', clientui);
    }
    props.getOptions();

    unlistener.current = history.listen(() => {
      if (shouldGetUser()) {
        getUserData();
      }
    });

    if (shouldGetUser()) {
      getUserData();
    }

    fastclick.attach(document.body);
  }, []);

  const getUserData = () => {
    let token = '';

    try {
      token = window.localStorage.getItem('token');
    } catch (e) {
      token = storage.getItem('token');
    }

    if (token) {
      let userId;

      try {
        userId = jwtDecode(token).sub;
      } catch (e) {
        console.warn(e);
      }
      
      if (!userId) {
        clearAuthToken();
        redirectToOAuthLogin();
        return;
      }

      props.setField(['user', 'user_id'], userId);

      props.getUser(userId);
      props.getCurrentWallet(userId);
      props.getOptions();
      unlistener.current();
    } else if (shouldGetUser()) {
      redirectToOAuthLogin();
    }
  };

  const { user, wallet, rewardsModalShown, isTransferDisabled, isSendMoneyDisabled, 
    isBankAccountsDisabled, isCreditCardsDisabled, isInvoicingEnabled } = props;
  const isLoading = user.isLoading || user.notAsked || wallet.isLoading || wallet.notAsked;
  const { pathname } = window.location;
  const foundRedirect = shouldBeRedirected();
  const loadingRequired = !any(startsWith(__, pathname), pathsWithoutUser) && !foundRedirect;

  foundRedirect && foundRedirect.redirect();

  if (isLoading && loadingRequired) {
    return (
      <div className="page -basic">
        <div className="page_wrapper -y-full -content-x-centered -content-y-centered">
          <div className="page_wrapper-inner">
            <div className="page_body">
              <div className="page_content">
                <Loader color="blue" size="sm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if(wallet.userMustChooseWallet) {
    return <WalletSelect />;
  }

  return (
    <Fragment>
      <Toast />
      <OnDesktop>
        <Version />
      </OnDesktop>
      {
        !shouldGetUser() &&
          <div className="login-version-wrapper">
            <OnMobile>
              <Version />
            </OnMobile>
          </div>
      }
      {
        shouldGetUser() &&
          <Fragment>
            <TransactionsInfoModal />
            <RewardsModal shown={rewardsModalShown} onClose={()=>{
              props.setDashboardField('rewardsModalShown', false);
            }}/>
          </Fragment>
      }
      <Router history={history}>
        {!window._isIFrameCall
          ? (
            <Switch>
              <Redirect from="/" to="/dashboard" exact />
              {!isSendMoneyDisabled && (
                <ProtectedRoute path="/send-money" exact component={SendMoney} requiredAccess="transact" />
              )}
              {!isBankAccountsDisabled && (
                <Route path="/bank-accounts">
                  <Switch>
                    <Route path="/bank-accounts" exact component={BankAccounts} />
                    <ProtectedRoute path="/bank-accounts/new" exact component={AddBankAccount} requiredAccess="transact" />
                    <ProtectedRoute path="/bank-accounts/instant" exact component={AddBankAccountInstant} requiredAccess="transact" />
                  </Switch>
                </Route>
              )}
              {!isTransferDisabled && (
                <ProtectedRoute path="/transfer" exact component={Transfer} requiredAccess="transact" />
              )}
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/signup" exact component={SignUpPage} />
              <Route path="/wallet-settings" exact component={WalletSettings} />
              <Route path="/login" exact component={LoginPage}>
                <Redirect to="/auth/login" />
              </Route>
              <Route path="/transactions" exact component={Transactions} />
              <Route path="/recover-password" exact component={RecoverPasswordPage} />
              <Route path="/set-new-password/:token" exact component={SetNewPasswordPage} />
              <Route path="/partner-signup" exact component={PartnerSignup} />
              <Route path="/loan-offer" exact component={LoanOffer} />
              <Route path="/invoice-complete" component={InvoiceComplete} />
              <Route path="/account/:currency" component={AccountPage} />
              {!isCreditCardsDisabled && (
                <Route path="/credit-cards">
                  <Switch>
                    <Route path="/credit-cards" exact component={CreditCardsPage} />
                    <ProtectedRoute path="/credit-cards/add" component={CreditCardsAddPage} requiredAccess="transact" />
                  </Switch>
                </Route>
              )}
              <ProtectedRoute path="/wallet-permissions" exact component={WalletPermissions} requiredAccess="owner" />
              <Route path="/authcode" component={OAuthCodePage} />
              <Route path="/auth/login" component={OAuthLoginPage} />
              <Route path="/auth/mobile" component={OAuthMobilePage} />
              <Route path="/auth/redir" component={OAuthRedirectPage} />
              <Route path="/auth/pending" component={OAuthPendingPage} />
              <Route path="/auth/consent" component={OAuthConsentPage} />
              <Route path="/auth/scope" component={OAuthScopePage} />
              <Route path="/auth/setup" component={OAuthSetupPage} />
              <Route path="/auth/otp" component={OAuthOTPPage} />
              <Route path="/auth/error" component={ErrorOAuth} />
              <Route path="/maintenance" component={MaintenanceScreen} />
              <Route path="/404" exact component={Error404} />
              <Route path="/wallet-select" exact component={WalletSelect} />
              {isInvoicingEnabled && (
                <Route path="/invoices">
                  <Switch>
                    <Route path="/invoices" exact component={InvoiceList} />
                    <Route path="/invoices/create" exact component={InvoiceForm} />
                  </Switch>
                </Route>
              )}
              <Route component={Error404} />
            </Switch>
          )
          : (
            <Switch>
              <Route path="/login" exact component={LoginPage} />
              <Route path="/authcode" component={OAuthCodePage} />
              <Route path="/auth/login" component={OAuthLoginPage} />
              <Route path="/auth/mobile" component={LoginPage} />
              <Route path="/auth/redir" component={OAuthRedirectPage} />
              <Route path="/auth/pending" component={OAuthPendingPage} />
              <Route path="/auth/consent" component={OAuthConsentPage} />
              <Route path="/auth/scope" component={OAuthScopePage} />
              <Route path="/auth/otp" component={OAuthOTPPage} />
              <Route path="/auth/setup" component={LoginPage} />
              <Route path="/wallet-select" exact component={WalletSelect} />
              <Route path="/order/form" component={Payment} />
              <Route path="/agreements/form" exact component={RecurrentPayment} />
              <Route path="/invoice-complete" component={InvoiceComplete} />
              <Route path="/auth/error" component={ErrorOAuth} />
              <Route path="/maintenance" component={MaintenanceScreen} />
              <Route component={Error404} />
            </Switch>
          )
        }
      </Router>
    </Fragment>
  );
};

App.propTypes = {
  getUser: PropTypes.func,
  setField: PropTypes.func,
  getCurrentWallet: PropTypes.func,
  user: PropTypes.shape(userReducerTypes),
  wallet: PropTypes.shape(walletReducerTypes),
  rewardsModalShown: PropTypes.bool,
  setDashboardField: PropTypes.func,
  getOptions: PropTypes.func,
  isSendMoneyDisabled: PropTypes.bool, 
  isTransferDisabled: PropTypes.bool,
  isBankAccountsDisabled: PropTypes.bool,
  isCreditCardsDisabled: PropTypes.bool,
  isInvoicingEnabled: PropTypes.bool,
  setWalletField: PropTypes.func,
};

App.defaultProps = {
  getUser: () => {},
  getCurrentWallet: () => {},
  getOptions: () => {},
  getLogo: () => {},
  user: {},
  wallet: {},
  isSendMoneyDisabled: false, 
  isTransferDisabled: false,
  isBankAccountsDisabled: false,
  isOrderDisabled: false
};

const mapStateToProps = state => ({
  user: state.user,
  wallet: state.wallet,
  rewardsModalShown: state.dashboard.rewardsModalShown,
  isSendMoneyDisabled: isSendMoneyDisabledSelector(state),
  isTransferDisabled: isTransferDisabledSelector(state),
  isBankAccountsDisabled: isBankAccountsDisabledSelector(state),
  isOrderDisabled: isOrderDisabledSelector(state),
  isCreditCardsDisabled: isCreditCardsDisabledSelector(state),
  isInvoicingEnabled: isInvoicingEnabledSelector(state)
});

export default connect(mapStateToProps, { getUser, setField, getCurrentWallet, setDashboardField, getOptions })(App);
