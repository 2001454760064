export const CONSTANTS = {
  // ACTION CONSTANTS
  SET_FIELD: '@sign-up/SET_FIELD',
  END_STEP: '@sign-up/END_STEP',
  SET_VALIDATION_ERROR: '@sign-up/SET_VALIDATION_ERROR',
  SET_POPUP: '@sign-up/SET_POPUP',
  UPDATE_ADDRESS: '@sign-up/UPDATE_ADDRESS',
  UPDATE_NAME: '@sign-up/UPDATE_NAME',
  CLEAR_STATE: '@sign-up/CLEAR_STATE',
  OPEN_POPUP: '@sign-up/OPEN_POPUP',
  ADD_BANK_ACCOUNTS_MULTIPLE: '@sign-up/ADD_BANK_ACCOUNTS_MULTIPLE',
  CONSENT_AGREEMENT: '@sign-up/CONSENT_AGREEMENT',
  AGREEMENT_CONSENTED: '@sign-up/AGREEMENT_CONSENTED',
  SUBMIT_DOCUMENTATION: '@sign-up/SUBMIT_DOCUMENTATION',
  GET_SINGULAR_KEY: '@sign-up/GET_SINGULAR_KEY',
  START_VERIFICATION: '@sign-up/START_VERIFICATION',

  AUTH_LINK_FLOW: '@sign-up/AUTH_LINK_FLOW',
  // STEPS CONSTANTS
  STEP_EMAIL_PASSWORD: '@sign-up/STEP_EMAIL_PASSWORD',
  STEP_PAYPHONE_AUTODETECT: '@sign-up/STEP_PAYPHONE_AUTODETECT',
  STEP_LINK_SENT: '@sign-up/STEP_LINK_SENT',
  STEP_PHONE_NUMBER: '@sign-up/STEP_PHONE_NUMBER',
  STEP_PHONE_NUMBER_SKIP: '@sign-up/STEP_PHONE_NUMBER_SKIP',
  STEP_SUPPORT_VERIFICATION: '@sign-up/STEP_SUPPORT_VERIFICATION',
  STEP_SSN: '@sign-up/STEP_SSN',
  STEP_SINGULAR_KEY: '@sign-up/STEP_SINGULAR_KEY',
  STEP_WALLET_SUCCESS: '@sign-up/STEP_WALLET_SUCCESS',
  STEP_WALLET_TYPE: '@sign-up/STEP_WALLET_TYPE',
  STEP_BANK_ACCOUNT: '@sign-up/STEP_BANK_ACCOUNT',
  STEP_COMPLETE: '@sign-up/STEP_COMPLETE',
  STEP_ERROR_TRY_AGAIN: '@sign-up/STEP_ERROR_TRY_AGAIN',
  STEP_ERROR_HELPDESK: '@sign-up/STEP_ERROR_HELPDESK',
  STEP_ERROR_WENT_WRONG: '@sign-up/STEP_ERROR_WENT_WRONG',
  STEP_ADDITIONAL_VERIFICATION_HELPDESK: '@sign-up/STEP_ADDITIONAL_VERIFICATION_HELPDESK',
  STEP_ERROR_UNABLE: '@sign-up/STEP_ERROR_UNABLE',
  STEP_VERIFY_EMAIL: '@sign-up/STEP_VERIFY_EMAIL',
  STEP_PHONE_NUMBER_VERIFIED: '@sign-up/STEP_PHONE_NUMBER_VERIFIED',
  STEP_YODLEE_SUCCESS: '@sign-up/STEP_YODLEE_SUCCESS',
  STEP_SIGNUP_SUCCESS: '@sign-up/STEP_SIGNUP_SUCCESS',
  STEP_YODLEE_FLOW: '@sign-up/STEP_YODLEE_FLOW',
  STEP_VERIFICATION_SELECT: '@sign-up/STEP_VERIFICATION_SELECT',
  STEP_YODLEE_MOBILE: '@gb-signup/STEP_YODLEE_MOBILE',
  STEP_YODLEE_DESKTOP: '@gb-signup/STEP_YODLEE_DESKTOP',
  STEP_OAUTH_PHONE_NUMBER_SKIP: '@sign-up/STEP_OAUTH_PHONE_NUMBER_SKIP',
  STEP_OAUTH_PHONE_NUMBER: '@sign-up/STEP_OAUTH_PHONE_NUMBER',
  STEP_OAUTH_SCOPES: '@sign-up/STEP_OAUTH_SCOPES',
  STEP_OAUTH_EMAIL_PASSWORD: '@sign-up/STEP_OAUTH_EMAIL_PASSWORD',
  RETURN_TO_PHONE_STEP: '@sign-up/RETURN_TO_PHONE_STEP',
  // VERIFICATION SERVICES CONSTANTS
  SERVICE_SUPPORT: 'support',
  SERVICE_LAST4: 'last4',
  SERVICE_SINGULAR_KEY: 'html'
};

export default CONSTANTS;
