import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../button';
import Modal from '../modal';
import { BankAccountShape } from '../../constants/prop-types';
import Access from '../hoc/access';
import { ACCESS_NAMES } from '../../constants/wallet-constants';

const ModalAccountDetails = ({
  isLoading,
  bankDetails,
  containerClassName,
  toggleEditAccountLabelModal,
  toggleVerifyBankAccount,
  removeBankAccount,
  ...props
}) => (
  <Modal {...props} containerClassName={cn('-white', containerClassName)}>
    <div className="modal_header">
      <h1 className="js-modal-title">Bank Account Details</h1>
    </div>
    <div className="modal_body">
      <div className="description-list -fixed-title -title-width-xl -condensed full-width">
        <div className="description-list_body">
          <div className="description-list_item">
            <div className="description-list_title">Routing Number</div>
            <div className="description-list_value">
              <div className="js-routing-number description-list_item-primary">{bankDetails.routing_number}</div>
              <div className="js-bank-name description-list_item-secondary">{bankDetails.bank_name || 'Bank Account'}</div>
            </div>
          </div>
          <div className="description-list_item">
            <div className="description-list_title">Account Number</div>
            <div className="description-list_value">
              <div className="description-list_item-primary">
                <span className="js-account-number account-number-bullets">&bull;&bull;</span>{bankDetails.account_number}
              </div>
            </div>
          </div>
          <div className="description-list_item">
            <div className="description-list_title">Nickname</div>
            <div className="description-list_value">
              <Button
                className="js-edit-account-button -has-arrow"
                color="blue"
                transparency="full"
                xSize="condensed-full"
                ySize="condensed"
                onClick={toggleEditAccountLabelModal}
                disabled={isLoading}
              >
                <div className="button_head">
                  {bankDetails.user_description}
                </div>
              </Button>
            </div>
          </div>
          <div className="description-list_item">
            <div className="description-list_title">Account Type</div>
            <div className="description-list_value">
              <div className="js-account-type description-list_item-primary">
                {bankDetails.account_type}
              </div>
            </div>
          </div>
          <div className="description-list_item">
            <div className="description-list_title">Account Class</div>
            <div className="description-list_value">
              <div className="js-account-type description-list_item-primary">
                {bankDetails.account_class}
              </div>
            </div>
          </div>
          <div className="description-list_item">
            <div className="description-list_title">Ownership</div>
            {bankDetails.amounts_verified ? (
              <div className="description-list_value color-green font-weight-bold">
                <div className="js-ownership-verification description-list_item-primary">Verified</div>
              </div>
            ) : (
              <div className="description-list_value color-orange font-weight-bold">
                <div className="js-ownership-verification description-list_item-primary">Verification pending</div>
              </div>
            )}
          </div>
          <div className="description-list_item">
            <div className="description-list_title">Withdrawal</div>
            {!bankDetails.amounts_verified && (
              <div className="description-list_value color-orange font-weight-bold">
                <div className="js-withdrawal-verification description-list_item-primary">Waiting for ownership verification</div>
              </div>
            )}
            {bankDetails.amounts_verified && bankDetails.withdraw_status === 'pending' && (
              <div className="description-list_value color-orange font-weight-bold">
                <div className="js-withdrawal-verification description-list_item-primary">
                  Verification pending
                  <br />
                  <div className="label">No Withdrawal Allowed</div>
                </div>
              </div>
            )}
            {bankDetails.amounts_verified && bankDetails.withdraw_status === 'declined' && (
              <div className="description-list_value color-orange font-weight-bold">
                <div className="description-list_item-primary">
                  <div className="js-withdrawal-verification label">No Withdrawal Allowed</div>
                </div>
              </div>
            )}
            {bankDetails.amounts_verified && bankDetails.withdraw_status === 'allowed' && (
              <div className="description-list_value color-green font-weight-bold">
                <div className="js-withdrawal-verification description-list_item-primary">Allowed</div>
              </div>
            )}
          </div>
          <div className="description-list_item">
            <div className="description-list_value">
              <div className="js-description-list description-list_item-primary">
                {bankDetails.amounts_verified && bankDetails.withdraw_status === 'pending' && (
                  <p className="font-size-secondary-responsive text-align-center color-secondary">
                    We will try to&nbsp;withdraw funds that were sent to&nbsp;you on previous step
                    to&nbsp;make&nbsp;sure withdrawal&nbsp;is possible.
                  </p>
                )}
                {bankDetails.amounts_verified && bankDetails.withdraw_status === 'declined' && (
                  <p className="font-size-secondary-responsive text-align-center color-secondary">
                    Attempt to&nbsp;withdraw funds from this account failed.
                    <br />
                    To&nbsp;enable withdrawal please contact&nbsp;
                    <a
                      target="_blank"
                      href={process.env.REACT_APP_HELP_DESK_URL}
                      rel="noopener noreferrer"
                    >
                      Customer Support
                    </a>.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal_footer">
      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <div className="modal_footer-controls">
          <Button
            color="red"
            transparency="full"
            xSize="full"
            className="js-remove-button modal_footer-control -cancel"
            onClick={() => removeBankAccount(bankDetails.bank_account_id)}
            disabled={isLoading}
          >
            Remove this account
          </Button>
          {!bankDetails.amounts_verified && (
            <Button
              color="blue"
              xSize="full"
              className="js-verify-button modal_footer-control -submit"
              onClick={toggleVerifyBankAccount}
              disabled={isLoading}
              loading={isLoading}
            >
            Verify Account Ownership
            </Button>
          )}
        </div>
      </Access>
    </div>
  </Modal>
);

ModalAccountDetails.propTypes = {
  bankDetails: BankAccountShape,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  containerClassName: PropTypes.string,
  toggleEditAccountLabelModal: PropTypes.func,
  toggleVerifyBankAccount: PropTypes.func,
  removeBankAccount: PropTypes.func,
};

ModalAccountDetails.defaultProps = {
  bankDetails: {
    account_number: null,
    bank_name: null,
    routing_number: null,
    amounts_verified: false,
    withdraw_status: 'pending',
    user_description: null,
  },
  containerClassName: null,
  isLoading: false,
  onClose: () => {},
  toggleEditAccountLabelModal: () => {},
  toggleVerifyBankAccount: () => {},
  removeBankAccount: () => {},
};

export default ModalAccountDetails;
