import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Avatar from '../../components/avatar';

const WalletIcon = ({ type, initials, className, onClick }) => {
  return type === 'individual' ? (
    <Avatar className={cn('wallet-select__icon', 'wallet-select__icon--individual', className)} onClick={onClick}>
      { initials && initials.toUpperCase ? initials.toUpperCase() : initials }
    </Avatar>
  ) : (
    <Avatar className={cn('wallet-select__icon', 'wallet-select__icon--business', className)} type="wallet" onClick={onClick} />
  );
};

WalletIcon.propTypes = {
  type: PropTypes.oneOf(['individual', 'business']).isRequired,
  initials: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default WalletIcon;
