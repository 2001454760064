import { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ACCESS_TYPES } from '../../constants/wallet-constants';

const logAccess = (componentName, children, requiredAccess, userAccess, isGranted) => {
  const isLoggingTurnOn = localStorage.getItem('logAccess') === 'true';
  if (!isLoggingTurnOn) return;
  
  console.log('============================================');
  console.log(`${componentName} - ${children}`);
  console.log(`Access ${isGranted ? 'granted' : 'denied'} because access needed is ${requiredAccess} and user wallet access is ${userAccess}`);
  console.log('============================================');
};

const Access = memo(({ children, access, requiredAccess, fallback = null }) => {
  const hasAccess = ACCESS_TYPES[access] >= ACCESS_TYPES[requiredAccess];
  const componentName = children?.type?.name || 'Unknown Component';
  const childrenText = children?.props?.children || '';

  logAccess(componentName, childrenText, requiredAccess, access, hasAccess);

  if (hasAccess) {
    return children;
  }

  return fallback;
});

Access.propTypes = {
  requiredAccess: PropTypes.oneOf(Object.keys(ACCESS_TYPES)).isRequired,
  children: PropTypes.node.isRequired,
  access: PropTypes.oneOf(Object.keys(ACCESS_TYPES)).isRequired,
  fallback: PropTypes.node,
};

const mapStateToProps = (state) => ({
  access: state.wallet.walletRights,
});

export default connect(mapStateToProps)(Access);