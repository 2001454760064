import { find, filter, reduce, forEach, last, dropLast, path, isEmpty, propEq, prop, sortWith, descend, compose, groupBy, splitAt } from 'ramda';
import { Decimal } from 'decimal.js';
import { hasPermission, permissionAllowedLimit } from './permissions';
import { pluralize, formatFunc } from './string-utils';

import NumToWords from 'number-to-words';

export const currencyFormat = value =>
  parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 8,
  });

export const rewardsFormat = value =>
  parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const formatNumberText = source => {
  const dollars = source.split('.')[0];
  const cents = (Decimal(source).toFixed(2).split('.')[1] || '0').substring(0, 2);

  return `${num2words(dollars)} ${pluralize('dollar', parseInt(dollars, 10))} ${num2words(cents)} ${pluralize('cent', parseInt(cents, 10))}`;
};

export const truncatedValue = value => Decimal(value).toDecimalPlaces(8, 3).toFixed(2);
export const toOrdinalDecimal = value => {
  const ordinal = Decimal(value).truncated().toString();
  const decimal = Decimal(value).minus(ordinal).toString().replace('0.', '');

  return {
    ordinal,
    decimal: decimal === '0' ? '00' : decimal,
  };
};
export const convertCurrencyFormat = (str) => {
  const numExample = 1.1;
  const decimalSep = numExample.toLocaleString().substring(1, 2);
  const modifiedStr = str.replace(new RegExp(`[^0-9${decimalSep}]`, 'g'), '');
  return modifiedStr.replace(decimalSep, '.');
};

export const canAccountBeUsed = (account = { balance: { } }, amount, currency, transactionRate, selectedSource, rewardsUsed) => {
  const accBalance = balance(account.balance.ordinal, account.balance.decimal, account.balance.sign);
  if(accBalance < 0) {
    return false;
  }
  const totalAmount = rewardsUsed ? calcRewards(amount, currency, selectedSource, transactionRate) : amount; 

  if (currency !== account.balance.currency) {    
    const convertedValue = _convertCurrency(account.balance.currency, currency, accBalance, transactionRate);
    
    return Decimal(convertedValue).minus(totalAmount).toFixed(2, 3) >= 0;
  } else {
    return Decimal(accBalance).minus(totalAmount).toFixed(2, 3) >= 0;
  }
};

export const balance = (ordinal = '0', decimal = '00', sign = '+') => Decimal(sign + ordinal + '.' + decimal).toFixed(2, 3);

export const calcBalance = (transactionCurrency, currency, ordinal, decimal, transactionRate) => 
  !isEmpty(transactionRate) && transactionCurrency !== currency ?
    '\xA0' + 
    withCurrency(_convertCurrency(currency, transactionCurrency, balance(ordinal, decimal), transactionRate), transactionCurrency) :
    null;

export const convertBonusPoints = (bonusAmount, pointsValue) => Decimal(bonusAmount).div(pointsValue).toFixed(2, 3);

export const leftAmountMinusBonus = (amount, bonus) => Decimal(amount).plus(bonus).toFixed(2, 3);

const functionDefferedUSD = ({
  transactionAmount, 
  transactionCurrency, transactionRate, maxAchAllowed,
  isDefferedPaymentAllowed, accounts,
  banks, permissions, accountType
}) => {
  let source = [];
  const defaultAccount = find(propEq('isDefault', true))(accounts) || accounts[0];
  const defaultAccountBalance = defaultAccount ? balance(defaultAccount.balance.ordinal, defaultAccount.balance.decimal) : 0;
  const defaultAccountCurrency = defaultAccount ? defaultAccount.balance.currency : 'USD';
  const convertedDefAccBalance = defaultAccount ? _convertCurrency(defaultAccountCurrency, 'USD', defaultAccountBalance, transactionRate) : 0;
  const convertedUserBalance = Decimal(
    accounts.reduce(
      (result, account) => Decimal(result).plus(
        !!path([account.balance.currency, 'USD'], transactionRate) 
          ? getConvertibleTotal(account, transactionRate) 
          : balance(account.balance.ordinal, account.balance.decimal)
      ), 0)
  ).toFixed(2);

  if (isDefferedPaymentAllowed || (!isDefferedPaymentAllowed && accountType === 'INSTANT')) {
    if (defaultAccount && Decimal(convertedDefAccBalance).minus(transactionAmount) >= 0) {
      source.push({
        id: defaultAccount.account_id,
        type: 'account',
        amount: transactionAmount,
        currency: transactionCurrency,
        accountName: defaultAccount.name,
        accountCurrency: defaultAccount.balance.currency
      });
    } else if(Decimal(convertedUserBalance).minus(transactionAmount) >= 0 ) {
      sortWith([
        descend(prop('isDefault'))
      ], accounts).forEach((account) => {
        if(transactionAmount > 0 && !isEmpty(account)) {
          const accountBalance = balance(account.balance.ordinal, account.balance.decimal);
          const convertedBalance = _convertCurrency(account.balance.currency, 'USD', accountBalance, transactionRate);
          const isEnough = Decimal(convertedBalance).minus(transactionAmount) >= 0;
          const amount = isEnough ? transactionAmount : convertedBalance;
          source.push({
            id: account.account_id,
            type: 'account',
            amount,
            currency: transactionCurrency
          });
          transactionAmount -= amount;
        }
      });
    } else if (isDefferedPaymentAllowed) {
      source = sourceBankAccountsUSD(transactionAmount, banks, permissions, source, transactionCurrency, maxAchAllowed);
    }
  } else if (!isDefferedPaymentAllowed && accountType === 'BANK') {
    if (banks && banks.length > 1) {
      source = [];
    } else {
      source = sourceBankAccountsUSD(transactionAmount, banks, permissions, source, transactionCurrency, maxAchAllowed);
    }
  }

  return source;
};

const sourceBankAccountsUSD = (transactionAmount, banks, permissions, source, transactionCurrency, maxAchAllowed) => {
  if (banks && banks.length && hasPermission(permissions, 'can_transfer') && maxAchAllowed > 0) {
    forEach(b => {
      if (Decimal(maxAchAllowed).gte(transactionAmount)) {
        source.push({
          id: b.bank_account_id,
          type: 'bank',
          amount: Decimal(transactionAmount).valueOf(),
          currency: transactionCurrency,
        });
        transactionAmount = 0;
      } else {
        transactionAmount = Decimal(transactionAmount).minus(maxAchAllowed);
        source.push({
          id: b.bank_account_id,
          type: 'bank',
          amount: maxAchAllowed,
          currency: transactionCurrency,
        });
      }
    }, banks);
  }

  return source;
};

// TODO: Double check deffered payments
export function calcPaymentSource({
  amount, transactionCurrency, accounts, banks,
  transactionRate, permissions, bonusAmount, accountType
}) {
  let source = [];

  const defferedPaymentLimit = path(['deferred_payment_limit', 'value'], permissions) / 100;

  let transactionAmount = bonusAmount ? Decimal(amount).minus(bonusAmount).toFixed(2, 3) : amount;

  const isDeffered = transactionCurrency !== 'USD' 
    ? defferedPaymentLimit >= parseFloat(_convertCurrency(transactionCurrency, 'USD', transactionAmount, transactionRate))
    : defferedPaymentLimit >= parseFloat(transactionAmount);

  const isDefferedPaymentAllowed = isDeffered || (banks && banks.length === 0);

  if (!isDefferedPaymentAllowed && accountType === 'BANK') {
    transactionAmount = amount;
  }

  const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');
  if (transactionCurrency === 'USD') {
    source = functionDefferedUSD({
      transactionAmount,
      transactionCurrency,
      transactionRate,
      maxAchAllowed,
      isDefferedPaymentAllowed, 
      accounts,
      banks,
      permissions,
      accountType
    });
  }

  return filter(s => !Decimal(s.amount).eq(0), source);
}

export function num2words(num) {
  return NumToWords.toWords(num);
}

export function calcLeftToSource(sendMoney, accountId, dontUseBonus) {
  const {
    paymentAmount,
    paymentCurrency,
    paymentSource,
    paymentBonusSource: { selectedSource },
    transactionRate,
  } = sendMoney;

  const arr = [];
  const sources = accountId ? filter(p => p.id !== accountId, paymentSource) : paymentSource;
  const correctCurrency = filter(p => p.currency === paymentCurrency, sources);
  const incorrectCurrency = filter(p => p.currency !== paymentCurrency, sources);
  forEach(o => arr.push(o.amount), correctCurrency);
  forEach((o) => {
    arr.push(_convertCurrency(o.currency, paymentCurrency, o.amount, transactionRate));
  }, incorrectCurrency);
  const sourceAmount = reduce((zero, val) => Decimal(zero).plus(val).valueOf(), '0', arr);

  if (selectedSource && !dontUseBonus) {
    return Decimal(paymentAmount).minus(sourceAmount).minus(selectedSource.rewardAmountTransactionCurrency).toFixed(2, 3);
  }
  
  return Decimal(paymentAmount).minus(sourceAmount).toFixed(2, 3);
}

// It's for future, because text for applying rewards may change (Roman should talk about it with Chris) but we decide don't change it for now
// Ok, I'm in future and there is still no changes
export function calcRewardsDiscount(paymentCurrency, selectedSource, rate) {
  if(selectedSource && selectedSource.reward_id) {
    const selectedSourceAmount = balance(selectedSource.amount.ordinal, selectedSource.amount.decimal);

    if(paymentCurrency !== selectedSource.amount.currency) {
      return _convertCurrency(selectedSource.amount.currency, paymentCurrency, selectedSourceAmount, rate);
    }

    return selectedSourceAmount;
  }
}

export function calcRewards(paymentAmount, paymentCurrency, selectedSource, rate) {
  if (selectedSource && selectedSource.reward_id) {
    const selectedSourceAmount = balance(selectedSource.amount.ordinal, selectedSource.amount.decimal);

    return Decimal(paymentAmount).minus(selectedSourceAmount).toFixed(2, 3);
  }

  return Decimal(paymentAmount).toFixed(2, 3);
}

export function calcSourceBalance(account, sourceCurrency, rate) {
  const accountBalance = balance(account.balance.ordinal, account.balance.decimal);
  if (account.balance.currency !== sourceCurrency) {
    return _convertCurrency(account.balance.currency, sourceCurrency, accountBalance, rate);
  }
  return accountBalance;
}

export function replaceDotInTail(value) {
  const dotInTail = last(value) === '.';
  return dropLast(dotInTail ? 1 : 0, value);
}

export function _convertCurrency(from, to, amount = 0, rates, round = 3) {
  const rate = path([from, to], rates) || 1;

  return from !== to ? Decimal(parseAmount(amount)).times(rate).toFixed(2, round) : amount;
}

export function getConvertibleTotal(account, rates) {
  const { currency, decimal, ordinal, sign } = account.balance;
  const amount = balance(ordinal || '0', decimal || '00', sign);
  
  return _convertCurrency(currency, 'USD', amount, rates);
};

export function setNamesForAccounts(accounts, currencies) {
  try {
    return Array.isArray(currencies) && currencies.length > 0 ? accounts.filter((account) => {
      const accountOptions = find(propEq('code', account.balance.currency))(currencies);
  
      return accountOptions && accountOptions.options && accountOptions.options.active !== false;
    }).map((account) => {
      const accountOptions = find(propEq('code', account.balance.currency))(currencies);
      account.name = accountOptions.name;
      account.isDefault = accountOptions && accountOptions.code === 'FC';
      account.can_sell = accountOptions && accountOptions.options && accountOptions.options.can_sell;
      account.can_buy = accountOptions && accountOptions.options && accountOptions.options.can_buy;
      account.cannot_transfer_from = accountOptions && accountOptions.options && accountOptions.options.cannot_transfer_from;
      account.cannot_transfer_to = accountOptions && accountOptions.options && accountOptions.options.cannot_transfer_to;
      account.code = accountOptions && accountOptions.code;
  
      return account;
    }) : accounts;
  } catch(e) {
    console.warn(e);
  }
  
}

export const addCurrencySign = (currency) => (amount) => currency === 'USD' ?
  `${amount < 0 ? `-$${amount.slice(1)}` :
    `$${amount}`}` : `${amount} ${currency}`;

export function withCurrency(amount = 0) {
  // Now we always use only USD (but what if it'll change?)
  const currency = 'USD';

  return compose(formatFunc, addCurrencySign(currency), currencyFormat, truncatedValue, parseAmount)(amount, currency);
} 

export function parseAmount(amount = 0) {
  return typeof amount === 'number' ? amount : parseFloat(amount.replace(',', ''));
}

export function filterAccountsByOptions(accounts, banks, sourceAccount = {}, configuration = {}) {
  const { tenant_can_transfer, tenant_can_withdraw_from_br } = configuration;
  let filteredAccounts = [];

  if(tenant_can_transfer !== false || (tenant_can_transfer === false && !sourceAccount.account_id)) {
    filteredAccounts = !sourceAccount.bank_account_id 
      ? compose(
        filter(a => !(Array.isArray(a.cannot_transfer_from) && a.cannot_transfer_from.includes(sourceAccount.code))),
        filter(a => !(Array.isArray(sourceAccount.cannot_transfer_to) && sourceAccount.cannot_transfer_to.includes(a.code))),
        filter(a => a.account_id !== sourceAccount.account_id)
      )(accounts) 
      : compose(
        filter(a => a.can_buy !== false),
        filter(a => a.account_id !== sourceAccount.account_id)
      )(accounts);
  }

  const filteredBanks = !sourceAccount.bank_account_id 
    && sourceAccount.can_sell !== false && tenant_can_withdraw_from_br !== false ? banks : [];

  return {
    accounts: filteredAccounts,
    bankAccounts: filteredBanks
  };
}

export function filterSourceAccountsByOptions(accounts, banks, destinationAccount = {}, configuration = {}) {
  const { tenant_can_transfer, tenant_can_deposit_to_br } = configuration;
  let filteredAccounts = [];

  if(tenant_can_transfer !== false || (tenant_can_transfer === false && !destinationAccount.account_id)) {
    filteredAccounts = !destinationAccount.bank_account_id 
      ? compose(
        filter(a => !(Array.isArray(a.cannot_transfer_to) && a.cannot_transfer_to.includes(destinationAccount.code))),
        filter(a => !(Array.isArray(destinationAccount.cannot_transfer_from) && destinationAccount.cannot_transfer_from.includes(a.code))),
        filter(a => a.account_id !== destinationAccount.account_id)
      )(accounts) 
      : compose(
        filter(a => a.can_sell !== false),
        filter(a => a.account_id !== destinationAccount.account_id)
      )(accounts);
  } 

  const filteredBanks = !destinationAccount.bank_account_id 
    && destinationAccount.can_buy !== false && tenant_can_deposit_to_br !== false ? banks : [];

  return {
    accounts: filteredAccounts,
    bankAccounts: filteredBanks
  };
}

export function isBackupCardError(errors = []) {
  const BACKUP_ERROR_TEXT = 'backup card pre-authorization failed';
  
  return !!find((error) => error.detail === BACKUP_ERROR_TEXT)(errors);
}

export function getAccountFromCurrency(accounts, currencies) {
  const groupedAccounts = groupBy((account) => account.code)(accounts);
  const groupedCurrencies = groupBy((currency) => currency.code)(currencies);
  const accountCodes = Object.keys(groupedAccounts).sort();
  const currenciesCodes = Object.keys(groupedCurrencies).sort();
  
  if(accountCodes.length < currenciesCodes.length) {
    return currenciesCodes.filter((code) => !accountCodes.includes(code)).map((code) => groupedCurrencies[code][0]).map((currency) => ({
      account_id: currency.currency_id,
      wallet_id: accounts[0].wallet_id,
      balance: {
        currency: 'USD',
        ordinal: '0',
        decimal: '00',
        sign: '+',
        usd_equivalent: '0'
      },
      type: currency.name.toLowerCase(),
      name: currency.name,
      isDefault: false,
      can_sell: true,
      can_buy: true,
      code: currency.code
    }));
  }

  return [];
}

export function getAccountBalance(account) {
  return withCurrency(Math.abs(path(['balance', 'usd_equivalent'], account)) / 100, 'USD');
}

export function mapPreflightData(preflightData, currentWallet) {
  let preflightAccounts = (preflightData[0].accounts || []);
  const paymentSourceData = {
    'account': currentWallet.accounts || [],
    'bank account': preflightData[0].bank_availability || [],
    'card': preflightData[0].card_availability || [],
  };

  const recentTransfers = (preflightData[0].recent_transfers || []).map((transfer) => {
    transfer.id = transfer.transaction_id;
    transfer.sourceType = 'recentTransfer';
    Object.assign(transfer, transfer.bank_account);
    delete transfer.bank_account;

    return transfer;
  });

  const ID_FIELDS = {
    'account': 'account_id',
    'bank account': 'bank_account_id',
    'card': 'card_account_id',
  };

  const SOURCE_TYPES = {
    'account': 'account',
    'recentTransfer': 'recentTransfer',
    'bank account': 'bank',
    'card': 'card',
  };

  if(preflightAccounts.length > 0) {
    preflightAccounts.forEach(account => {
      const idField = ID_FIELDS[account.type];
      const sourceType = SOURCE_TYPES[account.type];

      const accountData = paymentSourceData[account.type].find(item => item[idField] === account.id);


      if(!accountData) {
        account.forDeletion = true;
      }

      Object.assign(account, accountData, { sourceType });
    });

    preflightAccounts = splitAt(5, recentTransfers.concat(preflightAccounts.filter(account => !account.forDeletion)));
  }

  return {
    banksAvailabilty: preflightData[0].bank_availability || [],
    cardAvailability: preflightData[0].card_availability || [],
    recentTransfers,
    preflightAccounts,
    preflightIsLoading: false,
  };
}
