import { assocPath, compose, assoc, isEmpty } from 'ramda';
import CONST from '../constants/app-constants';

const INIT_STATE = {
  isPopup: false,
  paymentPopup: {
    isOpened: false,
    isPaymentSource: false,
  },
  configuration: {},
  options: [{
    currencies: [],
    maintenances: [],
    transactionOptions: {},
    ui: {},
  }]
};

export const currencyOptionsSelector = (state) => {
  const { application: { options } } = state;

  return Array.isArray(options) && options.length > 0 && Array.isArray(options[0].currencies) ?
    options[0].currencies : [];
};

export const isSendMoneyDisabledSelector = (state) => {
  const { application: { configuration } } = state;

  return !!configuration && !isEmpty(configuration) ? configuration.tenant_can_send_money === false : false;
};

export const isTransferDisabledSelector = (state) => {
  const { application: { configuration } } = state;

  return !!configuration && !isEmpty(configuration) ? 
    (configuration.tenant_can_deposit_to_br === false && configuration.tenant_can_withdraw_from_br === false && configuration.tenant_can_transfer === false) 
    || (configuration.tenant_can_transfer === false && configuration.tenant_can_add_bank_account === false) :
    false;
};

export const isBankAccountsDisabledSelector = (state) => {
  const { application: { configuration } } = state;

  return !!configuration && !isEmpty(configuration) ?
    configuration.tenant_can_add_bank_account === false : false;
};

export const isOrderDisabledSelector = (state) => {
  const { application: { configuration } } = state;

  return !!configuration && !isEmpty(configuration) ?
    configuration.tenant_can_order === false : false;
};

export const isCreditCardsDisabledSelector = (state) => {
  const { application: { configuration } } = state;

  return !!configuration && !isEmpty(configuration) ?
    configuration.tenant_can_add_card_account === false : false;
};

export const canUseACHSelector = (state) => {
  const { application: { configuration } } = state;

  return !!configuration && !isEmpty(configuration) ?
    configuration.tenant_can_use_ach_for_transactions === false : false;
};

export const canAddBankAccountSelector = (state) => {
  const { application: { configuration } } = state;

  return !!configuration && !isEmpty(configuration) &&
    configuration.tenant_can_add_bank_account; 
};

export const maintenancesSelector = (state) => {
  const { application: { maintenances } } = state;

  return Array.isArray(maintenances) ? maintenances : [];
};

const appReducer = (state = INIT_STATE, action) => {
  console.log(action); // eslint-disable-line no-console

  switch (action.type) {
    case CONST.SET_APP_OPENED_IN_POPUP:
      return assocPath(['isPopup'], action.payload.isPopup, state);
    case CONST.SET_PAYMENT_POPUP:
      return compose(
        assocPath(['paymentPopup', 'isOpened'], action.payload.isOpened),
        assocPath(['paymentPopup', 'isPaymentSource'], action.payload.isPaymentSource),
      )(state);
    case CONST.SET_FIELD:
      return assoc(action.payload.field, action.payload.value, state);
    default:
      return state;
  }
};

export default appReducer;
