export const CONSTANTS = {
  GET_CURRENT_WALLET: '@wallet/GET_CURRENT_WALLET',
  SET_CURRENT_WALLET: '@wallet/SET_CURRENT_WALLET',
  SET_WALLET_BANK_ACCOUNTS: '@wallet/SET_WALLET_BANK_ACCOUNTS',
  SET_WALLET_CARD_ACCOUNTS: '@wallet/SET_WALLET_CARD_ACCOUNTS',
  SET_WALLET_ACCOUNTS: '@wallet/SET_WALLET_ACCOUNTS',
  SET_FIELD: '@wallet/SET_FIELD',
  UPDATE_WALLET_HANDLE: '@wallet/UPDATE_WALLET_HANDLE',
  UPDATE_TRANSACTION_ALLOCATION: '@wallet/UPDATE_TRANSACTION_ALLOCATION',
  SET_ERROR_MESSAGE: '@wallet/SET_ERROR_MESSAGE',
  FETCH_ACCOUNTS: '@wallet/FETCH_ACCOUNTS',
  ACCOUNT_TYPE_FREEDOMCOIN: 'freedomcoin',
  ACCOUNT_TYPE_USD: 'usd',
  GET_RATE: '@wallet/GET_RATE',
  SET_RATE: '@wallet/SET_RATE',
  UPDATE_EIN: '@wallet/UPDATE_EIN',
  SET_CURRENCY_PROMOS: '@wallet/SET_CURRENCY_PROMOS',
  SET_AVAILABLE_WALLETS: '@wallet/SET_AVAILABLE_WALLETS',
  HANDLE_WALLET_SELECT: '@wallet/HANDLE_WALLET_SELECT',
  GET_WALLET_USERS: '@wallet/GET_WALLET_USERS',
  ADD_WALLET_USER: '@wallet/ADD_WALLET_USER',
  REMOVE_WALLET_USER: '@wallet/REMOVE_WALLET_USER',
};

export const ACCESS_TYPES = {
  'view': 1,
  'transact': 2,
  'owner': 3,
};

export const ACCESS_NAMES = {
  VIEW: 'view',
  TRANSACT: 'transact',
  OWNER: 'owner',
};

export const ACCESS_NAMES_TEXT = {
  [ACCESS_NAMES.VIEW]: 'view only',
  [ACCESS_NAMES.TRANSACT]: 'transfer only',
  [ACCESS_NAMES.OWNER]: 'owner',
};


export default CONSTANTS;
