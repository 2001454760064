import React from 'react';
import { InvoiceShape } from '../../constants/prop-types';
import cn from 'classnames';

import Svg from '../../components/svg';
import UserCard from '../../components/user-card';

import { withCurrency } from '../../utils/send-money-utils';

import CONST from '../../constants/invoice-constants';

const InvoiceDetailsResult = ({ invoice = {} }) => {
  const { send_to_sms, send_to_email, recipient, amount, status } = invoice;

  const recipientInfo = [recipient, send_to_sms, send_to_email].filter(Boolean);
  const statusText = CONST.INVOICE_STATUS_TEXT[status];

  return (
    <div className="exchange-result">
      {recipientInfo.length > 0 && (
        <div className="exchange-result_hint">
          <Svg name="arrow" className="exchange-result_hint-icon" />
        </div>
      )}
      <div className="exchange-result_body">
        {recipientInfo.length > 0 && (
          <div className="exchange-result_item">
            <UserCard
              avatarSrc={''}
              avatarType={'individual'}
              avatarClassName={cn({
                'transactions-log-item_avatar -gray': true,
                '-status': true,
              })}
              className="exchange-result_item-icon"
              contentMain={recipientInfo[0]}
              contentSecondary={<span className="word-break-word">{recipientInfo[1]}</span>}
            />
          </div>
        )}
        <div className="exchange-result_item">
          <UserCard
            className="exchange-result_item-icon"
            avatarType={status}
            contentMain={withCurrency(amount, 'USD')}
            contentSecondary={<span className="word-break-word">{statusText}</span>}
          />
        </div>
      </div>
    </div>
  );
};

InvoiceDetailsResult.propTypes = {
  invoice: InvoiceShape,
};

export default InvoiceDetailsResult;
