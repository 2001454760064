import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InvoiceFilterFields from './invoice-filter-fields';
import Button from '../../components/button';
import { TransactionFiltersShape } from '../../constants/prop-types';

const InvoiceFilterDesktop = ({ 
  filters,
  setFilterValue,
  setTimeFrameFilter,
  range,
  openCalendar,
  filterPlateRef,
  filterVisible,
  clearFilters,
  applyFilters,
}) => (
  <div
    ref={filterPlateRef}
    className={cn('search-input_filter-dropdown transactions-filter-dropdown', {
      '-visible': filterVisible,
    })}
  >
    <div className="transactions-filter-dropdown_body">
      <InvoiceFilterFields
        filters={filters}
        setFilterValue={setFilterValue}
        timeFrameValue={filters.timeFrameValue}
        setTimeFrameFilter={setTimeFrameFilter}
        range={range}
        openCalendar={openCalendar}
      />
    </div>

    <div className="transactions-filter-dropdown_footer full-width">
      <Button
        color="gray"
        transparency="full"
        xSize="full"
        ySize="xs"
        onClick={clearFilters}
      >
        Reset filters
      </Button>
      <Button
        color="blue"
        xSize="full"
        ySize="xs"
        className="transactions-filter-dropdown_footer-control"
        onClick={() => applyFilters(filters)}
      >
        Show Matching Invoices
      </Button>
    </div>
  </div>
);

InvoiceFilterDesktop.propTypes = {
  filters: TransactionFiltersShape.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  setTimeFrameFilter: PropTypes.func.isRequired,
  range: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  openCalendar: PropTypes.func.isRequired,
  filterPlateRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  filterVisible: PropTypes.bool.isRequired,
  clearFilters: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
};

export default InvoiceFilterDesktop;