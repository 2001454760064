import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Access from './access';

const ProtectedRoute = ({ 
  component: Component,
  requiredAccess,
  access,
  redirectTo = '/404',
  ...rest 
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Access
          requiredAccess={requiredAccess}
          fallback={<Redirect to={redirectTo} />}
        >
          <Component {...props} />
        </Access>
      )}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  requiredAccess: PropTypes.oneOf(['view', 'transact', 'owner']).isRequired,
  redirectTo: PropTypes.string,
  access: PropTypes.string
};

const mapStateToProps = (state) => ({
  access: state.wallet.walletRights
});

export default connect(mapStateToProps)(ProtectedRoute); 