import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { InvoiceShape } from '../../constants/prop-types';
import Badge from '../../components/badge';
import Modal from '../../components/modal';
import Loader from '../../components/loader';
import InvoiceDetailsResult from './invoice-details-result';
import Button from '../../components/button';
import ModalDeletionConfirmation from '../../components/modal-deletion-confirmation';
import { getInvoiceDetails, cancelInvoice, createNewInvoice } from '../../actions/invoice-actions';
import { addNotification } from '../../actions/toast-actions';
import { getFieldsFromAttributes } from '../../utils/invoice-utils';
import CONST from '../../constants/invoice-constants';
import { withCurrency } from '../../utils/send-money-utils';

const InvoiceDetailsModal = ({ 
  shown,
  onClose,
  invoice,
  isLoading,
  getInvoiceDetails,
  cancelInvoice,
  createNewInvoice,
  addNotification
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  let badgeStatus = '';
  let badgeText = '';
  let link = '';
  let orderId = '';
  let recipientInfo = [];
  let canBeCancelled = false;
  let canBeCopied = false;
  let tax = '';
  let total = '';

  useEffect(() => {
    if (invoice && invoice.invoice_id) {
      getInvoiceDetails(invoice.invoice_id);
    }
    
  }, [invoice && invoice.invoice_id]);

  if(invoice) {
    badgeStatus = CONST.INVOICE_BADGE_STATUS[invoice.status];
    const invoiceDateType = invoice.status === CONST.INVOICE_STATUS.pending && invoice.expires_at ? 'expires_at' 
      : invoice.status === CONST.INVOICE_STATUS.completed ? 'created_at' : '';
    const dateFormat = invoiceDateType && moment().year() === moment(invoice[invoiceDateType]).year() ? 'MMMM Do' : 'MMMM Do, YYYY';
    const badgeDate = invoiceDateType && moment(invoice[invoiceDateType]).format(dateFormat);
    badgeText = `${CONST.INVOICE_BADGE_TEXT[invoice.status]} ${badgeDate}`;

    link = `${process.env.REACT_APP_API_URL}/v1/pay/${invoice.ticket}`;
    recipientInfo = [invoice.recipient, invoice.send_to_email, invoice.send_to_sms].filter(Boolean);
    canBeCancelled = invoice.status === CONST.INVOICE_STATUS.pending;
    canBeCopied = invoice.status !== CONST.INVOICE_STATUS.pending;
    let mappedInvoice = getFieldsFromAttributes(invoice);
    orderId = mappedInvoice.orderId;
    tax = mappedInvoice.tax;
    total = mappedInvoice.total;
  }


  return (
    <Modal show={shown} onClose={onClose} closeButton containerClassName="-white">
      {
        isLoading ? (
          <Loader size="sm" color="blue" className="-centered" />
        ) : (!invoice || !invoice.invoice_id) ? (
          <div className="modal_wrapper">
            <div className="modal-text-wrapper">
              <h1 className="modal_header-centered">Invoice not found</h1>
              <p className="modal_body-centered">
                Invoice does not exist<br />
                or you are not allowed to see it.
              </p>
            </div> 
        
            <div className="modal_body -down">
              <p>If you are sure that the invoice should be here, it might be something wrong on our side.</p>
              <p>Try again later or contact 
                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                  target="_blank" 
                  rel="noopener noreferrer"
                  href={process.env.REACT_APP_HELP_DESK_URL}
                >
                  {' Customer Support'}
                </a>
              </p>
            </div>
          </div>
        ) : (
          <div className="invoice-details">
            <div className="modal_header">
              <h1 className="js-modal-title">Invoice Details</h1>
            </div>
            <div className="modal_body">
              <div className="layer">
                <InvoiceDetailsResult invoice={invoice} /> 

                <div className="js-transaction-result-badge layer -space-up-xs -space-down-lg">
                  <Badge status={badgeStatus}>
                    {badgeText}
                  </Badge>
                </div>
                {invoice.status === CONST.INVOICE_STATUS.pending && (
                  <div className="description-list -fixed-title -x-condensed">
                    <div className="description-list_item">
                      <div className="description-list_title">Payment link</div>
                      <div className="js-transaction-result-type-text description-list_value">
                        <div className="description-list_item-primary invoice-details__link-text">
                          {link}
                        </div>
                        <div className="description-list_item-secondary invoice-details__link-copy">
                          <a onClick={() => {
                            navigator.clipboard.writeText(link);
                            addNotification('Link copied to clipboard', 200, 2000);
                          }}>
                          Copy
                          </a>
                        </div>  
                      </div>
                    </div>
                  </div>
                )}
                {orderId && (
                  <div className="description-list -fixed-title -x-condensed">
                    <div className="description-list_item">
                      <div className="description-list_title">Order ID</div>
                      <div className="js-transaction-result-type-text description-list_value">{orderId}</div>
                    </div>
                  </div>
                )}

                <div className="description-list -fixed-title -x-condensed">
                  <div className="description-list_item">
                    <div className="description-list_title">Amount</div>
                    <div className="js-transaction-result-type-text description-list_value">{withCurrency(invoice.amount, 'USD')}</div>
                  </div>
                  {invoice.tax && (
                    <div className="description-list_item">
                      <div className="description-list_title">Tax</div>
                      <div className="js-transaction-result-type-text description-list_value">{withCurrency(tax, 'USD')}</div>
                    </div>
                  )}
                  {invoice.total && (
                    <div className="description-list_item">
                      <div className="description-list_title">Total</div>
                      <div className="js-transaction-result-type-text description-list_value">{withCurrency(total, 'USD')}</div>
                    </div>
                  )}
                  {invoice.description && (
                    <div className="description-list_item">
                      <div className="description-list_title">Description</div>
                      <div className="js-transaction-result-type-text description-list_value">{invoice.description}</div>
                    </div>
                  )}
                </div>
                <div className="description-list -fixed-title -x-condensed">
                  <div className="description-list_item">
                    <div className="description-list_title">Created</div>
                    <div className="js-transaction-result-type-text description-list_value">{moment(invoice.created_at).format('MMMM D, YYYY')}</div>
                  </div>
                  <div className="description-list_item">
                    <div className="description-list_title">Expires</div>
                    <div className="js-transaction-result-type-text description-list_value">{moment(invoice.expires_at).format('MMMM D, YYYY')}</div>
                  </div>

                </div>
                <div className="description-list -fixed-title -x-condensed">
                  <div className="description-list_item">
                    <div className="description-list_title">Recipient</div>
                    <div className="js-transaction-result-type-text description-list_value">
                      <div className={cn('description-list_item-primary', {
                        '-lowercase': recipientInfo[0] === invoice.send_to_email
                      })}>
                        {recipientInfo[0] || 'Not Specified'}
                      </div>
                      <div className={cn('description-list_item-secondary', {
                        '-lowercase': recipientInfo[1] === invoice.send_to_email
                      })}>
                        {recipientInfo[1]} 
                      </div>
                    </div>
                  </div>
                </div>
                {Array.isArray(invoice.attributes) && invoice.attributes.length > 0 && invoice.attributes.map((attribute) => (
                  <div className="description-list -fixed-title -x-condensed">
                    <div className="description-list_item">
                      <div className="description-list_title">{attribute.name}</div>
                      <div className="js-transaction-result-type-text description-list_value">{attribute.value}</div>
                    </div>
                  </div>
                ))}
                <div className="description-list -fixed-title -x-condensed">
                  <div className="description-list_item">
                    <div className="description-list_title">Status</div>
                    <div className={`description-list_value font-weight-bold color-${CONST.INVOICE_STATUS_COLOR[invoice.status]} -capitalize`}>
                      {CONST.INVOICE_STATUS[invoice.status]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal_footer">
              <div className="modal_footer-controls">
                { canBeCancelled && (
                  <Button
                    color="red"
                    xSize="full"
                    className="js-submit-modal-button modal_footer-control -submit"
                    onClick={() => setIsDeleteOpen(true)}
                    transparency="full"
                  >
                    Cancel invoice
                  </Button>
                )}
                { canBeCopied && (
                  <Button
                    color="blue"
                    xSize="full"
                    className="js-submit-modal-button modal_footer-control -submit"
                    onClick={() => createNewInvoice(invoice)}
                    transparency="full"
                  >
                    Create new invoice
                  </Button>
                )}
              </div>
            </div>
          </div>
        )
      }
      <ModalDeletionConfirmation 
        show={isDeleteOpen}
        confirmationText="Do you really want to cancel this invoice?"
        isLoading={isLoading}
        onConfirm={() => { setIsDeleteOpen(false); setTimeout(() => cancelInvoice(invoice.invoice_id), 100); }}
        onClose={() => setIsDeleteOpen(false)}
      />

    </Modal>
  );
};

InvoiceDetailsModal.propTypes = {
  shown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invoice: InvoiceShape,
  isLoading: PropTypes.bool.isRequired,
  getInvoiceDetails: PropTypes.func.isRequired,
  cancelInvoice: PropTypes.func.isRequired,
  createNewInvoice: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoice: state.invoice.invoiceToShow,
  isLoading: state.invoice.isLoading,
});

export default connect(mapStateToProps, { getInvoiceDetails, cancelInvoice, createNewInvoice, addNotification })(InvoiceDetailsModal);
