import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isEmpty } from 'ramda';

import Button from '../button';
import Modal from '../modal';
import Svg from '../svg';
import Loader from '../loader';
import { CreditCardShape } from '../../constants/prop-types';
import CreditCardLabel from './credit-cards-label';
import CreditCardEdit from './credit-cards-edit';
import ModalDeletionConfirmation from '../modal-deletion-confirmation';
import { getIconName, CARD_STATUS, getStatusColor } from '../../constants/card-account-constants';
import { updateCardAccount, removeCardAccount, setField, setValidationError, getCardService } from '../../actions/card-account-actions';
import { formatExpiration } from '../../utils/string-utils';
import Access from '../hoc/access';
import { ACCESS_NAMES } from '../../constants/wallet-constants';

const CreditCardDetails = ({
  isLoading,
  cardDetails,
  containerClassName,
  toggleEditCardLabelModal,
  updateCardAccount,
  removeCardAccount,
  setField,
  setValidationError,
  getCardService,
  validationErrors,
  service_id,
  ...props
}) => {

  useEffect(() => {
    if(!service_id) {
      getCardService();
    }
  }, []);
  
  const [ isLabelOpen, setIsLabelOpen ] = useState(false);
  const [ isEditOpen, setIsEditOpen ] = useState(false);
  const [ isDeleteOpen, setIsDeleteOpen ] = useState(false);

  return (
    <Modal {...props} containerClassName={cn('-white', containerClassName)}>
      <div className="modal_header cards-page__details-header">
        <h1 className="js-modal-title">Credit Card Details</h1>
      </div>
      <div className="modal_body">
        {isLoading && isEmpty(cardDetails) ? (
          <div className="cards-page__details-loader">
            <Loader color="blue" size="md" />
          </div>
        ) : (
          <div className="description-list -fixed-title -title-width-xl -condensed full-width">
            <div className="description-list_body">
              <div className="description-list_item">
                <div className="description-list_title">Card number</div>
                <div className="description-list_value">
                  <div className="js-routing-number description-list_item-primary">
                    <Svg name={getIconName(cardDetails.bank_name)} className="icon cards-page__icon" />
                    <span className="js-account-number account-number-bullets">&bull;&bull;</span>
                    {cardDetails.account_number}
                    {cardDetails.is_default_backup && (
                      <div className="label cards-page__label">Default backup</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="description-list_item">
                <div className="description-list_title">Name on card</div>
                <div className="description-list_value">
                  <div className="description-list_item-primary">
                    {cardDetails.holder_fname || cardDetails.holder_lname ? `${cardDetails.holder_fname} ${cardDetails.holder_lname}` : 'N/A'}
                  </div>
                </div>
              </div>
              <div className="description-list_item">
                <div className="description-list_title">Expiration date</div>
                <div className="description-list_value">
                  <div className="js-account-type description-list_item-primary">
                    {formatExpiration(cardDetails.expiration, true)}
                  </div>
                </div>
              </div>
              <div className="description-list_item">
                <div className="description-list_title">Card label</div>
                <div className="description-list_value">
                  <Button
                    className="js-edit-account-button -has-arrow"
                    color="blue"
                    transparency="full"
                    xSize="condensed-full"
                    ySize="condensed"
                    onClick={() => setIsLabelOpen(true)}
                    disabled={isLoading}
                  >
                    <div className="button_head">
                      {cardDetails.user_description || 'Add card label'}
                    </div>
                  </Button>
                </div>
              </div>
              <div className="description-list_item">
                <div className="description-list_title">Card status</div>
                <div className={cn('description-list_value', getStatusColor(cardDetails.status))}>
                  <div className="js-account-type description-list_item-primary">
                    {cardDetails.status}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <div className="modal_footer">
          <div className="modal_footer-controls">
            {!isEmpty(cardDetails) && (
              <Button
                color="red"
                transparency="full"
                xSize="full"
                className="js-remove-button modal_footer-control -cancel"
                onClick={() => setIsDeleteOpen(true)}
                disabled={isLoading}
              >
              Remove this card
              </Button>
            )}
            {!isEmpty(cardDetails) && !cardDetails.is_default_backup && cardDetails.status === 'active' && (
              <Button
                color="blue"
                xSize="full"
                className="js-verify-button modal_footer-control -submit"
                onClick={() => updateCardAccount(cardDetails.card_account_id, { is_default: true }, setField(['cardDetails', 'is_default_backup'], true))}
                disabled={isLoading}
                loading={isLoading}
              >
              Set as default backup
              </Button>
            )}
            {!isEmpty(cardDetails) && cardDetails.status === CARD_STATUS.EXPIRED && (
              <Button
                color="blue"
                xSize="full"
                className="js-verify-button modal_footer-control -submit"
                onClick={() => { setIsEditOpen(cardDetails.expiration); setField(['cardDetails', 'expiration'], ''); }}
                disabled={isLoading}
                loading={isLoading}
              >
              Update card info
              </Button>
            )}
          </div>
        </div>
      </Access>
      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <CreditCardLabel 
          show={isLabelOpen}
          onClose={() => {
            setIsLabelOpen(false);
          }}
          cardDetails={cardDetails}
          updateCardAccount={updateCardAccount}
          isLoading={isLoading}
          setField={setField}
          closeButton
        />
      </Access>

      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <CreditCardEdit 
          show={!!isEditOpen}
          onClose={() => {
            if(!cardDetails.expiration || cardDetails.expiration === '__ / __') {
              setField(['cardDetails', 'expiration'], isEditOpen);
            }
            setIsEditOpen(false);
          }}
          cardDetails={cardDetails}
          isLoading={isLoading}
          setField={setField}
          updateCard={() => {
            updateCardAccount(cardDetails.card_account_id, cardDetails, () => setIsEditOpen(false));
          }}
          validationErrors={validationErrors}
          setValidationError={setValidationError}
          closeButton
        />
      </Access>
      <Access requiredAccess={ACCESS_NAMES.OWNER}>
        <ModalDeletionConfirmation 
          show={isDeleteOpen}
          confirmationText="Do you really want to remove this credit card?"
          isLoading={isLoading}
          onConfirm={() => { removeCardAccount(cardDetails.card_account_id, () => { setIsDeleteOpen(false); props.onClose(); }); }}
          onClose={() => setIsDeleteOpen(false)}
        />
      </Access>
    </Modal>
  );
};

CreditCardDetails.propTypes = {
  cardDetails: CreditCardShape,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  containerClassName: PropTypes.string,
  toggleEditCardLabelModal: PropTypes.func,
  updateCardAccount: PropTypes.func,
  removeCardAccount: PropTypes.func,
  setField: PropTypes.func,
  setValidationError: PropTypes.func,
  getCardService: PropTypes.func,
  validationErrors: PropTypes.shape({}),
  service_id: PropTypes.number
};

CreditCardDetails.defaultProps = {
  cardDetails: {
    account_number: null,
    bank_name: null,
    routing_number: null,
    amounts_verified: false,
    withdraw_status: 'pending',
    user_description: null,
  },
  containerClassName: null,
  isLoading: false,
  onClose: () => {},
  toggleEditCardLabelModal: () => {},
  updateCardAccount: () => {},
  removeCardAccount: () => {},
  getCardService: () => {},
  setField: () => {}
};

const mapStateToProps = (state) => ({
  isLoading: state.cardAccount.isLoading,
  cardDetails: state.cardAccount.cardDetails,
  validationErrors: state.cardAccount.validationErrors,
  service_id: state.cardAccount.service_id

});

export default connect(mapStateToProps, { updateCardAccount, removeCardAccount, setField, setValidationError, getCardService })(CreditCardDetails);
