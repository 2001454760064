import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range-creativeco';
import Button from '../../components/button';
const InvoiceFilterDateRange = ({ 
  calendarOpened, 
  rangeRef,
  handleSelect, 
  cancelCalendar,
  applyDates,
  disabledDates,
  initDisabledDates,
  range,
}) => {
  const selectionRange = range ? [range] : [{
    endDate: moment().toDate(),
    startDate: moment().toDate(),
    key: 'selection',
  }];

  return (
    <div
      ref={rangeRef}
      className={cn('search-input_filter-dropdown transactions-filter-dropdown -visible')}
    >
      <div className="transactions-filter-dropdown_body -daterange">
        <DateRangePicker
          hideMonthName={true}
          weekDateFormat={'dd'}
          ranges={selectionRange}
          showMonthArrow={false}
          showMonthAndYearPickers={false}
          staticRanges={[]}
          inputRanges={[]}
          months={1.1}
          monthDisplayFormat={'MMMM, YYYY'}
          showDateDisplay={true}
          dateDisplayFormatter={({startDate, endDate}) => (
            <div className="daterange-header">{moment(startDate).format('MMMM, D')} &mdash; {moment(endDate).format('MMMM, D')}</div>
          )}
          minDate={disabledDates.minDate}
          globalMinDate={initDisabledDates.minDate}
          globalMaxDate = {initDisabledDates.maxDate}
          maxDate={disabledDates.maxDate}
          renderStaticRangeLabel={() => ({ hasCustomRendering: false })}
          direction="vertical"
          scroll={{ enabled: true }}
          dateDisplayFormat={'MMMM, D YYYY'}
          onChange={handleSelect}
        />
      </div>

      <div className="transactions-filter-dropdown_footer full-width">
        <Button
          color="gray"
          transparency="full"
          xSize="full"
          onClick={cancelCalendar}
        >
          Cancel
        </Button>
        
        <Button
          color="blue"
          xSize="full"
          ySize="xs"
          className="transactions-filter-dropdown_footer-control"
          onClick={applyDates}
        >
          Apply dates
        </Button>
      </div>
    </div>
  );
};

InvoiceFilterDateRange.propTypes = {
  calendarOpened: PropTypes.bool.isRequired,
  rangeRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  handleSelect: PropTypes.func.isRequired,
  cancelCalendar: PropTypes.func.isRequired,
  applyDates: PropTypes.func.isRequired,
  disabledDates: PropTypes.shape({
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  }),
  initDisabledDates: PropTypes.shape({
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  }),
  range: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
};

export default InvoiceFilterDateRange;