import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import WalletIcon from '../../pages/wallet-select/wallet-icon';

const HeaderButton = ({ menuIsOpened, onClick, walletType, initials }) => !menuIsOpened ? (
  <WalletIcon
    className="header-control -wallet-icon"
    type={walletType} 
    initials={initials}
    onClick={onClick}
  />
) : (
  <button
    className={
      cn('header_control -hamburger hamburger', { '-active': menuIsOpened })
    }
    onClick={onClick}
  />
);

HeaderButton.propTypes = {
  menuIsOpened: PropTypes.bool,
  onClick: PropTypes.func,
  walletType: PropTypes.string,
  initials: PropTypes.string,
};

HeaderButton.defaultProps = {
  onClick: () => {
  },
  walletType: '',
  initials: '',
};

export default HeaderButton;
