import React from 'react';
import PropTypes from 'prop-types';
import { TransactionShape } from '../constants/prop-types';
import moment from 'moment';
import { groupBy } from 'ramda';
import { stringify } from 'qs';

import { trackEvent } from '../utils/ganalytics';
import { history } from '../init-store';
import Loader from './loader';
import TransactionModal from './transaction-modal';
import Transaction from './transaction';
import Svg from '../components/svg';
import { createStickybitsComponent } from '../components/hoc/stickybits';
import { formatTransactionGroupTimeDate } from '../utils/transaction-utils';

export const replaceUrl = (transactionId, listType = 'transaction') => {
  let path = window.location.pathname;

  if (transactionId) {
    path += '?' + stringify({
      [listType]: transactionId
    });
  }

  history.push(path);
};

const TransactionsListStickyBits = createStickybitsComponent(
  '.transactions_group-header',
  {
    useStickyClasses: true,
    stickyBitStickyOffset: 68, // height of both mobile and desktop headers
    stickyClass: '-stuck-visible',
    stuckClass: '-stuck-scrolled',
  },
);

const TransactionsLoading = props => (
  <div className="transactions-log_loader-wrapper">
    <Loader color="blue" size="sm" />
    { props.children }
  </div>
);

TransactionsLoading.propTypes = {
  children: PropTypes.node
};

const TransactionsList = (props) => {

  const { pending, completed, pendingIsLoading, isSeparate, hasMore, filterByAccount,
    noTransactions, transactionToShow, transactionShown, isInvoiceList, modalComponent, listType,
    setTransaction, setModal
  } = props;

  const ModalComponent = modalComponent ? modalComponent : TransactionModal;
  const groupList = (data) => {
    const result = Object
      .entries(groupBy(
        item => moment(item.initiated_at).format('dddd, MMMM D, Y'),
        data,
      ))
      .map(([key, value]) => ({ date: key, transactions: value }));

    return result.sort((firstGroup, secondGroup) =>
      moment(secondGroup.date, 'dddd, MMMM D, Y').diff(moment(firstGroup.date, 'dddd, MMMM D, Y')));
  };

  const openModal = transaction => {
    if (setTransaction && setModal) {
      replaceUrl(transaction.transaction_id, listType);

      trackEvent('transaction_view','View transaction details');

      setTransaction(transaction);
      setModal(true);
    }
  };

  const closeTransaction = () => {
    if (setTransaction && setModal) {
      replaceUrl();
    
      setTransaction(null);
      setModal(false);
    }
  };

  if (noTransactions) {
    return <TransactionsListStickyBits><ModalComponent
      shown={transactionShown}
      transaction={transactionToShow}
      onClose={closeTransaction}
    />
    </TransactionsListStickyBits>;
  }

  const groupsPending = groupList(pending);
  const groupCompleted = groupList(completed);
  const listName = isInvoiceList ? 'invoices' : 'transactions';

  return (
    <TransactionsListStickyBits>
      {
        !pendingIsLoading && pending.length > 0 && (
          <div className="transactions_group">
            <div className="transactions_group-header js-transactions-group-header">
              <h3 className="js-dashboard-transactions-pending-header transactions_group-title">
                  Pending {listName}
                <span className="transactions_group-title-current-log-item-date" />
              </h3>
              {/* TODO: will be fixed in separate ticket */}
              {/* {isSeparate && (
                <div className="transactions_group-header-controls">
                  <button
                    className="transactions_trigger -scroll-to-search-trigger"
                    onClick={props.handleScrollToSearchTriggerClick}
                  >
                    <Svg name="magnifying-glass" className="transactions_trigger-icon" />
                  </button>
                  <button
                    className="transactions_trigger -filter-trigger"
                    onClick={props.handleFilterTriggerClick}
                  >
                    <Svg name="filter-bars" className="transactions_trigger-icon" />
                  </button>
                </div>
              )} */}
              { !isSeparate && <a href="/transactions" className="transactions_group-link">All</a> }
            </div>
            <div className="js-dashboard-pending-transactions transactions_log transactions-log">
              {
                !pendingIsLoading ?
                  groupsPending.map(group => (
                    <div key={group.date} className="transactions-log_section">
                      <div className="transactions-log_section-header">
                        {formatTransactionGroupTimeDate(group.date)}
                      </div>
                      <div className="transactions-log_section-body">
                        { group.transactions.map(transaction => (
                          <Transaction
                            key={transaction.transaction_id}
                            onClick={openModal}
                            transaction={transaction}
                            filterByAccount={filterByAccount}
                          />))}
                      </div>
                    </div>))
                  : (
                    <div className="transactions-log_loader-wrapper">
                      <Loader color="blue" size="sm" />
                    </div>
                  )
              }
            </div>
          </div>
        )
      }
      { completed.length > 0 && (
        <div className="transactions_group">
          <div className="transactions_group-header js-transactions-group-header">
            {completed.length > 0 && (
              <h3 className="js-dashboard-transactions-completed-header transactions_group-title">
                Completed {listName}
                <span className="transactions_group-title-current-log-item-date" />
              </h3>
            )}
            {isSeparate && (
              <div className="transactions_group-header-controls">
                <button
                  className="transactions_trigger -scroll-to-search-trigger"
                  onClick={props.handleScrollToSearchTriggerClick}
                >
                  <Svg name="magnifying-glass" className="transactions_trigger-icon" />
                </button>
                <button
                  className="transactions_trigger -filter-trigger"
                  onClick={props.handleFilterTriggerClick}
                >
                  <Svg name="filter-bars" className="transactions_trigger-icon" />
                </button>
              </div>
            )}
            {(completed.length > 0 && !isSeparate) && (
              <a href="/transactions" className="transactions_group-link">All</a>
            )}
          </div>
          <div className="js-dashboard-completed-transactions transactions_log transactions-log">
            {
              completed.length > 0 &&
              groupCompleted.map(group => (
                <div key={group.date} className="transactions-log_section">
                  <div className="transactions-log_section-header">
                    {formatTransactionGroupTimeDate(group.date)}
                  </div>
                  <div className="transactions-log_section-body">
                    { group.transactions.map(transaction => (
                      <Transaction
                        key={transaction.transaction_id}
                        onClick={openModal}
                        transaction={transaction}
                        filterByAccount={filterByAccount}
                      />))}
                  </div>
                </div>))
            }
            {hasMore && <TransactionsLoading />}
          </div>
        </div>
      ) }

      <ModalComponent
        shown={transactionShown}
        transaction={transactionToShow}
        onClose={closeTransaction}
      />
    </TransactionsListStickyBits>
  );
};

TransactionsList.propTypes = {
  pending: PropTypes.arrayOf(TransactionShape),
  completed: PropTypes.arrayOf(TransactionShape),
  transactionShown: PropTypes.bool,
  isSeparate: PropTypes.bool,
  pendingIsLoading: PropTypes.bool,
  noTransactions: PropTypes.bool,
  transactionToShow: TransactionShape,
  hasMore: PropTypes.bool,
  handleScrollToSearchTriggerClick: PropTypes.func, 
  handleFilterTriggerClick: PropTypes.func,
  setTransaction: PropTypes.func,
  setModal: PropTypes.func,
  filterByAccount: PropTypes.string,
  isInvoiceList: PropTypes.bool,
  modalComponent: PropTypes.func,
  listType: PropTypes.string,
};

const TransactionsListWithLoader = props => {
  const { pendingIsLoading, transactionShown, transactionToShow, setTransaction, setModal, modalComponent } = props;
  const ModalComponent = modalComponent ? modalComponent : TransactionModal;

  if (pendingIsLoading) {
    const closeTransaction = () => {

      if (setTransaction && setModal) {
        replaceUrl();
      
        setTransaction(null);
        setModal(false);
      }
    };

    return <TransactionsLoading>
      <ModalComponent
        shown={transactionShown}
        transaction={transactionToShow}
        onClose={closeTransaction}
      />
    </TransactionsLoading>;
  } else {
    return <TransactionsList {...props} />;
  }
};

TransactionsListWithLoader.propTypes = {
  pendingIsLoading: PropTypes.bool,
  setTransaction: PropTypes.func,
  setModal: PropTypes.func,
  transactionToShow: TransactionShape,
  transactionShown: PropTypes.bool,
  modalComponent: PropTypes.func,
};

export default TransactionsListWithLoader;
