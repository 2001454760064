import PropTypes from 'prop-types';

/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */

// Common types
export const CurrenciesEnum = PropTypes.string; // Now we don't know currencies list before we get it from server

export const AccountTypesEnum = PropTypes.oneOf(['account', 'bankaccount', 'loyalty', 'bank', 'creditcard']);

export const BankAccountTypeEnum = PropTypes.oneOf(['checking', 'savings']);

export const BankAccountClassEnum = PropTypes.oneOf(['individual', 'business']);

export const AccountClassEnum = PropTypes.string; // Now we don't know currencies list before we get it from server

export const PermissionShape = PropTypes.shape({
  value: PropTypes.bool,
  message: PropTypes.string
});

export const LimitsShape = PropTypes.shape({
  value: PropTypes.number,
  message: PropTypes.string
});

export const TimeFrameDatesShape = PropTypes.shape({
  from_date: PropTypes.string,
  to_date: PropTypes.string
});

export const PagingShape = PropTypes.shape({
  offset: PropTypes.number,
  count: PropTypes.number
});

export const RatesShape = PropTypes.shape({});

// User types

export const AddressShape = PropTypes.shape({
  address_id: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zip4: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  verified: PropTypes.bool,
  is_primary: PropTypes.bool
});

export const EnabledStatesShape = PropTypes.shape({
  can_transfer: PermissionShape,
  can_send_money: PermissionShape,
  can_order: PermissionShape,
  can_withdraw_from_br: PermissionShape,
  can_deposit_to_br: PermissionShape,
  can_use_ach_for_transactions: PermissionShape,
  can_add_bank_account: PermissionShape,
  max_send_amount: PropTypes.number,
  max_order_amount: PropTypes.number,
  max_daily_send_amount: PropTypes.number,
  order_fee: PropTypes.string,
  payment_fee: PropTypes.string,
  rate_modifier: PropTypes.string,
  max_ach_allowed: LimitsShape,
  resides_in_allowed_state: PermissionShape,
  prompt_for_gun_broker_wallet_link: PermissionShape,
  user_credit_max: LimitsShape,
  deferred_payment_limit: LimitsShape
});

export const noticeShape = PropTypes.shape({
  user_id: PropTypes.string,
  notice_id: PropTypes.string,
  acknowledged_at: PropTypes.string,
  notice: PropTypes.shape({
    notice_id: PropTypes.string,
    name: PropTypes.string,
    version: PropTypes.string,
    tenant_id: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    notice_summary: PropTypes.string,
    notice_text: PropTypes.string
  })
});

export const userReducerTypes = {
  user: PropTypes.shape({
    user_id: PropTypes.string,
    fname: PropTypes.string,
    lname: PropTypes.string,
    tenant_id: PropTypes.string,
    status: PropTypes.string,
    kyc_status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    kyc_time: PropTypes.string,
    phone_prompt: PropTypes.bool,
    referral: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    address: AddressShape,
    email: PropTypes.string,
    phone: PropTypes.string,
    addresses: PropTypes.arrayOf(AddressShape),
    emails: PropTypes.arrayOf(
      PropTypes.shape({
        email_id: PropTypes.string,
        parent_id: PropTypes.string,
        tenant_id: PropTypes.string,
        is_primary: PropTypes.bool,
        tfa: PropTypes.bool,
        verified: PropTypes.bool,
        email: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        deleted_at: PropTypes.string,
        created_by: PropTypes.string
      })
    ),
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        phone_id: PropTypes.string,
        parent_id: PropTypes.string,
        is_primary: PropTypes.bool,
        tfa: PropTypes.bool,
        verified: PropTypes.bool,
        phone: PropTypes.string,
        country_code: PropTypes.string,
        extension: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        created_by: PropTypes.string
      })
    ),
    name_confirmed: PropTypes.bool,
    email_verified: PropTypes.bool,
    phone_verified: PropTypes.bool,
    identity_verified: PropTypes.bool,
    loyalty_member: PropTypes.bool,
    enabled_states: EnabledStatesShape,
    notices: PropTypes.arrayOf(noticeShape)
  }),
  rewards: PropTypes.shape({
    level: PropTypes.string,
    points: PropTypes.number,
    tierPoints: PropTypes.number,
    pointsYTD: PropTypes.number,
    nextTierPoints: PropTypes.number,
    nextTierText: PropTypes.string,
    total: PropTypes.number,
    referral: PropTypes.string,
    history: PropTypes.array,    // eslint-disable-line
    pointsToUse: PropTypes.array // eslint-disable-line
  }),
  gbData: PropTypes.shape({
    popupIsOpened: PropTypes.bool,
    userName: PropTypes.string,
    step: PropTypes.string,
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
    accessToken: PropTypes.string,
    validationToken: PropTypes.string,
    isGetting: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    emptyData: PropTypes.bool,
    isConfirmed: PropTypes.bool
  }),
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  notAsked: PropTypes.bool,
  emailModalStep: PropTypes.number
};

export const BalanceShape = PropTypes.shape({
  currency: CurrenciesEnum,
  ordinal: PropTypes.string,
  decimal: PropTypes.string,
  sign: PropTypes.oneOf(['+', '-']),
  usd_equivalent: PropTypes.string
});

export const AccountShape = PropTypes.shape({
  account_id: PropTypes.string,
  wallet_id: PropTypes.string,
  balance: BalanceShape,
  type: AccountClassEnum,
  split: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  blockchain_account_id: PropTypes.string
});

export const BankAccountShape = PropTypes.shape({
  bank_account_id: PropTypes.string,
  bank_name: PropTypes.string,
  user_description: PropTypes.string,
  account_number: PropTypes.string,
  routing_number: PropTypes.string,
  account_type: BankAccountTypeEnum,
  account_class: BankAccountClassEnum,
  amounts_verified: PropTypes.bool,
  allows: PropTypes.arrayOf(PropTypes.string),
  withdraw_status: PropTypes.oneOf(['allowed', 'pending', '']),
  status: PropTypes.oneOf(['active', 'pending', 'suspended']),
  created_by: PropTypes.string,
  created_at: PropTypes.string
});

// Wallet types

export const walletReducerTypes = {
  wallet: PropTypes.shape({
    wallet_id: PropTypes.string,
    type: PropTypes.oneOfType([AccountClassEnum, BankAccountClassEnum]),
    handle: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.string),
    avatar_image_url: PropTypes.string,
    created_by: PropTypes.string,
    created_at: PropTypes.string,
    updated_by: PropTypes.string,
    updated_at: PropTypes.string,
    can_receive_funds: PropTypes.bool,
    identity_verified: PropTypes.bool
  }),
  bankAccounts: PropTypes.arrayOf(BankAccountShape),
  accounts: PropTypes.arrayOf(AccountShape),
  errorMessage: PropTypes.string,
  notAsked: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  currencyPromos: PropTypes.arrayOf(PropTypes.shape({
    promoId: PropTypes.string,
    priority: PropTypes.number,
    name: PropTypes.string,
    teaser: PropTypes.string,
    description: PropTypes.string,
    iconUrl: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string
  }))
};

// Transactions types

// shape for "source" and "destination" properties of transaction
export const WalletShape = PropTypes.shape({
  wallet_id: PropTypes.string,
  handle: PropTypes.string,
  user_specified_id: PropTypes.string,
  name: PropTypes.string,
  type: BankAccountClassEnum,
  avatar_image_url: PropTypes.string
});

export const TransactionsTypesEnum = PropTypes.oneOf(['payment', 'transfer', 'order', 'promotion', 'fee', 'reconciliation', 'refund', 'verification', 'reward', 'autodraft', 'card fee', 'invoice']);

export const TransactionStatusEnum = PropTypes.oneOf(['failed', 'pending', 'complete', 'suspended', 'fully_refunded', 'partially_refunded']);

export const ExchangeShape = PropTypes.shape({
  currency: CurrenciesEnum,
  rate: PropTypes.string,
  description: PropTypes.string
});

export const TransactionAttributeShape = PropTypes.shape({
  transaction_attributes_id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

export const TransactionDetailShape = PropTypes.shape({
  transactiondetail_id: PropTypes.string,
  transaction_id: PropTypes.string,
  account_id: PropTypes.string,
  account_type: AccountTypesEnum,
  status: TransactionStatusEnum,
  type: TransactionsTypesEnum,
  description: PropTypes.string,
  blockchain_txnid: PropTypes.string,
  transaction_amount: BalanceShape,
  account_amount: BalanceShape,
  currency: CurrenciesEnum,
  transaction_account_exchange_rate: ExchangeShape,
  balance: BalanceShape,
  datetime: PropTypes.string,
  account_name: PropTypes.string
});

export const TransactionDetailListShape = PropTypes.shape({
  all: PropTypes.arrayOf(TransactionDetailShape),
  bitrail: PropTypes.arrayOf(TransactionDetailShape),
  destination: PropTypes.arrayOf(TransactionDetailShape),
  fee: PropTypes.arrayOf(TransactionDetailShape),
  rewards: PropTypes.arrayOf(TransactionDetailShape),
  simple: PropTypes.arrayOf(TransactionDetailShape),
  source: PropTypes.arrayOf(TransactionDetailShape),
});

export const TransactionFiltersShape = PropTypes.shape({
  search: PropTypes.string,
  type: PropTypes.arrayOf(TransactionsTypesEnum),
  direction: PropTypes.arrayOf(PropTypes.oneOf(['incoming', 'outgoing'])),
  timeFrameValue: PropTypes.oneOf(['TODAY', 'THIS_MONTH', 'LAST_90', 'CUSTOM']),
  timeFrameDates: TimeFrameDatesShape
});

export const TransactionShape = PropTypes.shape({
  transaction_id: PropTypes.string,
  type: TransactionsTypesEnum,
  status: TransactionStatusEnum,
  invoiceStatus: PropTypes.string,
  source: WalletShape,
  destination: WalletShape,
  description: PropTypes.string,
  is_refundable: PropTypes.bool,
  gross_amount: BalanceShape,
  net_amount: BalanceShape,
  fee_amount: BalanceShape,
  exchange_rate: ExchangeShape,
  initiated_by: PropTypes.string,
  initiated_at: PropTypes.string,
  updated_at: PropTypes.string,
  attributes: PropTypes.arrayOf(TransactionAttributeShape),
  transaction_details: TransactionDetailListShape
});

export const transactionReducerTypes = {
  transaction: TransactionShape,
  transactionShown: PropTypes.bool,
  next90IsLoading: PropTypes.bool,
  reportIsSending: PropTypes.bool,
  reportConfirmed: PropTypes.bool,
  filters: TransactionFiltersShape,
  transactionsPending: PropTypes.shape({
    data: PropTypes.arrayOf(TransactionShape),
    isLoading: PropTypes.bool
  }),
  transactionsCompleted: PropTypes.shape({
    data: PropTypes.arrayOf(TransactionShape),
    isLoading: PropTypes.bool,
    paging: PagingShape
  })
};

// Sign Up types

export const signUpReducerTypes = {
  isLoading: PropTypes.bool,
  isPayfone: PropTypes.bool,
  payfoneIsLoading: PropTypes.bool,
  referral: PropTypes.string,
  userId: PropTypes.string,
  walletId: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  mnoLink: PropTypes.string,
  step: PropTypes.string,
  phoneNumber: PropTypes.string,
  expiration: PropTypes.number,
  ssnDigits: PropTypes.string,
  lastPhoneDigits: PropTypes.string,
  maskedEmail: PropTypes.string,
  agreement: PropTypes.bool,
  businessName: PropTypes.string,
  name: PropTypes.shape({
    fname: PropTypes.string,
    lname: PropTypes.string
  }),
  homeAddress: PropTypes.shape({
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  popups: PropTypes.shape({
    popupIsLoading: PropTypes.bool,
    namePopupShown: PropTypes.bool,
    addressPopupShown: PropTypes.bool,
    businessNamePopupShown: PropTypes.bool
  }),
  isIndividual: PropTypes.bool,
  bankAccountRouting: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  bankAccountLabel: PropTypes.string,
  bankAccountType: BankAccountTypeEnum,
  bankAccountClass: BankAccountClassEnum,
  yodleeUrl: PropTypes.string,
  yodleeJWT: PropTypes.string,
  yodleeServiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  configName: PropTypes.string,
  isYodleeDisabled: PropTypes.bool,
  addedAccountsLength: PropTypes.number,
  isInitializing: PropTypes.bool,
  validationErrors: PropTypes.shape({
    serverError: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    phoneNumber: PropTypes.string,
    ssnDigits: PropTypes.string,
    name: PropTypes.string,
    homeAddress: PropTypes.string,
    bankAccountRouting: PropTypes.string,
    bankAccountNumber: PropTypes.string,
    bankAccountLabel: PropTypes.string,
    bankAccountType: PropTypes.string
  })
};

// RecurrentPayment 

export const recurrentPaymentReducerTypes = {
  isLoading: PropTypes.bool,
  paymentInProcess: PropTypes.bool,
  step: PropTypes.string,
  agreementToken: PropTypes.string,
  destWalletHandle: PropTypes.string,
  frequency: PropTypes.string,
  amount: PropTypes.string,
  currency: PropTypes.string,
  description: PropTypes.string,
  eventId: PropTypes.string,
  rate: RatesShape,
  selectedBankAccount: BankAccountShape,
};

// Send Money 

export const PaymentSourceShape = PropTypes.shape({
  id: PropTypes.string,
  type: AccountTypesEnum,
  amount: PropTypes.string,
  currency: CurrenciesEnum,
  isFC: PropTypes.bool
});

export const RewardsAmountShape = PropTypes.shape({
  ordinal: PropTypes.string,
  decimal:PropTypes.string,
  currency:PropTypes.string,
  value:PropTypes.string
});

export const RewardSourceShape = PropTypes.shape({
  reward_id: PropTypes.string,
  points_required: PropTypes.number,
  rewardAmountTransactionCurrency: PropTypes.string,
  name: PropTypes.string,
  amount: RewardsAmountShape, 
  available: PropTypes.bool,
  disabled: PropTypes.bool
});

export const BonusSourceShape = PropTypes.shape({
  selectedSource: RewardSourceShape,
  allSources: PropTypes.array
});


export const sendMoneyReducerTypes = {
  searchString: PropTypes.string,
  foundWallet: walletReducerTypes.wallet,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  paymentAmount: PropTypes.string,
  paymentCurrency: CurrenciesEnum,
  paymentSource: PropTypes.arrayOf(PaymentSourceShape),
  paymentBonusSource: BonusSourceShape,
  paymentNotes: PropTypes.string,
  transactionRate: RatesShape,
  wizardStep: PropTypes.string,
  transactionResult: TransactionShape,
  paymentProcessing: PropTypes.bool,
  eventId: PropTypes.string,
  isPayment: PropTypes.bool,
  destinationWalletHandle: PropTypes.string,
  orderToken: PropTypes.string,
  orderDescription: PropTypes.string,
  orderID: PropTypes.string,
  attributes: PropTypes.arrayOf(TransactionAttributeShape),
  rewardsUsed: PropTypes.bool,
  rewardsSource: RewardSourceShape,
  paymentSourceType: PropTypes.string,
  paymentBankAccount: PropTypes.shape({}),
  paymentAccountId: PropTypes.string,
  accAmount: PropTypes.any, // it's okay, it should be any
  banksAvailabilty: PropTypes.arrayOf(
    PropTypes.shape({
      bank_account_id: PropTypes.string,
      description:PropTypes.string,
      last4:PropTypes.string,
      mode: PropTypes.oneOf(['invalid', 'instant', 'wait']),
      clears: PropTypes.string,
      additional_info: PropTypes.string
    })
  ), 
  cardAvailability: PropTypes.arrayOf(
    PropTypes.shape({
      card_account_id: PropTypes.string,
      description: PropTypes.string,
      last4: PropTypes.string,
      mode: PropTypes.oneOf(['invalid', 'instant', 'wait']),
    })
  ),
  recentTransfers: PropTypes.arrayOf(
    PropTypes.shape({
      transaction_id: PropTypes.string,
      amount: RewardsAmountShape,
      created_at: PropTypes.string,
      bank_account: BankAccountShape,
    })
  ),
  preflightIsLoading: PropTypes.bool,
  isInvoice: PropTypes.bool,
  isCardErrorModalOpen: PropTypes.bool,
};

// Bank Account Flow types

export const bankAccountReducerTypes = {
  isLoading: PropTypes.bool,
  bankAccountRouting: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  bankAccountLabel: PropTypes.string,
  bankAccountType: BankAccountTypeEnum,
  bankAccountClass: BankAccountClassEnum,
  validationErrors: PropTypes.shape({
    bankAccountClass: PropTypes.string,
    bankAccountRouting: PropTypes.string,
    bankAccountNumber: PropTypes.string,
    bankAccountLabel: PropTypes.string,
    bankAccountType: PropTypes.string,
    bankAccountIsUniq: PropTypes.string
  }),
  yodleeUrl: PropTypes.string,
  yodleeJWT: PropTypes.string,
  yodleeServiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bankAccountCreated: PropTypes.bool,
  yodleeModalShown: PropTypes.bool,
  isYodleeDisabled: PropTypes.bool,
  configName: PropTypes.string,
  errorMessage: PropTypes.string
};

// Transfer types

export const TranferTypeEnum = PropTypes.oneOf(['Exchange', 'Transfer']);

export const TransferDropdownListShape = PropTypes.shape({
  accounts: PropTypes.arrayOf(AccountShape),
  bankAccounts: PropTypes.arrayOf(BankAccountShape),
});

export const transferReducerTypes = {
  data: PropTypes.shape({
    sourceAccount: PropTypes.oneOfType([AccountShape, BankAccountShape]),
    destinationAccount: PropTypes.oneOfType([AccountShape, BankAccountShape]),
    amount: PropTypes.string,
    amountCharge: PropTypes.string,
    chargeCurrency: PropTypes.string,
    amountReceive: PropTypes.string,
    receiveCurrency: PropTypes.string,
    transactionCurrency: PropTypes.string,
    errorMsg: PropTypes.string,
    dropdownOpened: PropTypes.string,
    dropdownList: TransferDropdownListShape,
    type: TranferTypeEnum
  }),
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  predefined: PropTypes.string,
  transactionRate: RatesShape,
  transactionResult: TransactionShape,
  flowStep: PropTypes.string
};

// Loan Offer

export const LoanOfferRateShape = PropTypes.shape({
  credit_id: PropTypes.string,
  rate_id: PropTypes.number,
  term: PropTypes.string,
  rate: PropTypes.string,
  monthly_payment: PropTypes.string,
  amount_financed: PropTypes.string,
  total_of_payments: PropTypes.string,
  financed_charge: PropTypes.string,
  description: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string
});

export const loanOfferReducerTypes = {
  currentOffer: PropTypes.shape({
    credit_id: PropTypes.string,
    user_id: PropTypes.string,
    status: PropTypes.string,
    min_amount: PropTypes.number,
    max_amount: PropTypes.number,
    accepted_at: PropTypes.string,
    accepted_rate_id: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    rates: PropTypes.arrayOf(LoanOfferRateShape)
  }),
  step: PropTypes.string,
  acceptedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // TODO not sure about type
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  isChecking: PropTypes.bool,
  waitingApproval: PropTypes.bool,
  approvedLoan: PropTypes.any, // TODO need to specify 
};

// Toasts and Notifications

export const NotificationShape = PropTypes.shape({
  code: PropTypes.number,
  id: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  ttl: PropTypes.number
});

export const CurrencyOptionShape = PropTypes.shape({
  code: PropTypes.string,
  currency_id: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.shape({
    active: PropTypes.bool,
    can_buy: PropTypes.bool,
    can_sell: PropTypes.bool,
    cannot_transfer_from: PropTypes.bool,
    cannot_transfer_to: PropTypes.bool,
    currency_id: PropTypes.string,
    currency_option_id: PropTypes.string,
    tenant_default: PropTypes.bool,
    tenant_id: PropTypes.string,
  }),
  symbols: PropTypes.arrayOf(PropTypes.string),
  types: PropTypes.arrayOf(PropTypes.string)
});

export const ApplicationOptionsShape = PropTypes.shape({
  currencies: PropTypes.arrayOf(CurrencyOptionShape),
  maintances: PropTypes.arrayOf(PropTypes.string), // ???
  transaction_options: PropTypes.shape({
    tenant_can_add_bank_account: PropTypes.bool,
    tenant_can_deposit_to_br: PropTypes.bool,
    tenant_can_order: PropTypes.bool,
    tenant_can_send_money: PropTypes.bool,
    tenant_can_transfer: PropTypes.bool,
    tenant_can_use_ach_for_transactions: PropTypes.bool,
    tenant_can_withdraw_from_br: PropTypes.bool
  })
});

export const ApplicationConfigurationShape = PropTypes.shape({
  tenant_can_add_bank_account: PropTypes.bool,
  tenant_can_deposit_to_br: PropTypes.bool,
  tenant_can_order: PropTypes.bool,
  tenant_can_send_money: PropTypes.bool,
  tenant_can_transfer: PropTypes.bool,
  tenant_can_use_ach_for_transactions: PropTypes.bool,
  tenant_can_withdraw_from_br: PropTypes.bool
});

export const EditingCardShape = PropTypes.shape({
  account_number: PropTypes.string,
  verification_code: PropTypes.string,
  expiration: PropTypes.string,
  holder_fname: PropTypes.string,
  holder_lname: PropTypes.string,
  zip_code: PropTypes.string,
  user_description: PropTypes.string,
  account_type: PropTypes.oneOf(['creditcard']),
  account_class: PropTypes.oneOf(['individual', 'business']),
});

export const CreditCardShape = PropTypes.shape({
  card_account_id: PropTypes.string,
  is_default_backup: PropTypes.bool,
  bank_name: PropTypes.string,
  user_description: PropTypes.string,
  account_number: PropTypes.string,
  expiration: PropTypes.string,
  account_type: PropTypes.oneOf(['creditcard']),
  account_class: PropTypes.oneOf(['individual', 'business']),
  verified: PropTypes.bool,
  allows: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  created_by: PropTypes.string,
  created_at: PropTypes.string,
});

export const InvoiceShape = PropTypes.shape({
  invoice_id: PropTypes.string,
  tenant_id: PropTypes.string,
  status: PropTypes.string,
  source_wallet_id: PropTypes.string,
  dest_wallet_id: PropTypes.string,
  description: PropTypes.string,
  recipient: PropTypes.string,
  send_to_email: PropTypes.string,
  amount: PropTypes.string,
  currency: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  deleted_at: PropTypes.string,
  expires_at: PropTypes.string,
  client_app_id: PropTypes.string,
  ticket: PropTypes.string,
  order_token: PropTypes.string
});