import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { contains } from 'ramda';
import { TransactionFiltersShape } from '../../constants/prop-types';
import Checkbox from '../../components/checkbox';
import Radio from '../../components/radio';
import CONST from '../../constants/transactions-constants';
import { CONSTANTS } from '../../constants/invoice-constants';


const InvoiceFilterFields = ({ filters, setFilterValue, timeFrameValue, setTimeFrameFilter, range, openCalendar }) => {
  const getCustomFilterLabel = () => {
    if (range && range.startDate && range.endDate) {
      return (<span className="color-blue">
        { moment(range.startDate).format('MMM D, YYYY') + ' — ' + moment(range.endDate).format('MMM D, YYYY') }
      </span>);
    }

    return 'Custom dates'; 
  };

  return (
    <div className="transactions-filter-items">
      <div className="transactions-filter-items_group">
        <div className="transactions-filter-items_group-header">
          <h4 className="transactions-filter-items_group-title">By Status</h4>
        </div>
        <div className="transactions-filter-items_group-items">
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label={CONSTANTS.INVOICE_STATUS_TEXT.completed}
              inputName="invoice-status-complete"
              className="-like-button"
              value={CONSTANTS.INVOICE_STATUS.completed}
              checked={contains(CONSTANTS.INVOICE_STATUS.completed, filters.status)}
              onChange={e => setFilterValue(e.target.value, 'status')}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label="Pending"
              inputName="invoice-status-pending"
              className="-like-button"
              value={CONSTANTS.INVOICE_STATUS.pending}
              checked={contains(CONSTANTS.INVOICE_STATUS.pending, filters.status)}
              onChange={e => setFilterValue(e.target.value, 'status')}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label={CONSTANTS.INVOICE_STATUS_TEXT.expired}
              inputName="invoice-status-expired"
              className="-like-button"
              value={CONSTANTS.INVOICE_STATUS.expired}
              checked={contains(CONSTANTS.INVOICE_STATUS.expired, filters.status)}
              onChange={e => setFilterValue(e.target.value, 'status')}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label={CONSTANTS.INVOICE_STATUS_TEXT.canceled}
              inputName="invoice-status-canceled"
              className="-like-button"
              value={CONSTANTS.INVOICE_STATUS.canceled}
              checked={contains(CONSTANTS.INVOICE_STATUS.canceled, filters.status)}
              onChange={e => setFilterValue(e.target.value, 'status')}
            />
          </div>
        </div>
      </div>
      <div className="transactions-filter-items_group">
        <div className="transactions-filter-items_group-header">
          <h4 className="transactions-filter-items_group-title">By Date</h4>
        </div>
        <div className="transactions-filter-items_group-items">
          <div className="transactions-filter-items_group-item">
            <Radio
              label="Today"
              inputName="transaction-date-today"
              className="-like-button"
              value={CONST.TIMEFRAME_INTERVALS.TODAY}
              checked={CONST.TIMEFRAME_INTERVALS.TODAY === timeFrameValue}
              onChange={e => setTimeFrameFilter(e.target.value)}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Radio
              label="This month"
              inputName="transaction-date-this-month"
              className="-like-button"
              value={CONST.TIMEFRAME_INTERVALS.THIS_MONTH}
              checked={CONST.TIMEFRAME_INTERVALS.THIS_MONTH === timeFrameValue}
              onChange={e => setTimeFrameFilter(e.target.value)}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Radio
              label="Last 90 days"
              inputName="transaction-date-last-30-days"
              className="-like-button"
              value={CONST.TIMEFRAME_INTERVALS.LAST_90}
              checked={CONST.TIMEFRAME_INTERVALS.LAST_90 === timeFrameValue}
              onChange={e => setTimeFrameFilter(e.target.value)}
            />
          </div>
          
          <div className="transactions-filter-items_group-item">
            <Radio
              label={getCustomFilterLabel()}
              inputName="transaction-date-any-time"
              className="-like-button"
              value={CONST.TIMEFRAME_INTERVALS.CUSTOM}
              checked={CONST.TIMEFRAME_INTERVALS.CUSTOM === timeFrameValue}
              onChange={openCalendar}
              onClick={openCalendar}
            />
          </div>
        </div>
      </div>
    </div>
  );};

InvoiceFilterFields.propTypes = {
  filters: TransactionFiltersShape.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  timeFrameValue: PropTypes.string.isRequired,
  setTimeFrameFilter: PropTypes.func.isRequired,
  range: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  openCalendar: PropTypes.func.isRequired,
};

export default InvoiceFilterFields;