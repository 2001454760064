import React, { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputMask from 'react-input-mask';

const InputMaskWithRef = forwardRef((props, ref) => (
  <InputMask ref={ref} {...props} />
));

const InputWithRef = forwardRef((props, ref) => (
  <input ref={ref} {...props} />
));

const FormInput = (props) => {
  const [filled, setFilled] = useState(false);
  const hasGivenRef = props.inputRef instanceof Object;
  const inputRef = hasGivenRef ? props.inputRef : useRef(null);

  useEffect(() => {
    setFilled(props.value);
  }, []);

  useEffect(() => {
    setFilled(!!props.value);
  }, [props.value]);


  const onChange = (e) => {
    const { onChange } = props;
    const { target: { value } } = e;

    setFilled(!!value);

    if (onChange) {
      e.persist();
      onChange(value, e);
    }
  };

  const onFocus = (e) => {
    const { onFocus } = props;
    if (onFocus) {
      e.persist();
      onFocus(e);
    }
  };

  const onBlur = (e) => {
    const { onBlur } = props;
    if (onBlur) {
      e.persist();
      onBlur(e);
    }
  };

  const onKeyDown = (e) => {
    e.persist();
    const { onPressEnter } = props;
    if (onPressEnter && e.keyCode === 13) {
      onPressEnter(e);
    }
    const { onKeyDown } = props;
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const {
    className,
    type,
    label,
    inputName,
    value,
    color,
    disabled,
    invalid,
    errorMessage,
    transparency,
    mask,
    alwaysRenderMaskInput,
    hideMask,
    suffix,
    ySize,
    autoFocus,
    additionalMessage,
    isErrorAbsolute,
    style,
    maxLength,
  } = props;
  
  return (
    <div className={
      cn(
        'form-input', {
          [`-${color}`]: !!color,
          [`-y-${ySize}`]: !!ySize,
          [`-transparency-${transparency}`]: !!transparency,
          '-is-invalid': invalid,
          '-has-suffix': suffix,
        },
        className,
      )
    }
    style={style}
    >
      { mask || alwaysRenderMaskInput ?
        <InputMaskWithRef
          ref={inputRef}
          className={cn('form-input_input', { '-filled': filled })}
          value={value}
          disabled={disabled}
          id={inputName}
          name={inputName}
          placeholder=" "
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          autoComplete="off"
          mask={mask}
          maskChar={!hideMask && '_'}
          autoFocus={autoFocus}
          maxLength={maxLength}
        />
        :
        <InputWithRef
          ref={inputRef}
          className={cn('form-input_input', { '-filled': filled })}
          value={value}
          disabled={disabled}
          id={inputName}
          name={inputName}
          placeholder=" "
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          autoComplete="off"
          autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
          maxLength={maxLength}
        />
      }
      <label htmlFor={inputName} className="form-input_label">{label}</label>
      {
        additionalMessage && !errorMessage &&
          <label htmlFor={inputName} className="form-input_message -notification">{additionalMessage}</label>
      }
      {
        errorMessage &&
          <label htmlFor={inputName} className={cn('form-input_message', '-error', isErrorAbsolute && '-absolute')}>{errorMessage}</label>
      }
      {
        suffix &&
          <div className="form-input_suffix">{suffix}</div>
      }
    </div>
  );
};

FormInput.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['white', 'light-blue']),
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputRef: PropTypes.shape({}),
  autoFocus: PropTypes.bool,
  mask: PropTypes.string,
  hideMask: PropTypes.bool,
  transparency: PropTypes.string,
  suffix: PropTypes.string,
  ySize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  style: PropTypes.shape(),
  alwaysRenderMaskInput: PropTypes.bool,
  additionalMessage: PropTypes.string,
  isErrorAbsolute: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  maxLength: PropTypes.number,
};

FormInput.defaultProps = {
  mask: null,
  hideMask: false,
  className: null,
  color: null,
  errorMessage: null,
  disabled: false,
  invalid: false,
  label: null,
  type: 'text',
  value: null,
  transparency: null,
  suffix: null,
  ySize: null,
  style: null,
};

export default FormInput;
