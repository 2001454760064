import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { walletReducerTypes } from '../constants/prop-types';
import BasicLayout from './basic-layout';
import Button from '../components/button';
import FormInput from '../components/form-input';
import Checkbox from '../components/checkbox';
import { addWalletUser, removeWalletUser, getWalletUsers } from '../actions/wallet-actions';
import Svg from '../components/svg';

const WalletPermissions = ({ wallet, addWalletUser, removeWalletUser, getWalletUsers, walletUsers, isLoading }) => {
  const [email, setEmail] = useState('');
  const [isTransact, setIsTransact] = useState(false);

  useEffect(() => {
    if(!walletUsers || walletUsers.length === 0) {
      getWalletUsers(wallet.wallet_id);
    }
  }, []);

  return (
    <BasicLayout wrapperClassName="wallet-permissions">
      <div className="page_header -with-sub">
        <h1>Wallet Permissions</h1>
        <p>It's technical page, but still you can use it.</p>
      </div>
      <div className="layer wallet-permissions__users">
        {walletUsers.filter(user => user.access !== '').map(user => (
          <div key={user.email} className="wallet-permissions__user">
            <div className="wallet-permissions__user-email">{user.send_to}</div>
            <div className="wallet-permissions__user-access">{user.access}</div>
            <Button
              color="transparent"
              xSize="xs"
              ySize="xs"
              className="wallet-permissions__user-remove-button"
              onClick={() => removeWalletUser(wallet.wallet_id, user.user_id)}
            >
              <Svg name="cross" className="wallet-permissions__user-remove-icon" />
            </Button>
          </div>
        ))}
      </div>
      <div className="layer wallet-permissions__form">
        <FormInput
          label="Add User"
          placeholder="Enter user email"
          onChange={value => setEmail(value)}
          value={email}
        />
        <Checkbox
          label="Transact"
          inputName="transact"
          className="-like-button"
          value="transact"
          checked={isTransact}
          onChange={() => setIsTransact(!isTransact)}
        />
        <Checkbox
          label="View"
          inputName="view"
          className="-like-button"
          value="view"
          checked={!isTransact}
          onChange={() => setIsTransact(!isTransact)}
        />
      </div>
      <div className="page_controls -align-top-desktop">
        <Button 
          color="blue"
          xSize="full"
          ySize="md"
          disabled={!email}
          loading={isLoading}
          onClick={() => addWalletUser(wallet.wallet_id, email, { access: isTransact ? 'transact' : 'view' })}
        >
            Add User
        </Button>
      </div>
    </BasicLayout>
  );
};

WalletPermissions.propTypes = {
  wallet: walletReducerTypes.isRequired,
  addWalletUser: PropTypes.func.isRequired,
  removeWalletUser: PropTypes.func.isRequired,
  getWalletUsers: PropTypes.func.isRequired,
  walletUsers: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    access: PropTypes.string.isRequired,
  })).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(state => ({
  wallet: state.wallet.wallet,
  walletUsers: state.wallet.walletUsers || [],
  isLoading: state.wallet.isLoading,
}), { addWalletUser, removeWalletUser, getWalletUsers })(WalletPermissions);
