export const CONSTANTS = {
  SET_FIELD: '@invoice/SET_FIELD',
  GET_INVOICE_DETAILS: '@invoice/GET_INVOICE_DETAILS',
  CREATE_INVOICE: '@invoice/CREATE_INVOICE',
  GET_COMPLETED_INVOICES: '@invoice/GET_COMPLETED_INVOICES',
  GET_PENDING_INVOICES: '@invoice/GET_PENDING_INVOICES',
  SET_VALIDATION_ERROR: '@invoice/SET_VALIDATION_ERROR',
  CLEAR_FILTERS: '@invoice/CLEAR_FILTERS',
  APPLY_FILTERS: '@invoice/APPLY_FILTERS',
  CANCEL_INVOICE: '@invoice/CANCEL_INVOICE',
  CREATE_NEW_INVOICE: '@invoice/CREATE_NEW_INVOICE',
  DELETE_INVOICE: '@invoice/DELETE_INVOICE',
  CLOSE_INVOICE_FORM: '@invoice/CLOSE_INVOICE_FORM',
  OPEN_INVOICE_FORM: '@invoice/OPEN_INVOICE_FORM',
  CLEAR_INVOICE_FORM: '@invoice/CLEAR_INVOICE_FORM',
  INVOICE_STATUS: {
    pending: 'pending',
    completed: 'completed',
    expired: 'expired',
    canceled: 'canceled',
    deleted: 'deleted',
  },

  INVOICE_STATUS_TEXT: {
    pending: 'Waiting for payment',
    completed: 'Paid',
    expired: 'Expired',
    canceled: 'Canceled',
    deleted: 'Deleted',
  },

  INVOICE_BADGE_STATUS: {
    pending: 'pending',
    completed: 'success',
    expired: 'danger',
    canceled: 'danger',
    deleted: 'danger',
  },

  INVOICE_BADGE_TEXT: {
    pending: 'Invoice is pending payment until ',
    completed: 'Invoice paid, ',
    expired: 'Invoice expired',
    canceled: 'Invoice canceled',
    deleted: 'Invoice deleted',
  },

  INVOICE_STATUS_COLOR: {
    pending: 'blue',
    completed: 'green',
    expired: 'red',
    canceled: 'red',
    deleted: 'red',
  },
};

export default CONSTANTS;
