import moment from 'moment';
import { path } from 'ramda';
import CONST from '../constants/invoice-constants';
import TRANSACTION_CONSTANTS from '../constants/transactions-constants';

const { TRANSACTION_STATUSES, INVOICE } = TRANSACTION_CONSTANTS;

export const getInvoiceName = (invoice) => {
  return invoice.recipient || invoice.send_to_sms || invoice.send_to_email || invoice.description || `Invoice ${invoice.invoice_id || ''}`;
};

export const getInvoiceStatus = (invoice) => {
  switch (invoice.status) {
    case 'pending':
      const dateFormat = moment().year() === moment(invoice.expires_at).year() ? 'MMMM, D' : 'MMMM, D, YYYY';
      const till = invoice.expires_at ? `till ${moment(invoice.expires_at).format(dateFormat)}` : '';
      return `${CONST.INVOICE_STATUS.pending} ${till}`;
    default:
      return `${CONST.INVOICE_STATUS_TEXT[invoice.status]}`;
  }
};

/**
 * Maps an invoice object to a transaction object.
 * @param {InvoiceShape} invoice - The invoice object to map.
 * @returns {TransactionShape} - The mapped transaction object.
 */
export const mapInvoiceToTransaction = (invoice) => {
  const total = Array.isArray(invoice.attributes) && path(['value'], invoice.attributes.find((attr = {}) => attr.name === 'total'));
  const [ordinal, decimal] = total ? total.split('.') : invoice.amount.split('.');

  return {
    transaction_id: invoice.invoice_id,
    type: INVOICE,
    status: invoice.status === CONST.INVOICE_STATUS.pending ? TRANSACTION_STATUSES.PENDING : TRANSACTION_STATUSES.COMPLETE,
    invoiceStatus: getInvoiceStatus(invoice),
    name: getInvoiceName(invoice),
    source: {
      wallet_id: invoice.source_wallet_id,
    },
    destination: {
      wallet_id: invoice.dest_wallet_id,
    },
    description: invoice.description,
    is_refundable: false,
    gross_amount: {
      currency: invoice.currency,
      ordinal: ordinal || '0',
      decimal: decimal || '0',
      sign: '+',
      usd_equivalent: `${parseFloat((total || invoice.amount), 10) * 100}`
    },
    net_amount: {
      currency: invoice.currency,
      ordinal: ordinal || '0',
      decimal: decimal || '0',
      sign: '+',
      usd_equivalent: `${parseFloat((total || invoice.amount), 10) * 100}`
    },
    fee_amount: {
      currency: invoice.currency,
      ordinal: '0',
      decimal: '0',
      sign: '+',
      usd_equivalent: `${parseFloat((total || invoice.amount), 10) * 100}`
    },
    exchange_rate: {
      currency: invoice.currency,
      rate: '',
      description: ''
    },
    initiated_by: invoice.recipient,
    initiated_at: invoice.created_at,
    updated_at: invoice.updated_at,
    attributes: invoice.attributes || [],
    transaction_details: {
      all: [],
    }
  };
};

export const getFieldsFromAttributes = (invoice) => {

  if (!invoice) {
    console.warn('invoice wasn\'t provided');
    return invoice;
  }

  let orderId = invoice.orderId;
  let tax = invoice.tax;
  let total = invoice.total;

  if (!Array.isArray(invoice.attributes) || invoice.attributes.length === 0) {
    return { orderId, tax, total };
  }

  const attributes = invoice.attributes.slice();

  const orderIdIndex = attributes.findIndex((attribute) => attribute.name === 'order_id');

  if (orderIdIndex !== -1) {
    orderId = attributes[orderIdIndex].value;
    attributes.splice(orderIdIndex, 1);
  } 

  const taxIndex = attributes.findIndex((attribute) => attribute.name === 'tax');

  if (taxIndex !== -1) {
    tax = attributes[taxIndex].value;
    attributes.splice(taxIndex, 1);
  }

  const totalIndex = attributes.findIndex((attribute) => attribute.name === 'total');

  if (totalIndex !== -1) {
    total = attributes[totalIndex].value;
    attributes.splice(totalIndex, 1);
  }

  const expires = moment(invoice.expires_at).diff(moment(invoice.created_at), 'days');

  return Object.assign(invoice, { orderId, tax, total, attributes, expires });
};

export const removeFieldsFromInvoice = (invoice) => {
  const fieldsToDelete = ['created_at', 'updated_at', 'expires_at', 'deleted_at', 'invoice_id', 'ticket', 'client_app_id', 'order_token', 'status'];

  fieldsToDelete.forEach((field) => {
    delete invoice[field];
  });

  return invoice;
};
