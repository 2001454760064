import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Header from '../../components/header/header';
import Svg from '../../components/svg';

const WalletSelectLayout = (props) => {
  const {
    children,
    isInitialScreen,
    isPaymentPage
  } = props;

  return (
    <div className={cn('page', { '-basic': isPaymentPage, '-dashboard': !isPaymentPage })}>
      <Header 
        coloredLogo={isPaymentPage}
        hideNav={isInitialScreen || isPaymentPage}
        hideUser={isInitialScreen} 
        className={cn({'-payment': isPaymentPage})}
        poweredByType={isPaymentPage ? 'full' : null}
      />
      {!isPaymentPage && (
        <div className="page_bg -sm">
          <div className="page_bg-wrapper">
            <Svg name="bg-waves-sm" className="page_bg-image" />
          </div>
        </div>
      )}
      <div className="page_wrapper -x-lg -close-to-header -page-body-max">
        <div className={cn('page_wrapper-inner', { '-small-header': isPaymentPage })}>
          {children}
        </div>
      </div>
    </div>
  );
};

WalletSelectLayout.propTypes = {
  children: PropTypes.node,
  isInitialScreen: PropTypes.bool,
  isPaymentPage: PropTypes.bool,
};

WalletSelectLayout.defaultProps = {
  children: null,
  isInitialScreen: false,
  isPaymentPage: false,
};

export default WalletSelectLayout;
