import { delay, eventChannel } from 'redux-saga';
import { pick, find, prop, compose, propEq } from 'ramda';
import { setField, setValidationError, agreementConsented } from '../actions/sign-up-actions';
import { takeLatest, put, take, fork, call, select } from 'redux-saga/effects';
import { setCurrentWallet } from '../actions/wallet-actions';
import { setAuthToken, clearAuthToken } from '../utils/api';
import { setField as setUserField } from '../actions/user-actions';
import CONST from '../constants/sign-up-constants';
import REQUESTS from '../utils/requests';
import VALIDATION from '../utils/validation';
import { history } from '../init-store';
import store from '../init-store';
import { addNotification } from '../actions/toast-actions';
import { formatMaskedEmail, formatErrorMessage } from '../utils/string-utils';
import { helpDeskUrl } from '../utils/api';
import { setField as setLoginField } from '../actions/login-actions';
import { setField as setWalletField } from '../actions/wallet-actions';
import qs from 'qs';
import { getOptions } from '../actions/app-actions';

const STATE_TYPES = {
  USER: 'user',
  FAIL: 'fail',
  EMPTY: '',
  TF_AUTH: 'tfa_auth',
  TF_LINK: 'tfa_link',
  TF_PENDING: 'tf_pending',
  TF_OTP: 'tfa_otp'
};

const loginUrl = 
  `<a class="notification-link">Log In</a>`;

function* pageEmitter(status, page) {
  yield put(setField('isLoading', false));

  if (status >= 400) {
    yield put(setField('step', page));
  }
}

function* handle400(errors, email, password) {
  const { message, detail } = Array.isArray(errors) && errors[0];
  if(message === 'user exists') {
    yield call(gotToLoginToastEmitter, email, password);
  } else if(detail === 'unauthorized domain') {
    yield put(addNotification(`Error: Unauthorized email domain`, 500, -1));
  } else {
    yield call(toastEmitter, 400, errors);
  }
}

function* gotToLoginToastEmitter(email, password) {
  yield put(addNotification(`Sorry, this email is in use.<br>
    Did you mean to ${loginUrl}`, 500, -1, () => {
    history.push('/login');
    store.dispatch(setLoginField('email', email));
    store.dispatch(setLoginField('password', password));
  }));
};

function* toastEmitter(status, errors = []) {
  yield put(setField('isLoading', false));

  if (status >= 500) {
    yield put(addNotification(`Something went wrong. Please try again or 
      contact ${helpDeskUrl} if the error persists.`, 500));
  };

  if ([401, 403, 408].indexOf(status) === -1 && status >= 400 && status < 500) {
    for(let i = 0; i < errors.length; i++) {
      yield put(addNotification(formatErrorMessage(errors[i])));
    }
  };
}

function* callOAuth(params) {
  try{
    yield put(setField('isLoading', true));
    yield REQUESTS.OAUTH_AUTHORIZE(params ? params : qs.parse(window.location.search, { ignoreQueryPrefix: true }));
    yield put(setField('isLoading', false));
  } catch(e) {
    yield put(setField('isLoading', true));
    throw e;
  }
}

function* startVerification() {
  try {
    yield put(setField('isLoading', true));

    const { userId } = yield select(state => state.signUp);
    const { data, success, errors } = yield REQUESTS.GET_VERIFICATION_SERVICES(userId, true);
    if(success) {
      yield put(setField('isLoading', false));
      yield put(setField('verificationServices', data[0].services));
      yield put(setField('step', CONST.STEP_VERIFICATION_SELECT));
    } else {
      console.error(errors);
      yield put(setField('isLoading', false));
    }
  } catch (err) {
    yield put(setField('isLoading', false));
  }
}

function* verificationSelectFlow() {
  const { chosenVerificationService } = yield select(state => state.signUp);

  switch(chosenVerificationService.form) {
    case CONST.SERVICE_SUPPORT:
      yield put(setField('step', CONST.STEP_SUPPORT_VERIFICATION));
      break;
    case CONST.SERVICE_LAST4:
      yield put(setField('step', CONST.STEP_SSN));
      break;
    case CONST.SERVICE_SINGULAR_KEY:
      yield getSingularKey();
      yield put(setField('step', CONST.STEP_SINGULAR_KEY));
      break;
    default: break;
  }
}

function* sendDocumentationEmailFlow() {
  try {
    yield put(setField('isLoading', true));

    const { user_id: userId } = yield select(state => state.user.user);
    const { success } = yield REQUESTS.SEND_DOCUMENTATION_EMAIL(userId);

    if (success) {
      yield put(setField('step', CONST.STEP_WALLET_TYPE));
      yield put(addNotification('Instructions has been sent to your email', 200));  
    } else {
      yield put(addNotification('Something went wrong', 400));  
    }

    yield put(setField('isLoading', false));
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* getSingularKey() {
  try {
    yield put(setField('isLoading', true));
    const { user_id: userId } = yield select(state => state.user.user);
    const singularKeyServiceId = yield select(state => compose(
      prop('id'),
      find(propEq('form', 'html'))
    )(state.signUp.verificationServices));

    const { data, success } = yield REQUESTS.GET_VERIFICATION_SERVICE_BY_ID(userId, singularKeyServiceId);

    if(success) {
      yield put(setField('singularKeyData', data));
    }
  } catch(e) {
    console.warn(e);
    yield put(setField('isLoading', false));
  }
}

function* singularKeyFlow() {
  try {
    yield put(setField('isLoading', true));
    yield put(setField('isAwaitForSingularKey', true));
    const { user_id: userId } = yield select(state => state.user.user);
    const singularKeyServiceId = yield select(state => compose(
      prop('id'),
      find(propEq('form', 'html'))
    )(state.signUp.verificationServices));
    const kycSessionId = yield select(state => state.signUp.singularKeyData[0].kyc_session_id);

    yield put(setField('kycSessionId', kycSessionId));
    yield singularKeyAwaiting(userId, singularKeyServiceId, kycSessionId);

    const { data: userData, success: userSuccess, status: userStatus, errors: userErrors } = yield REQUESTS.GET_USER_DETAILS(userId);
    const { success: walletsSucces, data: walletData, status: walletStatus, errors: walletErrors } = yield REQUESTS.GET_USER_WALLETS(userId);

    if (userSuccess && walletsSucces) {
      yield put(setField(
        'homeAddress',
        pick(['street1', 'street2', 'state', 'zip', 'city', 'verified'], userData[0].address || {}),
      ));

      yield put(setUserField(
        ['user', 'address'],
        pick(['street1', 'street2', 'state', 'zip', 'city', 'verified'], userData[0].address || {}),
      ));

      yield put(setField('name', { fname: userData[0].fname, lname: userData[0].lname }));
      yield put(setUserField(['user', 'fname'], userData[0].fname));
      yield put(setUserField(['user', 'lname'], userData[0].lname));

      if (walletData[0].type === 'business') {
        yield put(setField('isIndividual', false));
        yield put(setField('businessName', walletData[0].name));
      }

      if (walletData[0].type === 'individual') {
        yield put(setField('isIndividual', true));
      }

      yield put(setField('step', CONST.STEP_WALLET_TYPE));
    } else {
      if ([401, 403, 408].indexOf(walletStatus) === -1 && walletStatus >= 400 && walletStatus < 500)  {
        yield call(pageEmitter, walletStatus, CONST.STEP_ERROR_WENT_WRONG);
      } else {
        if ([401, 403, 408].indexOf(userStatus) === -1 && userStatus >= 400 && userStatus < 500)  {
          yield call(pageEmitter, userStatus, CONST.STEP_ERROR_WENT_WRONG);
        }
      }

      if (walletStatus >= 500) {
        yield call(toastEmitter, walletStatus, walletErrors);
      } else if (userStatus >= 500) {
        yield call(toastEmitter, userStatus, userErrors);
      }
    }

    yield put(setField('isAwaitForSingularKey', false));
  } catch (e) {
    console.error(e);
    yield put(setField('isLoading', false));
  }
}

function* singularKeyAwaiting(userId, singularKeyServiceId, kycSessionId) {
  const { data, success } = yield REQUESTS.GET_KYC_SESSION_STATUS(userId, singularKeyServiceId, kycSessionId);

  if(success && data[0].status === 'pending') {
    yield call(delay, CALL_DELAY);
    return yield singularKeyAwaiting(userId, singularKeyServiceId, kycSessionId);
  } else {
    return { data, success };
  }
}

function* checkActionItem() {
  const { userId } = yield select(state => state.signUp);
  const { data, success, status, errors } = yield REQUESTS.GET_USER_DETAILS(userId);

  if (success) {
    const { phone_verified, identity_verified } = data[0];

    if (!identity_verified) {
      yield call(eligibilityCheck, data);
    } else {
      yield put(setUserField('user', { user_id: userId, phone_verified, identity_verified }));
      yield put(setField('step', CONST.STEP_PHONE_NUMBER_VERIFIED));
      yield put(setField('payfoneIsLoading', false));
    }
  } else {
    if ([401, 403, 408].indexOf(status) === -1 && status >= 400 && status < 500)  {
      yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));
      yield put(setField('payfoneIsLoading', false));
    }

    if (status >= 500) {
      yield call(toastEmitter, status, errors);
      yield put(setField('payfoneIsLoading', false));
    }
  }
}

function* eligibilityCheck(userData) {
  const { userId } = yield select(state => state.signUp);
  const { data, success, errors, status } = yield REQUESTS.GET_VERIFICATION_SERVICES(userId);
  const { success: walletsSucces, data: walletData, status: walletsStatus } = yield REQUESTS.GET_USER_WALLETS(userId);

  yield put(setField(
    'homeAddress',
    pick(['street1', 'street2', 'state', 'zip', 'city', 'verified'], userData[0].address || {}),
  ));

  yield put(setUserField(
    ['user', 'address'],
    pick(['street1', 'street2', 'state', 'zip', 'city', 'verified'], userData[0].address || {}),
  ));

  yield put(setField('name', { fname: userData[0].fname, lname: userData[0].lname }));
  yield put(setUserField(['user', 'fname'], userData[0].fname));
  yield put(setUserField(['user', 'lname'], userData[0].lname));
  
  if (walletsSucces) {
    if (walletData[0].type === 'business') {
      yield put(setField('isIndividual', false));
      yield put(setField('businessName', walletData[0].name));
    }

    if (walletData[0].type === 'individual') {
      yield put(setField('isIndividual', true));
    }

    yield put(setCurrentWallet(walletData[0]));
  } else {
    if ([401, 403, 408].indexOf(walletsStatus) === -1 && walletsStatus >= 400 && walletsStatus < 500)  {
      yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));
      yield put(setField('payfoneIsLoading', false));

      return;
    }
  }

  // TODO there is a question about how actual isPayfone flag for new flow 
  // As I understand it should be changed to another flag which indicates does we have enough data to verify user(?)

  if (success) {
    if(data && Array.isArray(data[0].services) && data[0].services.length > 0)  {
      yield put(setField('verificationServices', data[0].services));
      yield put(setField('isPayfone', true)); // ??
      yield put(setField('step', CONST.STEP_VERIFICATION_SELECT));
      yield put(setField('payfoneIsLoading', false)); // ??
    } else {
      yield put(setField('verificationServices', []));
      yield put(setField('isPayfone', false)); // ??
      yield put(setField('step', CONST.STEP_WALLET_TYPE));
      yield put(setField('payfoneIsLoading', false)); // ??
    }
  } else {
    if ([401, 403, 408].indexOf(status) === -1 && status >= 400 && status < 500)  {
      yield put(setField('isPayfone', false)); // ??
      yield put(setField('verificationServices', []));
      yield put(setField('step', CONST.STEP_WALLET_TYPE));
      yield put(setField('payfoneIsLoading', false)); // ??

    }

    if (status >= 500) {
      yield call(toastEmitter, status, errors);
      yield put(setField('payfoneIsLoading', false)); // ??
    }
  }
}

function* detectFlow() {
  const { userId } = yield select(state => state.signUp);
  const { data: userData, success: userSuccess } = yield REQUESTS.GET_USER_DETAILS(userId);

  if (userSuccess) {
    const { data, success: checkSuccess } = yield REQUESTS.CHECK_SOFT_LINK(userData[0].phone);

    if (checkSuccess) {
      if (data[0].state === STATE_TYPES.USER) {
        yield put(setField('isLoading', false));
        yield call(checkActionItem);

        return;
      }

      if (!data[0].state || data[0].state === STATE_TYPES.EMPTY) {
        yield put(setField('step', CONST.STEP_ERROR_TRY_AGAIN));

        return;
      }

      if (data[0].state === STATE_TYPES.FAIL) {
        yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));

        return;
      }

      if (data[0].state === STATE_TYPES.TF_LINK) {
        yield put(setField('isLoading', false));
        yield put(setField('step', CONST.STEP_PHONE_NUMBER));
      }
    }
  }
}

function* createAndDeleteIframeListener(channel) {
  while (true) {
    yield take(channel);
    const { data, success: checkSuccess } = yield REQUESTS.CHECK_SOFT_LINK();

    if (checkSuccess) {
      const state = data && data[0] && data[0].state;
      
      if (!data || !data[0]) {
        yield put(setField('step', CONST.STEP_ERROR_TRY_AGAIN));
        yield put(setField('payfoneIsLoading', false));

        return;
      }

      if (state === STATE_TYPES.FAIL) {
        yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));
        yield put(setField('payfoneIsLoading', false));

        return;
      }

      if (state === STATE_TYPES.USER) {
        yield put(setField('isLoading', false));

        yield call(checkActionItem);

        return;
      }

      if (state === STATE_TYPES.TF_LINK || state === STATE_TYPES.TF_AUTH || state === STATE_TYPES.EMPTY) {
        yield put(setField('isLoading', false));
        yield put(setField('step', CONST.STEP_PHONE_NUMBER));
        yield put(setField('payfoneIsLoading', false));

        return;
      }
    } else {
      yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));
      yield put(setField('payfoneIsLoading', false));
    }
  }
}

const CALL_DELAY = 3000;

function* consentAgreement() {
  const { userId, agreement } = yield select(state => state.signUp);
  yield put(setField('isLoading', true));

  if(agreement) {
    const { 
      success: agreementSuccess,
      status: agreementStatus, errors: agreementErrors
    } = yield REQUESTS.CONSENT_AGREEMENT(userId, 'user-agreement');
    
    if(agreementSuccess) {
      yield put(setField('agreementConfirmed', true));
  
    } else {
      if ([401, 403, 408].indexOf(agreementStatus) === -1 && agreementStatus >= 400 && agreementStatus < 500)  {
        yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));
      }
    
      if (agreementStatus >= 500) {
        yield call(toastEmitter, agreementStatus, agreementErrors);
      }
    }
  }

  yield put(agreementConsented());
}

function* emailPasswordFlow() {
  try {
    const { email, password, referral, isOAuth, oauthParams } = yield select(state => state.signUp);
    const emailValidation = VALIDATION.validateEmail(email);
    const passwordValidation = VALIDATION.validatePassword(password, email);
    const avi = oauthParams && oauthParams.avi;
    const OBTAIN_TOKEN_REQUEST = isOAuth ? REQUESTS.OBTAIN_TOKEN_OAUTH : REQUESTS.OBTAIN_TOKEN;
    const OBTAIN_APP_TOKEN_REQUEST = isOAuth ? async () => await { success: true } : REQUESTS.OBTAIN_APP_TOKEN;
    const code_verifier = localStorage.getItem('code_verifier');
    clearAuthToken();

    localStorage.setItem('oauthParams', window.location.search);
    localStorage.setItem('code_verifier', code_verifier);

    yield put(setValidationError('serverError', null));

    if (emailValidation.success && passwordValidation.success) {
      const {
        success: appSuccess,
        data: appData,
        status: appStatus,
        errors: appErrors,
      } = yield OBTAIN_APP_TOKEN_REQUEST();


      if (appSuccess) {
        const appToken = (Array.isArray(appData) && appData.length > 0 && appData[0].access_token) || avi;
        setAuthToken(appToken);
        const aviObj = isOAuth && oauthParams && { avi: oauthParams.avi };

        const {
          success: userCreated,
          data,
          status,
          errors: userErrors,
        } = yield REQUESTS.CREATE_USER({ email, referral, password, ...aviObj });


        if (userCreated || status === 200) {
          if(oauthParams && oauthParams.consents && oauthParams.consents.length > 0) {
            oauthParams.consents.split(',').forEach((consent) => {
              oauthParams[`consent:${consent}`] = 1;
            });
          }

          const {
            success: gotUserToken,
            data: userData,
            status: tokenStatus,
            errors: tokenErrors,
          } = yield OBTAIN_TOKEN_REQUEST({ username: email, password, ...oauthParams });

          if (gotUserToken && !isOAuth) {
            const userToken = userData[0].access_token;
            const { state, required_consents } = userData[0];
            const { phone_verified, identity_verified } = data[0];

            yield put(setUserField('user', { user_id: data[0].user_id, phone_verified, identity_verified }));
            yield put(setField('userId', data[0].user_id));

            setAuthToken(userToken);

            const { 
              success: softLinkSucces,
              errors: softLinkErrors,
              status: softLinkStatus
            } = yield REQUESTS.SOFT_LINK_AUTH(data[0].phone);

            if(Array.isArray(required_consents) && required_consents.includes('user-agreement')) {
              if(softLinkSucces) {
                yield call(consentAgreement);
              } else {
                yield call(toastEmitter, softLinkStatus, softLinkErrors);
              }
            } else {
              yield put(setField('agreement', true));
              yield put(setField('agreementConfirmed', true));
            }

            const { agreementConfirmed } = yield select(state => state.signUp);

            if(agreementConfirmed) {
              yield put(getOptions());
              switch (state) {
                case STATE_TYPES.USER:
                  history.push('/dashboard');
                  break;

                case STATE_TYPES.TF_LINK: {
                  if (phone_verified) {
                    yield call(authLinkFlow);
                  } else {
                    yield put(setField('step', CONST.STEP_PHONE_NUMBER));
                  }
                  break;
                }

                case STATE_TYPES.TF_AUTH: {
                  yield put(setField('isPayfone', true));
                  yield put(setField('mnoLink', userData[0].tfa_auth));
                  yield put(setField('step', CONST.STEP_PAYPHONE_AUTODETECT));
                  break;
                }
                case STATE_TYPES.FAIL:
                case STATE_TYPES.EMPTY:
                  yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));
                  break;

                case STATE_TYPES.TF_PENDING:
                default: break;
              }
            } else {
              yield put(setField('isLoading', false));
              yield put(setField('step', CONST.STEP_EMAIL_PASSWORD));
            }
          } else if(isOAuth) {
            yield REQUESTS.OAUTH_AUTHORIZE({ ...oauthParams, ...userData });
          } else {
            yield call(toastEmitter, tokenStatus, tokenErrors);
          }
        } else {
          if (status === 400) {
            yield call(handle400, userErrors, email, password);
          } else {
            yield call(toastEmitter, status, userErrors);
          }
        }
      } else {
        yield call(toastEmitter, appStatus, appErrors);
      }
    } else {
      if (!emailValidation.success) {
        yield put(setValidationError('email', emailValidation.message));
      }

      if (!passwordValidation.success) {
        yield put(setValidationError('password', passwordValidation.message));
      }
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* checkForAuthLink(numCalls) {
  try {
    const { step } = yield select(state => state.signUp);


    if (step === CONST.STEP_LINK_SENT || step === CONST.STEP_PAYPHONE_AUTODETECT) {
      yield call(delay, CALL_DELAY);

      const { data, success, status } = yield REQUESTS.CHECK_SOFT_LINK();

      if (success) {
        const { state } = data[0];

        if (!state || state === STATE_TYPES.EMPTY) {
          yield put(setField('step', CONST.STEP_ERROR_TRY_AGAIN));

          return;
        }

        if (state === STATE_TYPES.FAIL) {
          yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));

          return;
        }

        if (state === STATE_TYPES.USER) {
          yield put(setField('userId', data[0].user_id));

          yield call(checkActionItem);

          return;
        }

        yield call(checkForAuthLink, numCalls + 1);
      } else {
        yield call(pageEmitter, status, CONST.STEP_ERROR_WENT_WRONG);
      }
    }
  } catch (e) {
    throw e;
  }
}

function* authLinkActionFlow() {
  yield call(authLinkFlow);
}

function* authLinkFlow(phoneNumber) {
  const { success, data, status, errors } = yield REQUESTS.SOFT_LINK_AUTH(phoneNumber);

  if (success) {
    if (data[0].state && data[0].state === STATE_TYPES.USER) {
      yield call(checkActionItem);
      return;
    }

    if (!data[0].state || data[0].state === STATE_TYPES.FAIL || data[0].state === STATE_TYPES.EMPTY) {
      yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));
      return;
    }

    yield put(setField('lastPhoneDigits', data[0].tfa_phone));
    yield put(setField('maskedEmail', formatMaskedEmail(data[0].tfa_email)));
    yield put(setField('expiration', data[0].tfa_expires));
    yield put(setField('step', CONST.STEP_LINK_SENT));
    yield call(checkForAuthLink, 0);
  } else {
    if ([401, 403, 408].indexOf(status) === -1 && status >= 400 && status < 500)  {
      yield put(setField('step', CONST.STEP_VERIFY_EMAIL));
    }

    if (status >= 500) {
      yield call(toastEmitter, status, errors);
    }
  }
}

function* phoneNumberFlow() {
  try {
    const { phoneNumber } = yield select(state => state.signUp);

    if (phoneNumber !== null) {
      const phoneValidation = VALIDATION.validatePhoneNumber(phoneNumber);

      if (phoneValidation.success) {
        yield call(authLinkFlow, phoneNumber);
      } else {
        yield put(setValidationError('phoneNumber', phoneValidation.message));
      }
    } else {
      yield call(authLinkFlow);
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* ssnFlow() {
  try {
    const { userId, ssnDigits } = yield select(state => state.signUp);
    const ssnValidation = VALIDATION.validateSSN(ssnDigits);

    if (ssnValidation.success) {
      const { success, status, errors } = yield REQUESTS.VERIFY_NUMBER_SSN({ userId, ssn: ssnDigits });

      if (success) {
        const { data: userData, success: userSuccess, status: userStatus } = yield REQUESTS.GET_USER_DETAILS(userId);
        const { success: walletsSucces, data: walletData, status: walletStatus } = yield REQUESTS.GET_USER_WALLETS(userId);

        if (userSuccess && walletsSucces) {
          yield put(setField('isPayfone', true));
          yield put(setField(
            'homeAddress',
            pick(['street1', 'street2', 'state', 'zip', 'city', 'verified'], userData[0].address || {}),
          ));

          yield put(setUserField(
            ['user', 'address'],
            pick(['street1', 'street2', 'state', 'zip', 'city', 'verified'], userData[0].address || {}),
          ));

          yield put(setField('name', { fname: userData[0].fname, lname: userData[0].lname }));
          yield put(setUserField(['user', 'fname'], userData[0].fname));
          yield put(setUserField(['user', 'lname'], userData[0].lname));

          if (walletData[0].type === 'business') {
            yield put(setField('isIndividual', false));
            yield put(setField('businessName', walletData[0].name));
          }

          if (walletData[0].type === 'individual') {
            yield put(setField('isIndividual', true));
          }

          yield put(setField('step', CONST.STEP_WALLET_TYPE));
        } else {
          if ([401, 403, 408].indexOf(walletStatus) === -1 && walletStatus >= 400 && walletStatus < 500)  {
            yield call(pageEmitter, walletStatus, CONST.STEP_ERROR_WENT_WRONG);
          } else {
            if ([401, 403, 408].indexOf(userStatus) === -1 && userStatus >= 400 && userStatus < 500)  {
              yield call(pageEmitter, userStatus, CONST.STEP_ERROR_WENT_WRONG);
            }
          }
        }
      } else {
        if ([401, 403, 408].indexOf(status) === -1 && status >= 400 && status < 500)  {
          const { data: userData, success: userSuccess, status: userStatus } = yield REQUESTS.GET_USER_DETAILS(userId);
          const { success: walletsSuccess, data: walletData, status: walletStatus } = yield REQUESTS.GET_USER_WALLETS(userId);

          if (userSuccess && walletsSuccess) {
            yield put(setField('isPayfone', false));
            yield put(setField(
              'homeAddress',
              pick(['street1', 'street2', 'state', 'zip', 'city', 'verified'], userData[0].address || {}),
            ));

            yield put(setUserField(
              ['user', 'address'],
              pick(['street1', 'street2', 'state', 'zip', 'city', 'verified'], userData[0].address || {}),
            ));

            yield put(setField('name', { fname: userData[0].fname, lname: userData[0].lname }));
            yield put(setUserField(['user', 'fname'], userData[0].fname));
            yield put(setUserField(['user', 'lname'], userData[0].lname));

            if (walletData[0].type === 'business') {
              yield put(setField('isIndividual', false));
              yield put(setField('businessName', walletData[0].name));
            }

            if (walletData[0].type === 'individual') {
              yield put(setField('isIndividual', true));
            }

            yield put(setField('step', CONST.STEP_WALLET_TYPE));
          } else {
            if ([401, 403, 408].indexOf(userStatus) === -1 && userStatus >= 400 && userStatus < 500)  {
              yield call(pageEmitter, userStatus, CONST.STEP_ERROR_WENT_WRONG);
            } else {
              if ([401, 403, 408].indexOf(walletStatus) === -1 && walletStatus >= 400 && walletStatus < 500)  {
                yield call(pageEmitter, walletStatus, CONST.STEP_ERROR_WENT_WRONG);
              }
            }
          }
        }

        if (status >= 500) {
          yield call(toastEmitter, status, errors);
        }
      }
    } else if (!ssnValidation.success) {
      yield put(setValidationError('ssnDigits', ssnValidation.message));
    }
  } catch (error) {
    yield put(setField('step', CONST.STEP_ERROR_WENT_WRONG));
  }
}

function* walletTypeFlow() {
  try {
    const {
      userId,
      isPayfone,
      isIndividual,
      businessName,
      ein,
      isOAuth,
      homeAddress: {
        street1,
        street2,
        city,
        state,
        zip,
      },
      name: {
        fname,
        lname,
      }
    } = yield select(store => store.signUp);

    const nameValidation = VALIDATION.validateNotEmpty('Name', { fname, lname });
    const addressValidation = isPayfone
      ? { success: true }
      : VALIDATION.validateNotEmpty('Address', { street1, street2, city, state, zip });

    if (nameValidation.success) {
      if (addressValidation.success) {
        const { success, data, status } = yield REQUESTS.GET_USER_WALLETS(userId);
        const { success: yodleSuccess, data: yodleeData } = yield REQUESTS.OBTAIN_BANK_TOKEN(userId);

        if (success && yodleSuccess) {
          const walletId = data[0].wallet_id;

          yield put(setField('walletId', walletId));
          yield put(setWalletField('wallet', { wallet_id: walletId }));

          yield put(setField('yodleeUrl', yodleeData[0].create_url));
          yield put(setField('yodleeJWT', yodleeData[0].credentials.jwt));
          yield put(setField('yodleeServiceId', yodleeData[0].service_id));
          yield put(setField('configName', data[0].config_name));

          const walletParams = {};

          if (isIndividual) {
            walletParams.type = 'individual';
          } else {
            walletParams.type = 'business';
            walletParams.name = businessName;
            walletParams.ein = ein;
          }

          const walletResponse = yield REQUESTS.UPDATE_WALLET(walletId, walletParams);

          if (walletResponse.success) {
            const userResponse = yield REQUESTS.UPDATE_USER(userId, {
              address: {
                street1,
                street2,
                city,
                state,
                zip,
              },
              fname, lname,
            });

            if (userResponse.success) {
              yield put(setField('homeAddress', {
                street1,
                street2,
                city,
                state,
                zip,
              }));

              yield put(setField('name', {
                fname, lname,
              }));

              if(isOAuth) {
                yield put(setField('step', CONST.STEP_WALLET_SUCCESS));
              } else if (isPayfone) {
                const { success: yodleSuccess, data: yodleeData } = yield REQUESTS.OBTAIN_BANK_TOKEN(userId);

                if (yodleSuccess) {
                  yield put(setField('yodleeUrl', yodleeData[0].create_url));
                  yield put(setField('yodleeJWT', yodleeData[0].credentials.jwt));
                  yield put(setField('yodleeServiceId', yodleeData[0].service_id));
                  yield put(setField('configName', yodleeData[0].config_name));
                }

                yield put(setField('step', CONST.STEP_SIGNUP_SUCCESS));
              } else {
                yield put(setField('step', CONST.STEP_ADDITIONAL_VERIFICATION_HELPDESK));
              }
            } else {
              yield call(toastEmitter, userResponse.status, userResponse.errors);
            }
          } else {
            yield call(toastEmitter, walletResponse.status, walletResponse.errors);
          }
        } else {
          yield call(pageEmitter, status, CONST.STEP_ERROR_WENT_WRONG);
        }
      } else {
        yield put(setValidationError('homeAddress', addressValidation.message));
      }
    } else {
      yield put(setValidationError('name', nameValidation.message));
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* yodleeFlow() {
  try {
    yield callOAuth();
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* addBankAccountFlow() {
  try {
    const {
      walletId,
      bankAccountRouting,
      bankAccountNumber,
      bankAccountLabel,
      bankAccountType,
      bankAccountClass,
    } = yield select(state => state.signUp);
    const bankAccountRoutingValidation = VALIDATION.validateAccountRouting(bankAccountRouting);
    const bankAccountNumberValidation = VALIDATION.validateAccountNumber(bankAccountNumber);
    const bankAccountLabelValidation = VALIDATION.validateAccountLabel(bankAccountLabel);
    const bankAccountTypeValidation = VALIDATION.validateAccountType(bankAccountType);

    if (bankAccountRoutingValidation.success
      && bankAccountNumberValidation.success
      && bankAccountLabelValidation.success
      && bankAccountTypeValidation.success
    ) {
      // here will be checking bank account
      const { success, status, errors } = yield REQUESTS.ADD_BANK_ACCOUNT(walletId, {
        account_type: bankAccountType,
        routing_number: bankAccountRouting,
        account_number: bankAccountNumber,
        user_description: bankAccountLabel,
        account_class: bankAccountClass,
      });

      if (success) {
        yield put(setField('step', CONST.STEP_COMPLETE));
      } else {
        yield call(toastEmitter, status, errors);
      }
    } else {
      if (!bankAccountRoutingValidation.success) {
        yield put(setValidationError('bankAccountRouting', bankAccountRoutingValidation.message));
      }

      if (!bankAccountNumberValidation.success) {
        yield put(setValidationError('bankAccountNumber', bankAccountNumberValidation.message));
      }

      if (!bankAccountLabelValidation.success) {
        yield put(setValidationError('bankAccountLabel', bankAccountLabelValidation.message));
      }

      if (!bankAccountTypeValidation.success) {
        yield put(setValidationError('bankAccountType', bankAccountTypeValidation.message));
      }
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* successFlow() {
  yield put(setField('step', CONST.STEP_BANK_ACCOUNT));
}

function* oauthPhoneNumberFlow(isSkip) {
  try {
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if(isSkip) {
      queryParams.mobile = '';

      yield callOAuth(queryParams);
    } else {
      const { phoneNumber } = yield select(state => state.signUp);
      const phoneValidation = VALIDATION.validatePhoneNumber(phoneNumber);

      if (phoneValidation.success) {
        queryParams.mobile = phoneNumber;
        yield callOAuth(queryParams);
      } else {
        yield put(setValidationError('phoneNumber', phoneValidation.message));
      }
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* oauthScopesFlow() {
  try {
    const { oauthScopes } = yield select(state => state.signUp);
    
    yield call(callOAuth, oauthScopes);
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* returnToPhoneStepFlow() {
  try{
    yield put(setField('isLoading', true));
    yield REQUESTS.OAUTH_AUTHORIZE({ addphone: 'show', ...qs.parse(window.location.search, { ignoreQueryPrefix: true })});
    yield put(setField('isLoading', false));
  } catch(e) {
    yield put(setField('isLoading', true));
    throw e;
  }
}

function* signUpStepFlow(action) {
  try {
    yield put(setField('isLoading', true));

    switch (action.payload.step) {
      case CONST.STEP_EMAIL_PASSWORD: yield call(emailPasswordFlow); break;
      case CONST.STEP_PHONE_NUMBER: yield call(phoneNumberFlow); break;
      case CONST.STEP_PHONE_NUMBER_SKIP: yield call(startVerification); break;
      case CONST.STEP_VERIFICATION_SELECT: yield call(verificationSelectFlow); break;
      case CONST.STEP_SSN: yield call(ssnFlow); break;
      case CONST.STEP_WALLET_TYPE: yield call(walletTypeFlow); break;
      case CONST.STEP_WALLET_SUCCESS: yield call(yodleeFlow); break;
      case CONST.STEP_BANK_ACCOUNT: yield call(addBankAccountFlow); break;
      case CONST.STEP_PAYPHONE_AUTODETECT: yield call(detectFlow); break;
      case CONST.STEP_SIGNUP_SUCCESS: yield call(successFlow); break;
      case CONST.STEP_SINGULAR_KEY: yield call(singularKeyFlow); break;
      case CONST.STEP_OAUTH_PHONE_NUMBER: yield call(oauthPhoneNumberFlow); break;
      case CONST.STEP_OAUTH_PHONE_NUMBER_SKIP: yield call(oauthPhoneNumberFlow, true); break;
      case CONST.STEP_OAUTH_SCOPES: yield call(oauthScopesFlow, true); break;
      case CONST.STEP_COMPLETE: break;
        // eslint-disable-next-line no-console
      default: console.log(action.payload.step);
    }

    yield put(setField('isLoading', false));
  } catch (error) {
    yield put(setField('isLoading', false));
    throw error;
  }
}

function popupEmitter(mnoLink) {
  return eventChannel((emit) => {
    const url = `http://${window.location.host}/payfone?url=${btoa(mnoLink)}`;
    const payfoneWindow = window.open(url, 'Payfone','height=667,width=375');
    let timeout = null;
    let timer = null;

    timeout = setTimeout(function() {
      clearInterval(timer);
      clearTimeout(timeout);
      emit('close');
    }, process.env.REACT_APP_LOGIN_TIMEOUT);

    timer = setInterval(function() {
      if(payfoneWindow.closed) {
        clearInterval(timer);
        clearTimeout(timeout);
        emit('close');
      }
    }, 500);

    return () => {};
  });
}

function* setFieldInterceptor(action) {
  if (action.payload.field === 'step') {
    yield put(setField('payfoneIsLoading', false));
  }
}

function* openPayfonePopupFlow() {
  yield put(setField('payfoneIsLoading', true));
  const { mnoLink } = yield select(state => state.signUp);
  const emitter = popupEmitter(mnoLink);
  
  yield fork(createAndDeleteIframeListener, emitter);
}

function* signUpSaga() {
  yield takeLatest(CONST.END_STEP, signUpStepFlow);
  yield takeLatest(CONST.AUTH_LINK_FLOW, authLinkActionFlow);
  yield takeLatest(CONST.SET_FIELD, setFieldInterceptor);
  yield takeLatest(CONST.OPEN_POPUP, openPayfonePopupFlow);
  yield takeLatest(CONST.CONSENT_AGREEMENT, consentAgreement);
  yield takeLatest(CONST.SUBMIT_DOCUMENTATION, sendDocumentationEmailFlow);
  yield takeLatest(CONST.GET_SINGULAR_KEY, getSingularKey);
  yield takeLatest(CONST.START_VERIFICATION, startVerification);
  yield takeLatest(CONST.RETURN_TO_PHONE_STEP, returnToPhoneStepFlow);
}

export default [signUpSaga];
