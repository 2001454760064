import React from 'react';
import { TransactionShape, walletReducerTypes } from '../constants/prop-types';
import cn from 'classnames';
import { path, filter, prop } from 'ramda';

import Svg from './svg';
import UserCard from './user-card';
import Avatar from './avatar';

import {
  getTransactionType,
  findAccountBySign,
  getWalletToShow,
  getIsIncoming,
  findSuspended,
  findRejected,
  getAccountName
} from '../utils/transaction-utils';
import { withCurrency } from '../utils/send-money-utils';

import CONST from '../constants/transactions-constants';

const TransactionResult = (props) => {
  const { transaction, accounts } = props;
  const sourceAccount = findAccountBySign(transaction, CONST.MINUS) || {};
  const targetAccount = findAccountBySign(transaction, CONST.PLUS) || {};
  const isExchange = getTransactionType(props.transaction) === CONST.EXCHANGE 
    || (prop('account_type', sourceAccount) === 'account' && prop('account_type', targetAccount) === 'account');
  const getAvatarType = () => {
    const { transaction } = props;
    const suspended = findSuspended(transaction);
    const rejected = findRejected(transaction);
    if (suspended) {
      return 'alertCircle';
    } else if (rejected) {
      return 'rejectedCircle';
    }
    return 'brokenCircle';
  };

  const getExchangeParticipants = () => {
    const amount = path(['account_amount', 'usd_equivalent'], sourceAccount) || path(['account_amount', 'usd_equivalent'], targetAccount);
    const transactionAmount = Math.abs(amount) / 100;

    sourceAccount.name = getAccountName(sourceAccount, accounts);
    targetAccount.name = getAccountName(targetAccount, accounts);

    const sender = (
      <UserCard
        avatarType={getAvatarType()}
        contentMain={withCurrency(transactionAmount, 'USD')}
        contentSecondary={`${prop('name', sourceAccount)} Account`}
      />
    );

    const receiver = (
      <UserCard
        avatar={
          <Avatar className="-status">
            <Svg name="wallet" className="avatar_status-icon" />
          </Avatar>
        }
        contentMain={`${prop('name', targetAccount)} Account`}
      />
    );

    return { sender, receiver };
  };

  const getParticipants = () => {
    const { transaction } = props;
    const isIncoming = getIsIncoming(transaction);
    const sign = isIncoming ? '+' : '-';
    const destination = getWalletToShow(transaction, sign);
    const verb = CONST.TRANSACTION_MAPPER[sign].VERB;
    const transactionAmount = Math.abs(transaction.gross_amount.usd_equivalent) / 100;

    const myUserCard = (
      <UserCard
        className="exchange-result_item-icon"
        avatarType={getAvatarType()}
        contentMain={withCurrency(transactionAmount, 'USD')}
        contentSecondary={<span className="word-break-word">{verb}</span>}
      />
    );

    const otherUserCard = (
      <UserCard
        avatarSrc={destination.avatar_image_url}
        avatarType={destination.type}
        avatarClassName={cn({
          'transactions-log-item_avatar -gray': !destination.avatar_image_url,
          '-status': !destination.avatar_image_url,
        })}
        className="exchange-result_item-icon"
        contentMain={destination.name}
        contentSecondary={<span className="word-break-word">{destination.user_specified_id}</span>}
      />
    );

    const sender = isIncoming ? otherUserCard : myUserCard;
    const receiver = isIncoming ? myUserCard : otherUserCard;

    return { sender, receiver };
  };

  const getTransferParticipants = () => {
    if (isExchange) {
      return getExchangeParticipants();
    }

    const bankTransaction = filter(i => i.account_type === 'bankaccount', transaction.transaction_details.all)[0];
    const isIncoming = path(['transaction_amount', 'sign'], bankTransaction) !== CONST.PLUS;

    sourceAccount.name = getAccountName(sourceAccount, accounts);
    targetAccount.name = getAccountName(targetAccount, accounts);

    const transactionAmount = Math.abs(transaction.gross_amount.usd_equivalent) / 100;

    const sender = (
      <UserCard
        className="exchange-result_item-icon"
        avatarType={getAvatarType()}
        contentMain={withCurrency(transactionAmount, 'USD')}
        contentSecondary={!isIncoming ? `${sourceAccount.name} Account` : 'Bank Account'}
      />
    );

    const receiver = (
      <UserCard
        avatar={
          <Avatar className="-status">
            <Svg name={isIncoming ? 'wallet' : 'usd'} className="avatar_status-icon" />
          </Avatar>
        }
        contentMain={isIncoming ? `${targetAccount.name} Account` : 'Bank Account'}
      />
    );

    return { sender, receiver };
  };

  const { sender, receiver } =
      props.transaction.type === CONST.TRANSFER
        ? getTransferParticipants()
        : getParticipants();

  return (
    <div className="exchange-result">
      <div className="exchange-result_hint">
        <Svg name="arrow" className="exchange-result_hint-icon" />
      </div>
      <div className="exchange-result_body">
        <div className="exchange-result_item">
          {sender}
        </div>
        <div className="exchange-result_item">
          {receiver}
        </div>
      </div>
    </div>
  );
};

TransactionResult.propTypes = {
  transaction: TransactionShape,
  accounts: walletReducerTypes.accounts,
};

export default TransactionResult;
