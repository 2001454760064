
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/button';
import WalletIcon from './wallet-icon';
import { path } from 'ramda';
import { ACCESS_NAMES, ACCESS_NAMES_TEXT } from '../../constants/wallet-constants';

const WalletButton = ({ wallet, handleWalletSelect, isPaymentPage }) => {
  const initials = wallet && wallet.name && wallet.name.split(' ').map(name => name[0]).join('');
  const accessLevel = path(['features', 'current_access_level'], wallet);
  const isDisabled = isPaymentPage && accessLevel === ACCESS_NAMES.VIEW;

  return (
    <Button 
      color="white"
      xSize="full"
      ySize="xs"
      className="wallet-select__button button-group_item -has-arrow -arrow-blue -arrow-right-zero"
      key={wallet.wallet_id}
      onClick={() => handleWalletSelect(wallet)}
      disabled={isDisabled}
    >
      <WalletIcon type={wallet.type} initials={initials} />
      <div className="button_head account-widgets__balance">
        <div className="button_content-primary">
          {wallet.name}
        </div>
        <div className="button_content-secondary -first-letter-capitalize">
          {wallet.type}{accessLevel ? `, ${ACCESS_NAMES_TEXT[accessLevel]}` : ''}
          {accessLevel === ACCESS_NAMES.VIEW && isPaymentPage ? ', can’t be used for payments' : ''}
        </div>
      </div>
    </Button>
  );
};

WalletButton.propTypes = {
  wallet: PropTypes.shape({
    wallet_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['individual', 'business']).isRequired,
    features: PropTypes.shape({
      current_access_level: PropTypes.oneOf(['owner', 'view', 'transact']),
    }),
  }),
  handleWalletSelect: PropTypes.func.isRequired,
  isPaymentPage: PropTypes.bool,
};

export default WalletButton;