import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { userReducerTypes } from '../../constants/prop-types';
import { NavLink } from 'react-router-dom';
import Svg from '../svg';
import { OnMobile, OnDesktop } from '../breakpoints';
import Version from '../version';
import WalletIcon from '../../pages/wallet-select/wallet-icon';
import Access from '../hoc/access';
import { ACCESS_NAMES } from '../../constants/wallet-constants';

const MenuPrimary = ({
  walletType,
  initials,
  userName,
  isBankAccountsDisabled,
  isCreditCardsDisabled,
  hasLoanOffer,
  isSendMoneyDisabled,
  setDashboardField,
  windowScrolled,
  rewards,
  showRewards,
  isInvoicingEnabled,
  canChooseWallet,
  handleNavigateToWalletSelect,
  hideNav,
  hideUser
}) => {

  return (
    <Fragment>
      <nav className={cn('header-menu_body -primary', hideNav && '-no-margin-bottom')}>
        {!hideUser && (
          <OnMobile>
            <div className="header-menu_body-content-header">
              <WalletIcon type={walletType} initials={initials} />
              <div>
                <p className="-primary">
                  {userName}
                </p>
                <p className="-secondary -capitalize">
                  {walletType}
                </p>
              </div>
              {canChooseWallet && (
                <NavLink onClick={() => handleNavigateToWalletSelect()} to="/wallet-select" className="-wallet-select">All wallets</NavLink>
              )}
            </div>
          </OnMobile>
        )}
        {!hideNav && (
          <Fragment>
            <NavLink to="/dashboard" className="header-menu_item -dashboard" activeClassName="-active">
              <Svg name="bars" className="header-menu_item-icon" />
              Dashboard
            </NavLink>

            {!isBankAccountsDisabled && (
              <NavLink to="/bank-accounts" className="header-menu_item -accounts" activeClassName="-active">
                <Svg name="banknote" className="header-menu_item-icon" />
                Bank Accounts 
              </NavLink>
            )}

            {!isCreditCardsDisabled && (
              <NavLink className="header-menu_item -credit-cards -accounts" activeClassName="-active" to="/credit-cards">
                <Svg name="credit-card" className="header-menu_item-icon" />
                Credit Cards
              </NavLink>
            )}

            <NavLink to="/transactions" className="header-menu_item -transactions" activeClassName="-active">
              <Svg name="arrows-fat" className="header-menu_item-icon" />
              Transactions
            </NavLink>

            {isInvoicingEnabled && (
              <Access requiredAccess={ACCESS_NAMES.TRANSACT}>
                <NavLink to="/invoices" className="header-menu_item -invoices" activeClassName="-active">
                  <Svg name="invoice-icon" className="header-menu_item-icon -no-stroke" />
                  Invoices
                </NavLink>
              </Access>
            )}

            <NavLink
              to="/wallet-settings"
              className="header-menu_item -wallet-settings"
              activeClassName="-active"
            >
              <Svg name="filter-bars" className="header-menu_item-icon" />
              Wallet Settings
            </NavLink>

            {hasLoanOffer && (
              <Access requiredAccess={ACCESS_NAMES.TRANSACT}>
                <NavLink
                  to="/loan-offer"
                  className="header-menu_item -wallet-settings"
                  activeClassName="-active"
                >
                  <span className="header-menu_item-icon -offer">$</span>
                  Loan Offer
                </NavLink>
              </Access>
            )}
            {!isSendMoneyDisabled && (
              <Access requiredAccess={ACCESS_NAMES.TRANSACT}>
                <NavLink to="/send-money" className="header-menu_item -send-money" activeClassName="-active">
                Send money
                </NavLink>
              </Access>
            )}
          </Fragment>
        )}
        <div className="sidebar-version-wrapper">
          <OnMobile>
            <Version />
          </OnMobile>
        </div>      
      </nav>
      {showRewards && !hideNav && !hideUser && (
        <Access requiredAccess={ACCESS_NAMES.OWNER}>
          <OnDesktop>
            <div className="rewards-container" onClick={() => setDashboardField('rewardsModalShown', true)}>
              { 
                windowScrolled
                  ? <Svg name="rewards-icon" className="-incoming" />
                  : <Svg name="rewards-icon-white" className="-incoming" />
              }
            &nbsp;{(rewards.points || 0).toLocaleString('en')}&nbsp;points
            </div>
          </OnDesktop>
        </Access>
      )}
    </Fragment>
  );
};

MenuPrimary.propTypes = {
  walletType: PropTypes.string,
  initials: PropTypes.string,
  userName: PropTypes.string,
  isBankAccountsDisabled: PropTypes.bool,
  isCreditCardsDisabled: PropTypes.bool,
  hasLoanOffer: PropTypes.bool,
  isSendMoneyDisabled: PropTypes.bool,
  setDashboardField: PropTypes.func,
  windowScrolled: PropTypes.bool,
  rewards: userReducerTypes.rewards,
  showRewards: PropTypes.bool,
  isInvoicingEnabled: PropTypes.bool,
  canChooseWallet: PropTypes.bool,
  handleNavigateToWalletSelect: PropTypes.func,
  hideNav: PropTypes.bool,
  hideUser: PropTypes.bool,
};

export default MenuPrimary;
