import CONST from '../constants/wallet-constants';

export const setField = (path, value) => ({
  type: CONST.SET_FIELD,
  payload: { path, value },
});

export const setWalletBankAccounts = bankAccounts => ({
  type: CONST.SET_WALLET_BANK_ACCOUNTS,
  payload: { bankAccounts },
});

export const setWalletAccounts = accounts => ({
  type: CONST.SET_WALLET_ACCOUNTS,
  payload: { accounts },
});

export const setWalletCardAccounts = cardAccounts => ({
  type: CONST.SET_WALLET_CARD_ACCOUNTS,
  payload: { cardAccounts },
});


export const fetchAccounts = () => ({
  type: CONST.FETCH_ACCOUNTS,
});

export const getCurrentWallet = userId => ({
  type: CONST.GET_CURRENT_WALLET,
  payload: { userId },
});

export const setCurrentWallet = wallet => ({
  type: CONST.SET_CURRENT_WALLET,
  payload: { wallet },
});

export const updateWalletHandle = handle => ({
  type: CONST.UPDATE_WALLET_HANDLE,
  payload: { handle },
});

export const updateTransactionAllocation = walletAccounts => ({
  type: CONST.UPDATE_TRANSACTION_ALLOCATION,
  payload: { walletAccounts },
});

export const getRate = () => ({
  type: CONST.GET_RATE,
});

export const setRate = rate => ({
  type: CONST.SET_RATE,
  payload: { rate },
});

export const updateEIN = (ein) => ({
  type: CONST.UPDATE_EIN,
  payload: { ein },
});

export const setCurrencyPromos = currencyPromos => ({
  type: CONST.SET_CURRENCY_PROMOS,
  payload: { currencyPromos },
});

export const setAvailableWallets = wallets => ({
  type: CONST.SET_AVAILABLE_WALLETS,
  payload: { wallets },
});

export const handleWalletSelect = wallet => ({
  type: CONST.HANDLE_WALLET_SELECT,
  payload: { wallet },
});

export const getWalletUsers = walletId => ({
  type: CONST.GET_WALLET_USERS,
  payload: { walletId },
});

export const addWalletUser = (walletId, sendTo, params) => ({
  type: CONST.ADD_WALLET_USER,
  payload: { walletId, sendTo, params },
});

export const removeWalletUser = (walletId, sendTo) => ({
  type: CONST.REMOVE_WALLET_USER,
  payload: { walletId, sendTo },
});

