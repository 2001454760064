import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
// import * as types from '../../constants/prop-types';
import WalletSelectLayout from './wallet-select-layout';
import WalletButton from './wallet-button';
import Loader from '../../components/loader';
import { handleWalletSelect } from '../../actions/wallet-actions';
import { isPaymentPageSelector } from '../../reducers/wallet-reducer';

const WalletSelect = ({ wallets, handleWalletSelect, isPaymentPage, hasChosenWallet }) => {

  const [isLoading, setIsLoading] = useState(false);
  const onWalletSelect = (wallet) => {
    setIsLoading(true);
    handleWalletSelect(wallet);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <WalletSelectLayout isPaymentPage={isPaymentPage} isInitialScreen={!hasChosenWallet}>
      <div className="page_body">
        <div className="page_content">
          <div className={cn('wallet-select', { '-loading': isLoading, '-payment': isPaymentPage, 'full-width': isPaymentPage })}>
            {isLoading ? <Loader color="blue" size="sm" className="-centered" /> : (
              <Fragment>
                <div className="wallet-select__header">
                  <h2>Select a wallet to use</h2>
                  <p>
                    You can change wallet later in your settings menu
                  </p>
                </div>
                <div className="wallet-select__buttons">
                  {wallets.map(wallet => (
                    <WalletButton
                      key={wallet.wallet_id}
                      wallet={wallet}
                      handleWalletSelect={onWalletSelect}
                      isPaymentPage={isPaymentPage}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </WalletSelectLayout>
  );
};

WalletSelect.propTypes = {
  wallets: PropTypes.arrayOf(PropTypes.shape({
    wallet_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['individual', 'business']).isRequired,
    role: PropTypes.oneOf(['owner', 'view-only', 'transact']),
  })),
  handleWalletSelect: PropTypes.func.isRequired,
  isPaymentPage: PropTypes.bool.isRequired,
  hasChosenWallet: PropTypes.bool.isRequired,
};

WalletSelect.defaultProps = {
  wallets: [],
  handleWalletSelect: () => {
    window.location.href = '/dashboard';
  }
};

const mapStateToProps = (state) => {
  return {
    wallets: state.wallet.availableWallets,
    isLoading: state.wallet.isLoading,
    isPaymentPage: isPaymentPageSelector(state),
    hasChosenWallet: Boolean(state.wallet && state.wallet.wallet && state.wallet.wallet.wallet_id),
  };
};

export default connect(mapStateToProps, {
  handleWalletSelect,
})(WalletSelect);