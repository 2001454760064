import React, { useRef, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Svg from '../svg';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import WalletIcon from '../../pages/wallet-select/wallet-icon';
import { OnDesktop } from '../breakpoints';
import Access from '../hoc/access';
import { ACCESS_NAMES } from '../../constants/wallet-constants';

const MenuSecondary = ({
  walletType,
  initials,
  userName,
  logoutUser,
  isBankAccountsDisabled,
  isCreditCardsDisabled,
  hasLoanOffer,
  canChooseWallet,
  handleNavigateToWalletSelect,
  hideNav,
  hideUser
}) => {
  const [dropdownDesktopMenuIsOpen, setDropdownDesktopMenuIsOpen] = useState(false);
  const _isMounted = useRef(false);  

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <nav
      className="header-menu_body -secondary"
      onMouseEnter={() => setDropdownDesktopMenuIsOpen(true)}
      onMouseLeave={() => setDropdownDesktopMenuIsOpen(false)}
      onClick={() => {
        setDropdownDesktopMenuIsOpen(true);
        const clickListener = document.body.addEventListener('click', () => {
          _isMounted.current && setDropdownDesktopMenuIsOpen(false);
          document.body.removeEventListener('click', clickListener, false);
        }, false);
      }}
    >
      {!hideUser && ( 
        <OnDesktop>
          <WalletIcon className="header-menu_secondary-dropdown-trigger" type={walletType} initials={initials} />
        </OnDesktop>
      )}
      <CSSTransition
        in={dropdownDesktopMenuIsOpen}
        timeout={500}
        classNames=""
      >
        <div className="header-menu_body-content">
          {!hideUser && (
            <OnDesktop>
              <div className="header-menu_body-content-header">
                <WalletIcon type={walletType} initials={initials} />
                <div>
                  <p className="-primary">
                    {userName}
                  </p>
                  <p className="-secondary -capitalize">
                    {walletType}
                  </p>
                </div>
                {canChooseWallet && (
                  <NavLink onClick={() => handleNavigateToWalletSelect()} to="/wallet-select" className="-wallet-select">All wallets</NavLink>
                )}
              </div>
            </OnDesktop>
          )}
          {!hideNav && (
            <Fragment>
              {!isBankAccountsDisabled && (
                <NavLink className="header-menu_item -accounts" to="/bank-accounts">
                  <Svg name="banknote" className="header-menu_item-icon" />
                  Bank Accounts
                </NavLink>
              )}
              {!isCreditCardsDisabled && (
                <NavLink className="header-menu_item -credit-cards -accounts" activeClassName="-active" to="/credit-cards">
                  <Svg name="credit-card" className="header-menu_item-icon" />
                  Credit Cards
                </NavLink>
              )}
              <NavLink className="header-menu_item -accounts js-menu-item-wallet-settings" to="/wallet-settings">
                <Svg name="filter-bars" className="header-menu_item-icon" />
                Wallet Settings
              </NavLink>
              {hasLoanOffer && (
                <Access requiredAccess={ACCESS_NAMES.TRANSACT}>
                  <NavLink className="header-menu_item -accounts js-menu-item-wallet-settings" to="/loan-offer">
                    <span className="header-menu_item-icon -offer">$</span>
                    Loan Offer
                  </NavLink>
                </Access>
              )}
            </Fragment>
          )}
          <a 
            href={process.env.REACT_APP_HELP_DESK_URL} 
            rel="noopener noreferrer"
            target="_blank" 
            className="header-menu_item -sub"
          >
            Customer Support
          </a>
          <a 
            href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL} 
            target="_blank"
            rel="noopener noreferrer"
            className="header-menu_item -sub"
          >
            User Agreement
          </a>
          <a href="#logout" className="header-menu_item -sub" onClick={logoutUser}>
            Log Out
          </a>
        </div>
      </CSSTransition>
    </nav>
  );
};

MenuSecondary.propTypes = {
  walletType: PropTypes.string,
  initials: PropTypes.string,
  logoutUser: PropTypes.func,
  isBankAccountsDisabled: PropTypes.bool,
  isCreditCardsDisabled: PropTypes.bool,
  hasLoanOffer: PropTypes.bool,
  userName: PropTypes.string,
  canChooseWallet: PropTypes.bool,
  handleNavigateToWalletSelect: PropTypes.func,
  hideNav: PropTypes.bool,
  hideUser: PropTypes.bool,
};

export default MenuSecondary;
