import { takeLatest, put, select, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { path } from 'ramda';
import REQUESTS from '../utils/requests';
import { helpDeskUrl } from '../utils/api';
import CONST, { KYC_STATUS } from '../constants/user-constants';
import { setUser, setField } from '../actions/user-actions';
import { setField as setPaymentField } from '../actions/send-money-actions';
import { setField as setRecurrentField } from '../actions/recurrent-payment-actions';
import { STEP } from '../reducers/user-reducer';
import { clearAuthToken } from '../utils/api';
import { redirectToOAuthLogin } from '../utils/redirects';
import { addNotification } from '../actions/toast-actions';

const GB_STATUS = {
  ATTEMPTED: 'attempted', 
  CONFIRMED: 'confirmed',
  DECLINED: 'denied',
};

function* toastEmitter() { // eslint-disable-line no-unused-vars
  yield put(setField(['gbData', 'isLoading'], false));
  yield put(setField(['gbData', 'isGetting'], false));
  yield put(setField(['gbData', 'popupIsOpened'], false));

  yield put(addNotification(`Something went wrong. Please try again or 
    contact ${helpDeskUrl} if the error persists.`, 500));
}

function* getUserFlow({ payload }) {
  if(payload.userId) {
    try {
      const { success, data } = yield REQUESTS.GET_USER_DETAILS(payload.userId);

      if (success) {
        if (data[0].loyalty_member) {
          const { success: userRewardsSuccess, data: userRewardsData } = yield REQUESTS.GET_USER_REWARDS(payload.userId);


          if (userRewardsSuccess &&
            Array.isArray(userRewardsData) &&
            userRewardsData[0] instanceof Object
          ) {
            yield put(setField(['rewards', 'pointsToUse'], userRewardsData[0].rewards || []));
            yield put(setField(['rewards', 'points'], userRewardsData[0].points_balance));

            const { 
              success: userRewardsActivitySuccess,
              data: userRewardsActivityData
            } = yield REQUESTS.GET_USER_REWARDS_ACTIVITY(payload.userId);

            if (userRewardsActivitySuccess &&
              Array.isArray(userRewardsActivityData) &&
              userRewardsActivityData[0] instanceof Object
            ) {
              yield put(setField(['rewards', 'referral'], userRewardsActivityData[0].referral_link || ''));
              yield put(setField(['rewards', 'history'], userRewardsActivityData[0].transactions || []));
            }
          }
        }

        const noticesResponse = yield REQUESTS.GET_USER_NOTICES(payload.userId);

        if(noticesResponse.success) {
          data[0].notices = noticesResponse.data;
        }

        yield put(setUser(data[0]));
      } else {
        console.warn('user not found');
        yield put(setUser({
          id: payload.userId
        }));
      }
    } catch (error) {
      console.warn('error getting user', error);
      yield put(setUser({
        id: payload.userId
      }));
    }
  }
}

function* updateUserName({ payload: { lname, fname } }) {
  try {
    const { user: { user } } = yield select(state => state);
    const { success, data } = yield REQUESTS.UPDATE_USER(user.user_id, { lname, fname });

    if (success) {
      yield put(setUser(data[0]));
    } else {
      yield put(setField('isUpdating', false));
    }
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}

function* updateUserAddress({ payload }) {
  try {
    const { user: { user } } = yield select(state => state);
    const { success, data } = yield REQUESTS.UPDATE_USER_ADDRESS(user.user_id, { address: payload.address });

    if (success) {
      yield put(setUser(data[0]));
    } else {
      yield put(setField('isUpdating', false));
    }
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}


function* updateUserSSN({ payload }) {
  try {
    const { user: { user } } = yield select(state => state);
    const { success, data } = yield REQUESTS.UPDATE_USER_ADDRESS(user.user_id, { ssn: payload.ssn });

    if (success) {
      yield put(setUser(data[0]));
      yield put(addNotification(`Your profile was updated. Thank you!`, 200));
    } else {
      yield put(setField('isUpdating', false));
    }
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}

function* logoutUser() {
  try {
    yield put(setPaymentField('isLoading', true));
    yield put(setRecurrentField('isLoading', true));
    const { success } = yield REQUESTS.LOGOUT();
    if (success) {
      clearAuthToken();
      redirectToOAuthLogin();
    }
  } catch (error) {
    throw error;
  }
}

function* setUnlinkConfirmation () {
  try {
    yield put(setField(['gbData', 'step'], STEP.UNLINK_CONFIRM));
  } catch (error) {
    yield put(setField(['gbData', 'isLoading'], false));
    throw error;
  }
}

function* unlinkGB () { // eslint-disable-line no-unused-vars
  try {
    yield put(setField(['gbData', 'isLoading'], true));

    // requests

    yield put(setField(['gbData', 'isLoading'], false));
  } catch (error) {
    yield put(setField(['gbData', 'isLoading'], false));
    throw error;
  }
}

function* skipLinking() {
  try {
    yield put(setField(['gbData', 'isLoading'], true));

    const { validationToken } = yield select(path(['user', 'gbData']));
    const userId = yield select(path(['user', 'user', 'user_id']));
    const { wallet_id } = yield select(path(['wallet', 'wallet']));
    const { success: setSuccess } = yield REQUESTS.SET_GB_USER({
      userId,
      status: GB_STATUS.DECLINED,
      walletId: wallet_id,
      gbValidationId: validationToken,
    });

    if (setSuccess) {
      const { success: getUserSuccess, data: getUserData } = yield REQUESTS.GET_USER_DETAILS(userId);

      if (getUserSuccess) {
        yield put(setUser(getUserData[0]));
      } else {
        yield put(setUser({
          id: userId
        }));
      }


      yield put(setField(['gbData', 'emptyData'], false));
      yield put(setField(['gbData', 'isConfirmed'], false));

      yield put(setField(['gbData', 'isLoading'], false));
      yield put(setField(['gbData', 'popupIsOpened'], false));
    }

    yield put(setField(['gbData', 'isLoading'], false));
  } catch (error) {
    yield put(setField(['gbData', 'isLoading'], false));
    throw error;
  }
}

function* getGBStatusFlow() {
  try {
    yield put(setField(['gbData', 'isGetting'], true));

    const userId = yield select(path(['user', 'user', 'user_id']));
    if(userId) {
      const { data, success } = yield REQUESTS.GET_GB_USER(userId);

      if (success) {
        if (data && data[0] && data[0].gun_broker_validation_id) {
          yield put(setField(['gbData', 'userName'], data[0].username || ''));
          yield put(setField(['gbData', 'validationToken'], data[0].gun_broker_validation_id || ''));
          yield put(setField(['gbData', 'isGetting'], false));
          yield put(setField(['gbData', 'emptyData'], false));
          yield put(setField(['gbData', 'isConfirmed'], data[0].status === GB_STATUS.CONFIRMED));
        } else {
          yield put(setField(['gbData', 'isGetting'], false));
          yield put(setField(['gbData', 'emptyData'], true));
        }

        return;
      } else {
        yield put(setField(['gbData', 'isGetting'], false));
        yield put(setField(['gbData', 'emptyData'], true));
      }
    }
  } catch (error) {
    yield put(setField(['gbData', 'isGetting'], false));

    throw error;
  }
}

function* loginGBflow({ payload: { password }}) {
  try {
    yield put(setField(['gbData', 'isLoading'], true));
    const userId = yield select(path(['user', 'user', 'user_id']));
    const { validationToken, userName } = yield select(path(['user', 'gbData']));
    const { wallet_id: walletId } = yield select(path(['wallet', 'wallet']));


    const { success, status } = yield REQUESTS.LOGIN_GB_USER(userId, validationToken, userName, password, walletId);
    
    if (success) {
      const { success: getUserSuccess, data: getUserData } = yield REQUESTS.GET_USER_DETAILS(userId);

      if (getUserSuccess) {
        yield put(setUser(getUserData[0]));
      } else {
        yield put(setUser({
          id: userId
        }));
      }

      yield put(setField(['gbData', 'emptyData'], false));
      yield put(setField(['gbData', 'isConfirmed'], true));
      yield put(setField(['gbData', 'isLoading'], false));
      yield put(setField(['gbData', 'step'], STEP.LINK_COMPLETE));

      return;
    }

    if (!success && status === 403) {
      yield put(setField(['gbData', 'error'], true));
      yield put(setField(['gbData', 'isLoading'], false));

      return;
    }

    yield put(setField(['gbData', 'isLoading'], false));
  } catch (error) {
    yield put(setField(['gbData', 'isLoading'], false));

    throw error;
  }
}

function* updateUserEmail({ payload: { email } }) {
  try {
    yield put(setField('isUpdating', true));
    const { user: { user } } = yield select(state => state);
    const { success, data } = yield REQUESTS.UPDATE_EMAIL({
      userId: user.user_id, 
      email,
    });

    if (success) {
      yield put(setField('emailModalStep', 1));
      yield put(setUser(data[0]));
    } else {
      yield put(setField('isUpdating', false));
    }
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}

function* addPhoneFlow({ payload }) {
  try {
    yield put(setField('isUpdating', true));
    const { user: { user } } = yield select(state => state);
    
    const { success } = yield REQUESTS.ADD_PHONE({
      userId: user.user_id, 
      phone: payload.phoneNumber,
    });

    if (success) {
      yield call(logoutUser);
    } else {
      yield put(setField('isUpdating', false));
    }
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}

function* getKycSessionStatus() {
  try {
    yield call(delay, 15000);

    const { user: { user }, signUp } = yield select(state => state);
    const kycSessionId = signUp.kycSessionId || localStorage.getItem('kycSessionId');
    const { data, success } = yield REQUESTS.GET_KYC_SESSION_STATUS(user.user_id, 3, kycSessionId);

    if(success) {
      yield put(setField('kycStatus', data));

      if(data[0] && data[0].status === KYC_STATUS.PENDING) {
        yield call(getKycSessionStatus);
      }
    }

  } catch(error) {
    console.warn(error);
  }
}

function* updateUserNotice({ payload }) {
  try {
    yield put(setField('isUpdating', true));
    const { user: { user } } = yield select(state => state);

    const { success } = yield REQUESTS.UPDATE_USER_NOTICE({
      userId: user.user_id, 
      noticeName: payload.noticeName,
    });

    if(success) {
      const { data } = yield REQUESTS.GET_USER_NOTICES(user.user_id);

      yield put(setField(['user', 'notices'], data));
    }

    yield put(setField('isUpdating', false));
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}

function* userSaga() {
  yield takeLatest(CONST.GET_USER, getUserFlow);
  yield takeLatest(CONST.GET_USER_SIMPLE, getUserFlow);
  yield takeLatest(CONST.UPDATE_USER_ADDRESS, updateUserAddress);
  yield takeLatest(CONST.UPDATE_USER_NAME, updateUserName);
  yield takeLatest(CONST.LOGOUT_USER, logoutUser);
  yield takeLatest(CONST.LOGIN_GB_USER, loginGBflow);
  yield takeLatest(CONST.SKIP_LINKING, skipLinking);
  yield takeLatest(CONST.UNLINK_GB_USER, skipLinking);
  yield takeLatest(CONST.SET_UNLINK_CONFIRMATION, setUnlinkConfirmation);
  yield takeLatest(CONST.GET_USER_GB_STATUS, getGBStatusFlow);
  yield takeLatest(CONST.ADD_PHONE_NUMBER, addPhoneFlow);
  yield takeLatest(CONST.UPDATE_EMAIL, updateUserEmail);
  yield takeLatest(CONST.GET_KYC_SESSION_STATUS, getKycSessionStatus);
  yield takeLatest(CONST.UPDATE_USER_NOTICE, updateUserNotice);
  yield takeLatest(CONST.UPDATE_USER_SSN, updateUserSSN);
}

export default [userSaga];
