import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/modal';
import Button from '../../components/button';
import InvoiceFilterFields from './invoice-filter-fields';
import { TransactionFiltersShape } from '../../constants/prop-types';


const InvoiceFilterMobile = ({ 
  filterVisible, 
  handleFilterTriggerClick, 
  clearFilters,
  applyFilters, 
  filters,
  setFilterValue,
  setTimeFrameFilter,
  range,
  openCalendar,
}) => (
  <Modal
    show={filterVisible}
    onClose={handleFilterTriggerClick}
    closeButton
    containerClassName="-white"
  >
    <div className="modal_header">
      <h1>Filter Transactions</h1>
    </div>

    <div className="modal_body">
      <InvoiceFilterFields
        filters={filters}
        setFilterValue={setFilterValue}
        timeFrameValue={filters.timeFrameValue}
        setTimeFrameFilter={setTimeFrameFilter}
        range={range}
        openCalendar={openCalendar}
      />
    </div>

    <div className="modal_footer">
      <div className="modal_footer-controls">
        <Button
          color="gray"
          transparency="full"
          xSize="full"
          onClick={clearFilters}
        >
          Reset filters
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="modal_footer-control -submit"
          onClick={() => applyFilters(filters)}
        >
          Show Matching Invoices
        </Button>
      </div>
    </div>
  </Modal>
);

InvoiceFilterMobile.propTypes = {
  filterVisible: PropTypes.bool.isRequired,
  handleFilterTriggerClick: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filters: TransactionFiltersShape.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  setTimeFrameFilter: PropTypes.func.isRequired,
  range: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  openCalendar: PropTypes.func.isRequired,
};

export default InvoiceFilterMobile;