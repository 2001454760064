import { assocPath, assoc, clone } from 'ramda';
import { CONSTANTS } from '../constants/invoice-constants';
import CONST from '../constants/transactions-constants';
import { predefinedTimeFrame } from '../utils/transaction-utils';

export const INVOICE_INITIAL_STATE = {
  orderId: '',
  amount: '',
  tax: '', 
  total: '',
  description: '',
  expires: '30',
  attributes: [],
  send_to_email: '',
  send_to_sms: '',
};

export const INIT_STATE = {
  isLoading: false,
  completedInvoiceList: [],
  pendingInvoiceList: [],
  completedInvoiceTransactions: [],
  pendingInvoiceTransactions: [],
  invoiceDetails: {},
  destinationWallet: {},
  contactType: 'email',
  newInvoice: clone(INVOICE_INITIAL_STATE),
  validationErrors: {
    send_to_email: null,
    send_to_sms: null,
  },
  filters: {
    search: '',
    status: [],
    direction: [],
    timeFrameValue: CONST.TIMEFRAME_INTERVALS.LAST_90,
    timeFrameDates: predefinedTimeFrame(CONST.TIMEFRAME_INTERVALS.LAST_90),
  },
  invoiceShown: false,
  invoiceToShow: null,
};

const invoiceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONSTANTS.SET_FIELD:
      if(Array.isArray(action.payload.path)) {
        return assocPath(action.payload.path, action.payload.value, state);
      }
      return assoc(action.payload.path, action.payload.value, state);
    case CONSTANTS.CLEAR_FILTERS:
      return assoc(['filters'], INIT_STATE.filters, state);
    case CONSTANTS.SET_VALIDATION_ERROR:
      return assocPath(['validationErrors', action.payload.field], action.payload.error, state);
    default:
      return state;
  }
};

export default invoiceReducer;
