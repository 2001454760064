import Decimal from 'decimal.js';
import { path, prop } from 'ramda';
import CONST from '../constants/permission-constants';

export const formatCentsToAmount = (value) =>
  Decimal.div(value, 100).toFixed(2, 1).valueOf();

export const hasPermission = (permissionsList, permission) =>
  path([`${permission}`, 'value'], permissionsList) === undefined
    ? true
    : path([`${permission}`, 'value'], permissionsList);

export const hasFeature = (featuresList = {}, feature) =>
  path([`${feature}`, 'value'], featuresList) === undefined
    ? true
    : path([`${feature}`, 'value'], featuresList);

export const permissionErrorMessage = (permissionsList, permission) =>
  path([`${permission}`, 'message'], permissionsList)
  || CONST.DEFAULT_ERROR_MESSAGES[permission]
  || 'You do not have necessary permissions';

export const permissionAllowedLimit = (permissionsList, limitName) => {
  if (limitName === 'max_ach_allowed') {
    return path([`${limitName}`, 'value'], permissionsList) === undefined
      ? Infinity
      : formatCentsToAmount(path([`${limitName}`, 'value'], permissionsList));
  }
  return prop(limitName, permissionsList) === undefined
    ? Infinity
    : formatCentsToAmount(prop(limitName, permissionsList));
};

export const hasLoanOffer = (permissionsList) => {
  return path(['user_credit_max', 'value'], permissionsList) > 0;
};
