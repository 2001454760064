import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userReducerTypes } from '../../constants/prop-types';
import cn from 'classnames';
import qs from 'qs';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { logoutUser } from '../../actions/user-actions';
import { setField as setDashboardField } from '../../actions/dashboard-actions';
import { setField as setWalletField } from '../../actions/wallet-actions';
import { BREAKPOINTS, OnMobile } from '../breakpoints';
import Svg from '../svg';
import HeaderButton from './header-button';
import { shouldGetUser } from '../../application';
import { hasLoanOffer as checkHasLoanOffer } from '../../utils/permissions';
import { isSendMoneyDisabledSelector, isBankAccountsDisabledSelector, isCreditCardsDisabledSelector} from '../../reducers/app-reducer';
import { isInvoicingEnabledSelector, canChooseWalletSelector } from '../../reducers/wallet-reducer';
import { getLogoUrl } from '../../utils/api';
import PoweredBy from '../powered-by';
import MenuPrimary from './menu-primary';
import MenuSecondary from './menu-secondary';
import Access from '../hoc/access';
import { ACCESS_NAMES } from '../../constants/wallet-constants';

const Header = (props) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [windowScrolled, setWindowScrolled] = useState(false);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleMenuOpened);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleMenuOpened);
      clearAllBodyScrollLocks();
    };
  }, []);

  const { isCreditCardsDisabled } = props;

  const handleClick = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  const handleScroll = () => {
    const contentHeight = document.body.scrollHeight;
    const viewportHeight = window.innerHeight;

    // prevents color change of header when user tries to scroll page whose content doesn't overflow viewport
    // such behaviour manifests only when user uses track-pad
    if (contentHeight > viewportHeight) {
      if (window.pageYOffset > 10) {
        setWindowScrolled(true);
        return;
      }

      setWindowScrolled(false);
    }
  };

  const headerMenuRef = useRef(null);

  const handleMenuOpened = () => {
    const isMobile = window.innerWidth < BREAKPOINTS.desktop;
    const mobileMenuOpen = mobileMenuIsOpen;

    // we need to make sure that there are no open modals and it's safe to release body scroll lock
    if (isMobile && mobileMenuOpen) {
      disableBodyScroll(headerMenuRef);
    } else {
      enableBodyScroll(headerMenuRef);
    }
  };

  useEffect(handleMenuOpened, [mobileMenuIsOpen]);

  const generateLogo = (isBigLogo, isColored, tenantLogos) => {
    const { avi } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const size = isBigLogo ? 'large' : 'small';
    const color = isColored ? '' : '-white';
    const style = `${size}${color}`;
    const type = 'svg';

    const logoUrl = tenantLogos && tenantLogos[style] && tenantLogos[style][type] ? tenantLogos[style][type] : getLogoUrl(avi, style, type);

    return <img src={logoUrl} alt="branding logo" className={cn('header_logo', `-${size}`, `-${type}`)} />;
  };

  const {
    user: { user, rewards },
    className,
    hideNav,
    hideUser,
    isBigLogo,
    coloredLogo,
    isSendMoneyDisabled,
    isBankAccountsDisabled,
    poweredByType,
    isCustom,
    tenantLogos,
    setDashboardField,
    logoutUser,
    isInvoicingEnabled,
    walletType,
    walletName,
    canChooseWallet,
    setWalletField
  } = props;


  const handleNavigateToWalletSelect = () => {
    setWalletField('pageBeforeWalletSelect', window.location.pathname);
  };

  useEffect(() => {
    if(poweredByType === 'full' && isCustom) {
      document.body.classList.add('-custom-branding');
    }

    if(poweredByType !== 'full' || !isCustom) {
      document.body.classList.remove('-custom-branding');
    }

  }, [isCustom, poweredByType]);

  const initials = walletName ? walletName.split(' ').map(name => name[0]).join('') : '';
  const permissions = user ? user.enabled_states : {};
  const showRewards = !hideNav && !hideUser && user.loyalty_member;
  const hasLoanOffer = checkHasLoanOffer(permissions);

  return (
    <div className={
      cn(
        'header js-header',
        {
          '-menu-opened': mobileMenuIsOpen,
          '-window-scrolled': windowScrolled,
          '-padded-top': poweredByType === 'full' && isCustom,
        },
        className,
      )
    }
    >
      <div className={cn('header_wrapper', { '-column': poweredByType === 'sign' && isBigLogo })}>
        {generateLogo(isBigLogo, (windowScrolled || coloredLogo), tenantLogos)}
        {poweredByType ? 
          <PoweredBy isFullWidth={poweredByType === 'full'} className="header_wrapper__powered-by" /> : null}
        {
          walletType && !hideNav && (
            <HeaderButton onClick={handleClick} walletType={walletType} initials={initials} menuIsOpened={mobileMenuIsOpen} />
          )
        }
        { shouldGetUser() && !hideNav && user.loyalty_member && 
        <OnMobile>
          <Access requiredAccess={ACCESS_NAMES.OWNER}>
            <div className="header-menu_item -rewards">
              <div className="rewards-container" onClick={() => setDashboardField('rewardsModalShown', true)}>
                { 
                  windowScrolled
                    ? <Svg name="rewards-icon" className="-incoming" />
                    : <Svg name="rewards-icon-white" className="-incoming" />
                }
                  &nbsp;{(rewards.points || 0).toLocaleString('en')}&nbsp;points
              </div>
            </div>
          </Access>
        </OnMobile>
        }
        <CSSTransition
          in={mobileMenuIsOpen}
          timeout={300}
          classNames=""
        >
          <div className="header_menu header-menu" ref={headerMenuRef}>
            <div className="header-menu_wrapper">
              <Svg name="bg-waves-sm" className="header-menu_bg" />
              <div className={cn('header-menu_content', hideNav && '-align-right')}>
                <MenuPrimary
                  isBankAccountsDisabled={isBankAccountsDisabled}
                  isCreditCardsDisabled={isCreditCardsDisabled}
                  hasLoanOffer={hasLoanOffer}
                  isSendMoneyDisabled={isSendMoneyDisabled}
                  walletType={walletType}
                  initials={initials}
                  userName={walletName}
                  setDashboardField={setDashboardField}
                  windowScrolled={windowScrolled}
                  rewards={rewards}
                  showRewards={showRewards}
                  isInvoicingEnabled={isInvoicingEnabled}
                  canChooseWallet={canChooseWallet}
                  handleNavigateToWalletSelect={handleNavigateToWalletSelect}
                  hideNav={hideNav}
                  hideUser={hideUser}
                />
                <MenuSecondary
                  isBankAccountsDisabled={isBankAccountsDisabled}
                  isCreditCardsDisabled={isCreditCardsDisabled}
                  hasLoanOffer={hasLoanOffer}
                  walletType={walletType}
                  initials={initials}
                  logoutUser={logoutUser}
                  userName={walletName}
                  canChooseWallet={canChooseWallet}
                  handleNavigateToWalletSelect={handleNavigateToWalletSelect}
                  hideNav={hideNav}
                  hideUser={hideUser}
                />
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.shape(userReducerTypes),
  className: PropTypes.string,
  hideNav: PropTypes.bool,
  hideUser: PropTypes.bool,
  logoutUser: PropTypes.func,
  setDashboardField: PropTypes.func,
  isBigLogo: PropTypes.bool,
  coloredLogo: PropTypes.bool,
  isSendMoneyDisabled: PropTypes.bool,
  isBankAccountsDisabled: PropTypes.bool,
  poweredByType: PropTypes.oneOf(['full', 'sign']),
  isCustom: PropTypes.bool,
  tenantLogos: PropTypes.shape({
    large: PropTypes.shape({}),
    small: PropTypes.shape({})
  }),
  isCreditCardsDisabled: PropTypes.bool,
  isInvoicingEnabled: PropTypes.bool,
  walletType: PropTypes.string,
  walletName: PropTypes.string,
  canChooseWallet: PropTypes.bool,
  setWalletField: PropTypes.func,
};

Header.defaultProps = {
  user: {
    user: {},
  },
  className: null,
  logoutUser: () => {},
  hideUser: false,
  paymentLogo: false,
  configuration: {}
};

const mapStateToProps = (state) => ({ 
  user: state.user, 
  isSendMoneyDisabled: isSendMoneyDisabledSelector(state),
  isBankAccountsDisabled: isBankAccountsDisabledSelector(state),
  isCustom: state.application && state.application.ui ? state.application.ui.is_custom : null,
  tenantLogos: state.application && state.application.ui ? state.application.ui.logo_url : null,
  isCreditCardsDisabled: isCreditCardsDisabledSelector(state),
  isInvoicingEnabled: isInvoicingEnabledSelector(state),
  walletType: state.wallet && state.wallet.wallet ? state.wallet.wallet.type : null,
  walletName: state.wallet && state.wallet.wallet ? state.wallet.wallet.name : null,
  canChooseWallet: canChooseWalletSelector(state),
});

export default connect(mapStateToProps, { logoutUser, setDashboardField, setWalletField })(Header);
